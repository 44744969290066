import update from 'immutability-helper';
import { REVIEW, SEARCH } from '../../../common/path';
import { csvStringToIntArrayAndMap, str2Int } from '../../../common/helpers';
import { I } from '../../../common/v5/config';
import { push } from '../../../common/v5/utils';
import {
	chatHasExternalCollaboration,
	closeStatusString,
	notifyOS,
	getRandomString,
	reviewErrandURL,
	stripHTML
} from '../../../common/v5/helpers';
import { createAnswerForClient, handleReceiveClientDisplay } from '../../../common/v5/webRtcCall';
import {
	txtInvalidCreateOutboundErrandOption
	, SIP_CALL_IDLE
	, SIP_CALL_CONNECTING
	, SIP_CALL_CONNECTED
	, WARM_TRANSFER
	, COLD_TRANSFER
} from '../../../common/v5/callConstants';
import {
	addTimeout,
	removeTimeout
} from 'redux-timeout';
import {
	ErrandEmailDownload
	, ErrandEmailPreview
	, ErrandEmailManualPreview
} from '../../constants/endpoints';
import {
	deleteOneErrandNotes,
	getErrandNotes,
	getOneErrandContacts,
	getErrandMyErrands,
	getErrandLinkedErrands,
	getKnowledgeBase,
	getVoteFeaturedQuestion,
	postVoteFeaturedQuestion,
	postDetectTranslate,
	postErrandAreaData,
	postErrandAcquire,
	postErrandChangeInternalState,
	postErrandSendInternalStateChange,
	postErrandExtendedData,
	postErrandNotes,
	postErrandFetchClientAddress,
	postErrandFetchClientsAddressList,
	postErrandHistory,
	postErrandNotesDeleteAttachment,
	postErrandNotesUploadAttachment,
	postErrandPassiveChangeErrandArea,
	postErrandRemoveTemporaryAttachment,
	postErrandUploadAnswerAttachment,
	postErrandSendErrand,
	postErrandUpdateErrand,
	postErrandReopenErrand,
	postErrandResendErrand,
	postErrandPublishErrand,
	postErrandUnpublishErrand,
	postErrandSuggestErrandForLibrary,
	postErrandDoPaging,
	postErrandStartCountdown,
	postExtQueueDoneErrand,
	postOneBasicErrand,
	postOneErrandNotes,
	postTranslate,
	postErrandForwardToArea,
	postAreaNotification,
	getErrandPrint,
	postDeleteErrands,
	postCloseErrand,
	postCloseErrands,
	postSocialMediaActions,
	postErrandFetchAreaTags,
	postFBRatings,
	postSocialMediaPMS,
	postSocialMediaUpdateAns,
	getFileContent,
	getCustomerByAddress,
	getContactCardEntry,
	deleteContactCardEntry,
	postAddEntryToContactCard,
	getCustomerNotes,
	postCustomerNote,
	postDeleteCustomerNote,
	getContactBook,
	getErrandStatus,
	postCustomerAvatar,
	postCustomerDelAvatar,
	getAnonymize,
	postAnonymize,
	postExportLog,
	postContactExport,
	deleteContactExport,
	getMembershipStatus,
	postRecordEditorSize,
	getLastErrandInThread,
	getErrandId,
	postErrandUpdateLockToMe,
	postSetErrandPostponeDate,
	postFBEmotionList,
	getOpenErrandData,
	getErrandMinLoad,
	verifyChannelSelection,
	postUserVote,
	getUserVote,
	postGetErrandAcquireOwnerStatus,
	postAgentWorkingOnErrand,
	putAgentWorkingOnErrand,
	getAgentWorkingOnErrand,
	getErrandSuggestedAnswer,
	postCheckEEToBeSent,
	getCRMdatas,
	generateAIAnswerQues,
	getAnnouncement
} from './ajax';
import { multiDispatchesEE } from './collaborate'; // TODO: cyclic
import {
	closeErrand,
	closeErrands,
	deleteErrands,
	forwardErrandsToArea,
	forwardToAgent,
	getWorkflowState,
	loadList,
	moveToFolderWithoutLoadList,
	doQueueToMe,
	returnToInbox,
	linkErrandAsync,
	postErrandCount,
	setAgentPreference
} from './workflow';
import {
	selectAllErrandsSearchResult,
	handleProcessing,
	handleResetOffset,
	doGlobalSearchByWS
} from '../search';

import {
	chatSetSeenMessages,
	getAgentListInChat
} from './echat';
import {
	chatCheckCanClose,
	getQueueChat,
	closedChatErrand
} from './chat';
import {
	toggleSipOutgoingPopup,
	showSipAgentList,
	manualCallStatus,
	sipGetRefId,
	sipCloseManualErrandAfterColdTransfer,
	sipWarmFinalizeTransfer,
	sipColdTransfer
} from './sippRtc';
import {
	stopRecordingSip
	, isRecordingPaused
} from '../../../common/v5/webRtcVoice';
import {
	compareSameID
	, noReducerAsync
	, centionWebURLWithIdAndQuery
	, generateCentionWebURL
	, sameAsOpenedErrandID
} from './common';
import {
	clearPopWaiting,
	popError,
	popErrorOnly,
	popPleaseWait,
	popWaitingWithoutCatch,
	showPostPreview,
	togglePopAlert,
	togglePopWaiting,
	addNewPopupNotification,
	SetNotificationReadByErrand,
	toggleToastAlert
} from '../hmf';
import {
	customConfirm,
	enableConfirm,
	agentStatusOnLoad,
	optionalConfirm,
	redirectToErrands
} from './hmf';
import {
	clearOutboundCallSid
	, resetOutboundErrandId
	, resetOutboundPhoneId
	, updateAventaStatus
	, updateOutboundErrandId
	, sipMakeCallFromErrand
	, sipSetCallTransferData
} from '../call';
import {
	deleteUploadedColAttachment,
	uploadedColAttachments
} from '../collaborate'; // TODO: cyclic
import {
	agentBusyTyping,
	agentNotTyping,
	areaOperation,
	changeOtherContactShowIDs,
	chatSwitchOtherContactErrand,
	checkAndSwithToExternalForward,
	clearCollaborateAnsAttachment,
	deleteSavedAnswerAttachment,
	deleteUploadedInternalCommentAttachment,
	deleteUploadedManualInternalCommentAttachment,
	markLastSaveTriggered,
	moveOpenToAssociate,
	preparePostLink,
	selectQuestionAttachment,
	setAcquiringErrandsProgress,
	setClearPreivewOrSaveEmlBusy,
	setCurrentErrand,
	setInitialThreadID,
	showHideOtherContactErrand,
	syncOtherContactErrands,
	syncRelatedErrands,
	syncReviewData,
	updateCurrentErrandHasEE,
	setHasAcquirableOther,
	syncAcquireData,
	syncAcquiredState,
	syncAcquiredOwner,
	syncExtendedData,
	updateAllBasicErrandData,
	noteSize,
	saveErrandStart,
	selectReplyChannel,
	selectReplyChannelByType,
	signalOpenErrandReady,
	confirmYes,
	confirmEditNote,
	notesOperation,
	checkInternalCommentState,
	setErrandWFSettings,
	answerState,
	selectShowReply,
	postReplyActions,
	printErrandAction,
	updateInputTags,
	handleMediaActions,
	toggleSocialMediaUI,
	uploadedAnswerAttachments,
	uploadedInternalCommentAttachments,
	uploadedManualInternalCommentAttachments,
	uploadedChatAttachments,
	uploadedChatInternalCommentAttachments,
	uploadedNoteAttachments,
	deleteUploadedAnswerAttachment,
	setTextFilePreviewContent,
	setCSVFilePreviewContent,
	clearSelectedArchive,
	showContactCard,
	updateContactCard,
	setContactCard,
	setCustomerNotes,
	updateCustomerNote,
	updateContactBook,
	showContactBook,
	setChatTranslation,
	setExportLog,
	revokeGrantedAccess,
	errandNotesCount,
	setPersonalization,
	setPersonalizationDefault,
	toggleReplyToolbar,
	toggleRecipients,
	toggleSubject,
	toggleReplyOptionTab,
	setNavButtonBusy,
	setErrandCloseTimer,
	toggleReplyAssist,
	toggleLockToMe,
	signalViewErrandOnly,
	updateReplyToAndChannel,
	showVideoCallFrame,
	handleClientVideoCalling,
	handleAgentRecordVid,
	handleVideoCallRequest,
	handleClientScreenShare,
	handleClientScreenShareOffer,
	cancelConfirm,
	showHideAcquireErrand,
	saveCRMData,
	setPreviousErrand,
	moveLinkedToAssociate,
	updateAnnouncement,
	updateReformatAnswer,
	handleLibraryAttachments,
	setSuggestedAnswerUsed,
	fetchAgentAssistSuccess,
	fetchAgentAssistFailure,
	fetchAgentAssistInfo,
	updateAgentAssistPrompt,
	updateAgentAssistResponse,
	updateAgentAssistReceived,
	fetchAgentAssistDone
} from '../errand';
import {
	defaultManualAccountAndAddress,
	deleteUploadedManualAttachment,
	selectManualReplyChannel,
	updateManualErrandSavedAttachments,
	deleteAllUploadadManualAttachments,
	uploadedManualCallErrandAttachments,
	uploadedManualErrandAttachments
} from '../manual';
import {
	changeErrandListSelection,
	manualErrandState,
	resetErrandView,
	toggleManualOrCallPopup,
	setErrandMobileView,
	showMultipleActions,
	selectAllErrands,
	clearManualCallInputs,
	manualCallState
} from '../workflow';
import { 
	outboundErrandId
	, sipMakeCallCurrentErrand
	, sipCallIsRecording
	, sipGetCallXferData
	, sipCallConn
	, sipCallCurrentTransferMode
} from '../../selectors/call';
import {
	getDomainBasicErrands,
	allowInternalCommentMemoize,
	currentStateMemoize,
	isErrandActionInProgressSelector,
	isIdleAnswer,
	isValidAcquireErrandReviewContext,
	reviewData,
	selectedExtFwdQuestionAttachmentsSelector,
	getAllUnacquiredErrandsMemoize,
	getAppErrand,
	getCurrentErrand,
	getCurrentReply,
	getErrandBasicMemoize,
	getGroupedErrandListString,
	getGroupedErrandIDsWithCipherKey,
	getMemoizeAssociatedList,
	getOriginalReplyChannel,
	getClassificationAreaDataMemoize,
	getClassificationTagsMemoize,
	initialThreadIDSelector,
	isErrandAnswered,
	isInputsChangedMemoize,
	isCollabInputsChangedMemoize,
	// getOtherOpenErrandsMemoize,
	needFullSaveMemoize,
	getSelectedOpenErrandsMemoize,

	getSelectedLinkErrandMyErrandsMemoize,
	getSelectedLinkedMemoize,
	hasOtherErrandsSelector,
	hasOtherErrandsNoHistorySelector,
	ocHasOpenErrandMemoize,
	getManualErrandAreaTags,
	getMultiClassificationAreaTags,
	getErrandHistoryIdsSelector,
	getCurrentErrandAreaDataDomain,
	showReactionPopupMemo,
	contactBookPersonListSelector,
	getPreviousErrandId,
	getCurrentErrandOpening,
	getCompList,
	getEEUploadedAttachments
} from '../../selectors/errand';
import { rawHardSaveCollab
} from './collaborate';
import {
	isCallMemoize
	, selectedPhoneDataMemoize
	, manualErrandSelectedReplyChannel
	, showManualSelector
	, showManualCallSelector
} from '../../selectors/manual';
import {
	contextMemo
	, getSelectedAllErrandsWithArea
	, getSelectedAreas
	, getSelectedChatErrandsWithArea
	, getSelectedErrandIds
	, getSelectedErrandWithCipherKey
	, getSelectedErrandsWithArea
	, getWorkflowRoot
	, openErrandExtraFieldsMemoize
} from '../../selectors/workflow';
import { locationState } from '../../selectors/review';
import {
	agentPickUpNext,
	isMainMenuWorkflowErrandSelector
} from '../../selectors/hmf';
import { preselectChatAssociatedErrands } from '../../selectors/server';
import {
	keyAcquireErrand,
	keyAcquireOtherContacts,
	keyAssociatedAreaData,
	keyAssociatedExtendedData,
	keyEEAnswerSave,
	keyDeleteErrands,
	keyErrandAreaData,
	keyErrandChangeErrandArea,
	keyErrandChangeInternalState,
	keyErrandContacts,
	keyErrandMyErrands,
	keyFetchLinkedErrands,
	keyErrandContactsHistories,
	keyErrandNotesDeleteAttachment,
	keyErrandNotesUploadAttachment,
	keyErrandRemoveAllTempAttachment,
	keyErrandRemoveTemporaryAttachment,
	keyErrandSuggestToLibrary,
	keyErrandUploadAnswerAttachment,
	keyExternalexpertEdit,
	keyFetchClientAddress,
	keyFetchClientsAddressList,
	keyFetchErrandNotes,
	keyFetchExtendedData,
	keyFetchExternalExpertList,
	keyFetchExternalExpertThread,
	keyFetchExternalQueries,
	keyFetchHistory,
	keyFetchTranslateDetect,
	keyForwardToAgent,
	keyForwardToArea,
	keyLoadBasicErrand,
	keyMoveToFolder,
	keyMultiThreadsHistory,
	keyOtherContactHistory,
	keyReloadOneErrand,
	keyRetunToInbox,
	keySendAnswer,
	keySendEE,
	keyTryGetOneBasicErrand,
	keyTurnExternalExpertLightOff,
	keyUpdateAnswer,
	keyUpdateAnswerEE,
	keySavedEE,
	keyUpdateManualErrand,
	keyReopenErrand,
	keyResendAnswer,
	keyPublishErrand,
	keyUnpublishErrand,
	keyGetOneRelatedErrand,
	keyDeleteOneErrandNotes,
	keyCreateOneErrandNotes,
	keyUpdateOneErrandNotes,
	keyErrandTranslation,
	// keyErrandForwardToArea,
	keyGetAreaNotification,
	keyErrandPrintContent,
	keyCloseErrand,
	// keyCloseErrands,
	keySocialMediaUserProfile,
	keySocialMediaPostsHistory,
	keySocialMediaPostActions,
	keyErrandFetchAreaTags,
	keyErrandFBRatings,
	keySocialMediaPostsMessages,
	keySocialMediaCheckFriendship,
	keySocialMediaPostsUpdateAns,
	keyErrandAttachmentViaURL,
	keyGetKnowledgeBase,
	keyFeaturedQuestion,
	keyGetChatAreas,
	keyForwardChat,
	keyGetChatAgents,
	keyGetChatCandidates,
	keyGetContactCardEntry,
	keyGetCustomerByAddress,
	keyDeleteContactCardEntry,
	keyAddEntryToContactCard,
	keyGetCustomerNotes,
	keyPostCustomerNote,
	keyDeleteCustomerNote,
	keyGetContactBook,
	keyGetErrandStatus,
	keyCustomerAvatar,
	keyAnonymize,
	keyDataExportLog,
	keyDeleteExportContact,
	keyExportContact,
	keyGetAgentListInChat,
	keyHasMembership,
	keyRecordEditorSize,
	keyLastErrandInThread,
	keyErrandId,
	keyErrandUpdateLockToMe,
	keyErrandPostponeDate,
	keyErrandFBEmotionlist,
	keyOpenErrandData,
	keyErrandMinLoad,
	keyVerifyChannelSelection,
	keyFetchUserVote,
	keyPostUserVote,
	keyAcquireErrandOwnerStatus,
	keyAgentWorkingOnErrand,
	keyCompanyContactHistories,
	keyCompanyOtherContacts,
	keyErrandSuggestedAnswer,
	keyCheckEECanSendExternalID,
	keyCRMDataFetch,
	keyGenAIAnswer,
	keyGetAnnouncement,
	keyAgentSetErrandView,
	keyErrandWorkflowSettings
} from '../../constants/keys';
import {
	evtCHAT_FINISH_SESSION
	, evtCHAT_SET_TAGS
	, evt_CREATE_ERRAND
	, evtCHAT_BLOCK_IP
	, AGENT_BUSY_TYPING
	, CHAT_IP_BLOCKED
	, CHAT_UPDATE_TAGS
	, CHAT_UPDATE_INTERNAL_COMMENT
	, CHAT_ADD_INTERNAL_COMMENT
	, CHAT_DELETE_INTERNAL_COMMENT
	, ERRAND_CLASSIFICATION
	, INVALIDATE_LAST_SAVE_START
	, SAVE_ERRAND
	, SAVE_EE
	, XFER_CHANGED_DOMAIN_AREA_DATA
	, XFER_DOMAIN_AREA_DATA
	, GLOBAL_SEARCH_FROM_BODY
	, evtAGENT_REJECT_VIDEO_CALL
	, evtAGENT_REJECT_SCREEN_SHARE
} from '../../constants/constants';
import {
	async,
	cancellableAsync,
	createActionCreators,
	domainTransfer,
	getNormalizedDomain,
	getAreasIDViaOrgObj,
	mcamByID,
	mcamPromiseByID,
	mcamResult,
	multiAsync,
	sameValidContentIdInAttachments,
	isMobile
} from '../../util';
import {
	cachedAsync
	// , reloadCachedAsync
	, tryCachedAsync
	, tryCancellableCachedAsync
} from './cache';
import {
	ALRT_CFRM_OPR_BLOCK_IP,
	ALRT_CFRM_OPR_OPTIONAL,
	C3ERR_SAME_ID,
	CHAT_crOwner,
	CH_AREA_LINK,
	CTX_MANUAL,
	CTX_BULK_SEND,
	CTX_MY,
	CTX_NEW,
	CTX_REVIEW,
	D_AREAS,
	D_BASIC_ERRANDS,
	D_EE_EDIT,
	D_ERRAND_NOTES,
	D_HISTORY,
	DEF_OPT,
	DEFAULT_NO_DUE_DATE,
	DEL_ANSWER_ATTACHMENT,
	DEL_CHAT_ATTACHMENT,
	DEL_NOTES_ATTACHMENT,
	E_A_ANSWERED,
	E_A_SAVING,
	E_A_SENDING,
	E_A_UNKNOWN,
	ECB_INC_HISTORIES,
	ECB_INC_QUESTION,
	ECB_LOCK2ME,
	ECB_PARTIAL_ANSWER,
	ECB_SUGGEST_TO_LIBRARY,
	EMAIL_TYPE_FROM_REPLY,
	EOPR_ANSWERED,
	EXPERT_ANS_IDLE,
	EXPERT_ANS_SENDING,
	FETCH_AREA_DATA_STR,
	// LSSS_VALID,
	ME_CREATE,
	ME_CREATE_AS_MY,
	ME_CREATE_AS_CLOSED,
	ME_CREATE_AS_NEW,
	ME_ST_BUSY,
	ME_ST_CREATED,
	ME_ST_IDLE,
	ME_START,
	MEDIA_ACTION_URL as MEDIA_ACTION,
	//REPLY_CHANNEL_TO_SERVICE_TYPE,
	MP_NONE,
	NEW_MANUAL_CTX,
	NOTEOPR_DEFAULT,
	OTHER_CONTACTS_FETCH_ALL_SIZE,
	OTHER_CONTACTS_FETCH_MIN_SIZE,
	OTHER_CONTACTS_FETCH_SIZE,
	PLF_DOWNLOAD,
	PLF_PREVIEW,
	PLF_SAVE_AS_EML,
	RC_EMAIL,
	RC_FACEBOOK,
	RC_LINE,
	RC_SMS,
	RC_TWITTER,
	RC_VK,
	RC_VOICE,
	RC_INSTAGRAM,
	RPLY_COLLABORATE,
	RPLY_COMMENT,
	RPLY_ERRAND,
	RPLY_EXT_FWD,
	RPLY_MANUAL,
	RPLY_MANUAL_COMMENT,
	ST_ACQUIRING,
	THROTTLED_TMR,
	TMR_AGENT_NO_TYPING,
	TMR_AUTO_SAVE,
	UNSELECT,
	// WFP_MANUAL_ERRAND,
	INPUTS_OPEN_ERRAND,
	INPUTS_MANUAL_ERRAND,
	PREF_LIST_VIEW,
	SOLIDUS,
	CLOSE_BROWSER_MANUALLY,
	ERR_CHAT_ERRAND_OWNED_BY_OTHER,
	ERR_INVALID_OPEN_REVIEW,
	emptyArray,
	emptyObject,
	ACQUIRE_STATUS_OPEN,
	ACQUIRE_STATUS_CLOSE,
	RC_WHATSAPP,
	MY_ERRANDS,
	ERROR_MESSAGE,
	RC_GOOGLECHAT,
	RC_LINKEDIN,
	MP_BASIC_CALL,
	MP_MINIMIZE,
	INPUTS_MANUAL_CALL,
	CTX_POSTPONED
} from '../../../common/v5/constants';
import {
	handleCloseWindow,
	isInsideAreaTags,
	isValidIntegration,
	getChatArea,
	getChatTagLevels,
	getTagName,
	flattenTagLevels,
	dataURItoBlob,
	getExtQueueType,
	isTelavox,
	pleaseWaitString,
	DummyFunction
} from '../../../common/v5/helpers';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../../common/v5/chatbotConstants'
import { openPreviewWindowWithPost, IsContextSearch } from '../../../common/v5/utils';
import AgentSocket from '../../../common/v5/agentsocket';
import { readNotification } from '../../../common/v5/socket';
import {
	getLanguageName
} from '../../../components/v5/ErrandTranslation';
import { printErrands } from "../../actions/print";
import { TOAST_TYPE, TOAST_POSITION } from '../../../reactcomponents/Modal';
import { getStreamXHRDataWithJSON } from './xhrHandler';
import { ErrandRewriteAnswerBase } from '../../constants/endpoints';

const acquireErrandState = state => state.app.errand.acquireErrand;

const closedChatState = state => state.chat.closedChatErrand.data;

function checkOpenedByExternal(state) {
	return state.external.openedByExternal || getExtQueueType() != "";
}

export const accessErrand = rootState => rootState.app.errand;

export const errand = createActionCreators([
	keyAcquireErrand,
	keyAcquireOtherContacts,
	keyAssociatedAreaData,
	keyAssociatedExtendedData,
	keyEEAnswerSave,
	keyErrandAreaData,
	keyErrandChangeErrandArea,
	keyErrandContacts,
	keyErrandMyErrands,
	keyFetchLinkedErrands,
	keyErrandContactsHistories,
	keyErrandNotesDeleteAttachment,
	keyErrandNotesUploadAttachment,
	keyErrandRemoveAllTempAttachment,
	keyErrandRemoveTemporaryAttachment,
	keyErrandSuggestToLibrary,
	keyErrandUploadAnswerAttachment,
	keyExternalexpertEdit,
	keyFetchClientAddress,
	keyFetchClientsAddressList,
	keyFetchErrandNotes,
	keyFetchExtendedData,
	keyFetchExternalExpertList,
	keyFetchExternalExpertThread,
	keyFetchExternalQueries,
	keyFetchHistory,
	keyFetchTranslateDetect,
	keyLoadBasicErrand,
	keyMultiThreadsHistory,
	keyOtherContactHistory,
	keyReloadOneErrand,
	keySendAnswer,
	keySendEE,
	keyTryGetOneBasicErrand,
	keyTurnExternalExpertLightOff,
	keyUpdateAnswer,
	keyUpdateAnswerEE,
	keySavedEE,
	keyUpdateManualErrand,
	keyReopenErrand,
	keyResendAnswer,
	keyPublishErrand,
	keyUnpublishErrand,
	keyGetOneRelatedErrand,
	keyDeleteOneErrandNotes,
	keyCreateOneErrandNotes,
	keyUpdateOneErrandNotes,
	keyErrandTranslation,
	// keyErrandForwardToArea,
	keyGetAreaNotification,
	keyErrandPrintContent,
	// keyCloseErrand,
	// keyCloseErrands,
	keySocialMediaUserProfile,
	keySocialMediaPostsHistory,
	keySocialMediaPostActions,
	keyErrandFetchAreaTags,
	keyErrandFBRatings,
	keySocialMediaPostsMessages,
	keySocialMediaCheckFriendship,
	keySocialMediaPostsUpdateAns,
	keyGetKnowledgeBase,
	keyFeaturedQuestion,
	keyErrandAttachmentViaURL,
	keyGetChatAreas,
	keyForwardChat,
	keyGetChatAgents,
	keyGetChatCandidates,
	keyGetCustomerByAddress,
	keyGetContactCardEntry,
	keyDeleteContactCardEntry,
	keyAddEntryToContactCard,
	keyGetCustomerNotes,
	keyDeleteCustomerNote,
	keyPostCustomerNote,
	keyGetContactBook,
	keyGetErrandStatus,
	keyCustomerAvatar,
	keyAnonymize,
	keyDataExportLog,
	keyDeleteExportContact,
	keyExportContact,
	keyGetAgentListInChat,
	keyHasMembership,
	keyRecordEditorSize,
	keyLastErrandInThread,
	keyErrandId,
	keyErrandUpdateLockToMe,
	keyErrandPostponeDate,
	keyErrandChangeInternalState,
	keyErrandFBEmotionlist,
	keyOpenErrandData,
	keyErrandMinLoad,
	keyVerifyChannelSelection,
	keyFetchUserVote,
	keyPostUserVote,
	keyAcquireErrandOwnerStatus,
	keyAgentWorkingOnErrand,
	keyCompanyContactHistories,
	keyCompanyOtherContacts,
	keyErrandSuggestedAnswer,
	keyCheckEECanSendExternalID,
	keyCRMDataFetch,
	keyGenAIAnswer,
	keyGetAnnouncement,
	keyAgentSetErrandView,
	keyErrandWorkflowSettings
]);

export const errandPrint = errandList => async(getErrandPrint({errandList}),
	errand[keyErrandPrintContent]);

export const removeOneErrandNotes = (id, p) => async(
	deleteOneErrandNotes(id, p),
	errand[keyDeleteOneErrandNotes],
	update(p, {$merge: {id}})
);

// const closeErrand = p => async(postCloseErrand(p), errand[keyCloseErrand]);
//
// const closeErrands = p => async(postCloseErrands(p), errand[keyCloseErrands]);

export const createOneErrandNotes = p => async(postErrandNotes(p),
	errand[keyCreateOneErrandNotes], p
);

export const updateOneErrandNotes = (id, p) => async(postOneErrandNotes(id, p),
	errand[keyUpdateOneErrandNotes],
	update(p, {$merge: {id}})
);

const tryLoadBasicFactory = (key, force) => id => tryCachedAsync(
	id
	, D_BASIC_ERRANDS
	, multiAsync(postOneBasicErrand(id), errand[key], {id})
	, null
	, force
);

const reloadBasicFactory = key => tryLoadBasicFactory(key, true);

const rawReloadBasicErrand = reloadBasicFactory(keyReloadOneErrand);

export const ErrandMyErrandsAsync = p => async(getErrandMyErrands(p),
	errand[keyErrandMyErrands]
);

export const FetchLinkedErrandsAsync = p => async(getErrandLinkedErrands(p),
	errand[keyFetchLinkedErrands]
);

const updateAllBasicFactory = request => id => dispatch =>
	dispatch(request(id))
		.then(result => {
			if (result.error) {
				return Promise.reject(new Error(result.error));
			}
			dispatch(updateAllBasicErrandData(result));
			return result;
		});

export const reloadBasicErrand = updateAllBasicFactory(rawReloadBasicErrand);

// change the backend errand's area data with the updated area.
const changeErrandArea = (area, errandId, cipherKey) => (dispatch, getState) => {
	const p = {area, errand: errandId, cipherKey: cipherKey};
	dispatch(popPleaseWait(I("updating area")))
	return dispatch(async(postErrandPassiveChangeErrandArea(p),
		errand[keyErrandChangeErrandArea]))
		.then(() => {
			const result = getState().app.errand.changeErrandArea.data;
			if(result.error) {
				return Promise.reject(new Error(result.error));
			} else if(!result.success) {
				return Promise.reject(new Error('failed to update errand area'));
			}
			return update(p, {result: {$set: result}});
		})
		.then(({errand}) => {
			dispatch(clearPopWaiting());
			dispatch(reloadBasicErrand(errand))
		})
		.catch(() => {
			dispatch(clearPopWaiting());
		});
};

export const setErrandPostponeDate = (id, date, areaId, areaToReload) => (dispatch, getState) => {
	let p = {
		errandId: id,
		postponeDate: date,
		areaId: areaId
	};
    return dispatch(async(postSetErrandPostponeDate(p),
		errand[keyErrandPostponeDate]))
		.then(result =>{
			if(result.error == ""){
				dispatch(reloadBasicErrand(id));
				var param = errandExternalParam(id, false);
				dispatch(fetchExtendedData(param));
				if(result.areaId && result.areaId != 0){
					dispatch(dispatch(errandAreaData(result.areaId)));
				}
			}
		});
}

export const fetchClientAddress = p => async(postErrandFetchClientAddress(p),
	errand[keyFetchClientAddress]
);

export const fetchClientsAddressList = (errandId) => async(
	postErrandFetchClientsAddressList({errandId}),
	errand[keyFetchClientsAddressList]
);

// NOTE: this AJAX can only be called while opening errand. This is because
// backend will update action tags for opening errand action. Can NOT use this
// as reload extended data. Any extended data that is outdated must use another
// way to update.
const fetchExtendedData = p => async(postErrandExtendedData(p),
	errand[keyFetchExtendedData]
);

export const openErrandData = (errandId, p, threadId) => async(
	getOpenErrandData(errandId, p)
	, errand[keyOpenErrandData]
	, {errandId, threadId}
);

export const errandMinLoad = (errandId, p) => async(
	getErrandMinLoad(errandId, p)
	, errand[keyErrandMinLoad]
	, {errandId}
);

export const loadKnowledgeBase = (id, param, force) => tryCachedAsync(
	id
	, "errandKnowledgeBase"
	, async(getKnowledgeBase(id, param), errand[keyGetKnowledgeBase])
	, false
	, force
);

export const fetchVoteFeaturedQuestion = (id) => {
	return async(getVoteFeaturedQuestion(id), errand[keyFeaturedQuestion]);
}

export const setVoteFeaturedQuestion = (p) => {
	return async(postVoteFeaturedQuestion(p), errand[keyFeaturedQuestion]);
}

export const fetchUserVote = (id) => {
	return async(getUserVote(id), errand[keyFetchUserVote]);
}

export const setUserVote = (p) => {
	return async(postUserVote(p), errand[keyPostUserVote]);
}

export const generateAIAnswer = (p) => {
	return async(generateAIAnswerQues(p), errand[keyGenAIAnswer]);
}

export const generateAIAnswerStreaming = (q, streamId) => (dispatch) => {
	q.stream = true;
	getStreamXHRDataWithJSON(ErrandRewriteAnswerBase, q, (chunk, json) => {
		if(json.errand_id) {
			dispatch(updateAgentAssistResponse(json.errand_id));
		}
		if(json.received) {
			dispatch(updateAgentAssistReceived(json.received, streamId));
		}
		if(json.session) {
			dispatch(fetchAgentAssistInfo(json.session));
		}
		//if(json.status) {
		//	dispatch(updateAgentAssistResponse(json.status));
		//}
		if(chunk) {
			dispatch(fetchAgentAssistSuccess(streamId, chunk));
		}
	})
	.then((finalOutput) => {
		return dispatch(fetchAgentAssistDone(finalOutput));
	})
	.catch((error) => {
		console.error('Error:', error);
		return dispatch(fetchAgentAssistFailure(error));
	});
}

export const fetchAllNotes = (errandId, type) => {
	const q = {errand: errandId, type};
	return async(getErrandNotes(q), errand[keyFetchErrandNotes], q);
};

export const deleteNote = (errandId, type) => {
	const q = {errand: errandId, type};
	return async(getErrandNotes(q), errand[keyFetchErrandNotes], q);
};

export const fetchErrandSuggestedAnswer = (id) => {
	return async(getErrandSuggestedAnswer(id), errand[keyErrandSuggestedAnswer]);
};

const otherContactsBase = (
	id
	, answered
	, unanswered
	, acquirable
	, offset
	, limit
	, action
	, grpByCompany
) => {
	let q = {
			nocache: Math.random(),
			answered,
			unanswered,
			acquirable,
			offset,
			limit,
			grpByCompany
		};
	if (acquirable) {
		q.ownable = true;
	}
	return multiAsync(getOneErrandContacts(id, q),
		action,
		{id, unanswered, acquirable, offset, limit, grpByCompany}
	);
};

const otherContactsCore = (
	id
	, answered
	, unanswered
	, acquirable
	, offset
	, limit
	, key
	, grpByCompany
) => otherContactsBase(
	id
	, answered
	, unanswered
	, acquirable
	, offset
	, limit
	, errand[key]
	, grpByCompany
);

export const companyErrandHistory = (id, offset) =>
	otherContactsCore(
			id
			, true
			, false
			, false
			, offset
			, OTHER_CONTACTS_FETCH_SIZE
			, keyCompanyContactHistories
			, true
	);

export const companyErrandOtherContacts = (id, offset) =>
otherContactsCore(
		id
		, false
		, true
		, false
		, offset
		, OTHER_CONTACTS_FETCH_SIZE
		, keyCompanyOtherContacts
		, true
);

export const historyErrandContacts = (id, offset) =>
	otherContactsCore(
		id
		, true
		, false
		, false
		, offset
		, OTHER_CONTACTS_FETCH_SIZE
		, keyErrandContactsHistories
	);

const openErrandContacts = (id, offset, limit) =>
	otherContactsCore(
		id
		, false
		, true
		, false
		, offset
		, limit
		, keyErrandContacts
	);

const loadOtherContacts = (id, offset) => openErrandContacts(
	id
	, offset
	, OTHER_CONTACTS_FETCH_SIZE
);

const otherContacts = (id, offset) => loadOtherContacts(id, offset);

const allAcquirableOtherContacts = id => openErrandContacts(
	id
	, 0
	, OTHER_CONTACTS_FETCH_ALL_SIZE
);

export const loadMoreOtherContacts = loadOtherContacts;

const postHistoryBase = (key, p, threadId) =>
	async(
		postErrandHistory(p)
		, errand[key]
		, {threadId}
	);

const fetchHistory = (p, threadId) => postHistoryBase(
	keyFetchHistory
	, p
	, threadId
);

const ocHistory = (p, threadId, force) => tryCachedAsync(
	threadId
	, D_HISTORY
	, postHistoryBase(
		keyOtherContactHistory
		, p
		, threadId
	)
	, false
	, force
);

export const historyByErrandAndThread = (
	errandId,
	threadId,
	force
) => tryCancellableCachedAsync(
	threadId,
	D_HISTORY,
	cancellableAsync(
		postErrandHistory(errandExternalParam(errandId, false)),
		errand[keyMultiThreadsHistory],
		{ threadId }
	),
	false,
	force,
	true
);

const rawGetOneRelatedErrand = reloadBasicFactory(keyGetOneRelatedErrand);

const getOneRelatedErrand = updateAllBasicFactory(rawGetOneRelatedErrand);

const tryGetBasicErrandBase = tryLoadBasicFactory(keyTryGetOneBasicErrand);

const tryGetOneRelatedErrand = updateAllBasicFactory(tryGetBasicErrandBase);

const updateErrandsAcquiredState = onlyCurrentErrand => (dispatch, getState) => {
	const acquireData = acquireErrandState(getState()).data
		, { errand, owner } = acquireData.acquire
		;
	if (!owner) {
		return;
	}
	const related = acquireData.order;
	let errandIDs;
	if (!onlyCurrentErrand && related && related.length) {
		errandIDs = related.slice();
		errandIDs.push(errand);
	} else {
		errandIDs = [errand];
	}
	const { id, name } = owner;
	dispatch(syncAcquiredState(errandIDs, id, name));
};

const acquireErrand = (
	errandID
	, ctx
	, areas
	, doNotSyncAcquiredState
	, isAcquireFromOtherAgentErrand
	, postMessageOpen
) => (dispatch, getState) => {
	let source = ctx;
	if (ctx === CTX_NEW) {
		if (getState().domain[D_BASIC_ERRANDS].byId[errandID].acquired) {
			// this is to simulate condition of open errand from "my context"
			// because this errand is already been acquired but the errand list
			// if not yet being refreshed, so it should be considered acquire
			// from "my context" because this errand should already inside
			// "my context". This fix the issue where related errands are not
			// fetched when open an acquired errand from "new context".
			source = CTX_MY;
		}
	}
	const isOpenedByExternal = typeof externalqueue !== "undefined"
			&& (externalqueue.isExternal == true || isTelavox())
		, param = {
			errand: errandID
			, fetch_data_only_if_acquired: false
			, errand_was_opened_by_external_system: isOpenedByExternal
			, source
			, filterList: areas
			, isAcquireFromOtherAgentErrand
			, postMessageOpen
		}
		;
	return dispatch(async(
			postErrandAcquire(param)
			, errand[keyAcquireErrand]
			, param
		))
		.then(result => {
			const { wip, err } = acquireErrandState(getState());
			// TODO: wip should always false by the time async then return.
			// Checking wip is redundant and err might always null too for then.
			// 'async'.err only deal with network between browser and server
			// error and should appear in catch by right. Any business domain
			// error (Cention error) is not handled.
			if(wip == false && typeof err !== 'undefined' && err != null){
				return Promise.reject({
					err: err
				});
			} else if (!doNotSyncAcquiredState) {
				dispatch(updateErrandsAcquiredState(true));
			}
			let ui = getState().app.workflow.ui;
			let counters = getState().domain.counters;
			let chatErrandCount = getState().app.workflow.errandListChat.length;
			if(ui.subscribeErrandCountPM.length > 0){
				postErrandCount(counters.my.count + chatErrandCount,
					ui.subscribeErrandCountPM);
			}
			return result;
		})
		.catch(err => {
			if(typeof err.err !== 'undefined' &&
				typeof err.err.message !== 'undefined' &&
				err.err.message.length > 0){
					dispatch(togglePopAlert(err.err.message));
					dispatch(closeErrandView(true));
					 return Promise.reject(err);
				}
		});

};

// const acquireErrand = (errandID, ctx, areas, doNotSyncAcquiredState) => (dispatch, getState) => {
// 	return dispatch(async(postErrandAcquire({
// 			errand: errandID,
// 			fetch_data_only_if_acquired: false,
// 			errand_was_opened_by_external_system: false, // TODO: make it changeable
// 			source: ctx,
// 			filterList: areas
// 		}), errand[keyAcquireErrand]))
// 		.then(result => {
// 			const { err, errors } = getState().app.errand.acquireErrand;
// 			if(err && errors && errors.length >= 3) {
// 				return Promise.reject({err: errors.join(',')});
// 			} else if (err) {
// 				return dispatch(acquireErrand(errandID, ctx, areas, doNotSyncAcquiredState));
// 			}
// 			return result;
// 		});
// };

const reviewDataActions = {
	"associated_list": (data, _, { acquire }) => (dispatch, getState) => {
		const [ errandIds, map ] = csvStringToIntArrayAndMap(data)
			, { errand } = acquire
			// purposely not sharing getState as later may get a much later
			// basic errands data and initial thread id should match the
			// starting request of related errands.
			, initialThreadId = initialThreadIDSelector(getState())
			;
		if (typeof map[errand] === "undefined") {
			errandIds.unshift(errand);
		}
		return dispatch(loadRelatedErrands(errandIds))
			.then(() => dispatch(moveToAssociateErrands(
				true
				, errandIds
				, getState().domain[D_BASIC_ERRANDS].byId
				, false
				, initialThreadId
			)));
	}
};

const syncReviewContent = (data, acquireStateData) => dispatch => {
	const promises = [];
	$.each(reviewDataActions, (k, f) => {
		let fieldData;
		if (data) {
			fieldData = data[k];
		}
		promises.push(dispatch(f(fieldData, data, acquireStateData)));
	});
	return Promise.all(promises);
};

const acquireAndSyncRelated = (isSwitching, ...args) => (dispatch, getState) =>
	dispatch(acquireErrand(...args))
		.then(result => {
			if (isSwitching) {
				return result;
			}
			const state = getState()
				, { data: ae } = acquireErrandState(state)
				;
			if (!ae || !ae.acquire || !ae.acquire.data) {
				return;
			}
			if (isValidAcquireErrandReviewContext(state)) {
				return dispatch(syncReviewContent(reviewData(state), ae))
					.then(() => result);
			}
			const { data, errand } = ae.acquire;
			let { related_errands } = data
				, found
				;
			if (!related_errands) {
				related_errands = emptyArray;
			}
			// make sure related_errand not include the current acquiring errand.
			$.each(related_errands, (i, v) => {
				if (v === errand) {
					found = {index: i};
					return false;
				}
			});
			if (!found) {
				// if related errands do not include current errand then put it
				// as first of the list.
				related_errands = update(
					related_errands
					, {$unshift: [errand]}
				);
			}
			dispatch(syncRelatedErrands(errand, related_errands, false));
			return result;
		})
		.catch(err => {
			 return Promise.reject(err);
		});

const showBlueBubbleRelatedErrands = (
	dispatch
	, currentID
	, displayId
	, errands
	, basicById
) => {
	// raise a blue bubble popup notification for group errands
	if (errands && errands.length) {
		const related_errands = errands
				.filter(id => id != currentID)
				.map(id => {
					const errand = basicById[id];
					if (!errand) {
						console.log("dbg: invalid errand:", id, currentID);
					}
					return errand.data.displayId;
				})
			;
		if (related_errands.length) {
			let errandIDs = related_errands.join(", ");
			if (errandIDs.length > 500) {
				errandIDs = errandIDs.substr(0, 497) + "...";
			}
			dispatch(addNewPopupNotification({
				text: "MsgErrandOpenGroupErrand"
				, errand: displayId
				, related_errands: errandIDs
			}));
		}
	}
};

const getAcquireErrandOwnerStatus = (
	errandID
) => dispatch => {
	return dispatch(async(
			postGetErrandAcquireOwnerStatus({
				errand: errandID
			}),
			errand[keyAcquireErrandOwnerStatus]
		))
		.then(result => {
			return result;
		});
	};

export const addAgentWorkingOnErrand = (ids) =>
	multiAsync(postAgentWorkingOnErrand({ids}), errand[keyAgentWorkingOnErrand]);

export const removeAgentWorkingOnErrand = (ids) =>
	multiAsync(putAgentWorkingOnErrand({ids}), errand[keyAgentWorkingOnErrand]);

export const removeAgentWorkingOnOneErrand = (id) => async(dispatch,getState) => {
	const state = getState();
	await new Promise((resolve, reject) => {
		resolve(dispatch(putAgentWorkingOnErrand({ids: id})))
	});
	handleCloseWindow(state);
};

export const fetchAgentWorkingOnErrand = (ownerOnly, ids) =>
	async(getAgentWorkingOnErrand({ownerOnly, ids}), errand[keyAgentWorkingOnErrand]);

export const fetchAgentWorkingOnSelectedErrands = () => (dispatch, getState) => {
	let ids = getSelectedErrandIds(getState()).toString();
	return dispatch(async(getAgentWorkingOnErrand({ownerOnly: false, ids}), errand[keyAgentWorkingOnErrand]));
}

const reloadSearch = (() => dispatch => {
	dispatch(showMultipleActions(false));
	dispatch(handleProcessing(true));
	dispatch(handleResetOffset());
	return dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
});

const waitAcquiringErrandsAction = I("acquiring errand(s) in progress");

export const acquireFromOtherAgentErrand = (
	errandID,
	isAcquireFromOtherAgentErrand,
	isErrandView
) => (dispatch, getState) => {
	dispatch(popPleaseWait(waitAcquiringErrandsAction));
	dispatch(getAcquireErrandOwnerStatus(errandID))
		.then(result => {
			let messages = [], toAcquire = [];
			if(typeof result.error !== 'undefined') {
				return Promise.reject({
					err: new Error(result.error)
				});
			} else if(typeof result.message !== 'undefined') {
				dispatch(togglePopAlert(result.message));
			} else {
				$.each(result, (k, v) => {
					const { Id, message } = v;
					if (Id) {
						toAcquire.push(Id);
					} else if (message) {
						messages.push(message);
					}
				});
				if (toAcquire.length > 0) {
					dispatch(recursiveAcquireErrands(toAcquire, 0, isAcquireFromOtherAgentErrand))
					.then(({results, errors}) => {
						const currentContext = contextMemo(getState());
						let loadMsg = "acquireFromOtherAgentErrand" +
							messages.length;
						if(typeof errors !== 'undefined' && errors){
							$.each(errors, (i, err) => {
								messages.push(err.message);
							});
							dispatch(togglePopAlert(messages));
							return Promise.reject({errors: errors});
						} else if(messages.length > 0) {
							if(IsContextSearch(currentContext)){
								dispatch(reloadSearch())
									.then(() => {
										dispatch(togglePopAlert(messages));
								});
							} else {
								dispatch(loadList(loadMsg))
									.then(() => {
										dispatch(togglePopAlert(messages));
								});
							}
							return results;
						} else {
							if(IsContextSearch(currentContext)){
								dispatch(reloadSearch())
									.then(() => {
										dispatch(clearPopWaiting());
								});
							} else {
								dispatch(loadList(loadMsg))
									.then(() => {
										if (isErrandView){
											const wfSettings = getState().app.workflow.fetchWfSettings.data
												, { activeUserId, activeUserName, activeUserPhoto } = wfSettings;
											let errandIDs = [];
											const acquireData = acquireErrandState(getState()).data;
											// TODO: can not mutate redux state
											acquireData.acquire.acquired = true;
											acquireData.acquire.owner.id = 	activeUserId;
											acquireData.acquire.owner.name = activeUserName;
											acquireData.acquire.owner.photo = activeUserPhoto;
											dispatch(syncAcquiredOwner(acquireData.acquire.owner));
											dispatch(reloadBasicErrand(errandID))
											.then(() => {
												dispatch(selectShowReply(RPLY_ERRAND, true));
											});
										}
										dispatch(clearPopWaiting());
								});
							}
							return results;
						}
					});
				} else {
					dispatch(togglePopAlert(messages));
				}
			}
			return result;
		})
		.catch(err => {
			dispatch(clearPopWaiting());
			return Promise.reject(err);
		});
	};

const blueBubbleRelatedErrands = ({ id: currentID, displayId }) =>
	(dispatch, getState) => {
		// raise a blue bubble popup notification for group errands
		const state = getState()
			, ae = acquireErrandState(state).data
			;
		if (ae && ae.acquire && ae.acquire.data &&
			ae.acquire.data.related_errands &&
			ae.acquire.data.related_errands.length) {
			return showBlueBubbleRelatedErrands(
					dispatch
					, currentID
					, displayId
					, ae.acquire.data.related_errands
					, state.domain[D_BASIC_ERRANDS].byId
				);
		}
	};

const loadRelatedErrands = (errands, reload) => dispatch => {
	let dispatchee = []
		, ids = []
		, func
		;
	if (reload) {
		func = getOneRelatedErrand;
	} else {
		func = tryGetOneRelatedErrand;
	}
	$.each(errands, (i, v) => {
		dispatchee.push(dispatch(func(v)));
			ids.push(v);
	});
	dispatchee.push(dispatch(addAgentWorkingOnErrand(ids.toString())))
	return Promise.all(dispatchee);
}

const fetchRelatedErrands = currentErrandID => (dispatch, getState) => {
	const related = acquireErrandState(getState()).data.order.filter(id => id !== currentErrandID);
	if (!related || !related.length) {
		return Promise.resolve();
	}
	return dispatch(loadRelatedErrands(related, true));
};

const fetchRelatedErrandsAndCheckBlueBubble = (errand, isSwitching) =>
	dispatch => dispatch(fetchRelatedErrands(errand.id))
		.then(res => {
			if (isSwitching) {
				return res;
			}
			// no need wait this blue bubble complete
			dispatch(blueBubbleRelatedErrands(errand));
			return res;
		});

const loadChatRelatedErrandsAndCheckBlueBubble = chat =>
	(dispatch, getState) => {
		if (!preselectChatAssociatedErrands(getState())) {
			return Promise.resolve();
		}
		const { acquiredRelatedErrands } = chat.sessiondata;
		if (!acquiredRelatedErrands) {
			return Promise.resolve();
		}
		const { acquired } = acquiredRelatedErrands;
		if (!acquired || !acquired.length) {
			return Promise.resolve();
		}
		return dispatch(loadRelatedErrands(acquired))
			.then(res => {
				const { threadId, data } = chat.errand
					, { id, displayId } = data
					;
				// NOTE: purposely no need check if related errands (acquired)
				// having the same thread ID or not because live chat do not has
				// threaded errand.
				dispatch(syncOtherContactErrands(id, acquired));
				showBlueBubbleRelatedErrands(
					dispatch
					, id
					, displayId
					, acquired
					, getState().domain[D_BASIC_ERRANDS].byId
				);
				return res;
			});
	};

const fullAcquireErrand = (eID, ctx, areas) => (dispatch, getState) => {
	return dispatch(acquireAndSyncRelated(true, eID, ctx, areas, true))
		.then(() => {
			// do not wait for following request to finish as it is not
			// important for open errand front-end.
			dispatch(updateErrandsAcquiredState());
			// dispatch(fetchRelatedErrands(eID));
		});
};

const acquireOthers = (errandID, isAcquireFromOtherAgentErrand) => async(postErrandAcquire({
		do_not_fetch_data: true,
		errand: errandID,
		fetch_data_only_if_acquired: true,
		errand_was_opened_by_external_system: false,
		// force it always my errand as it's make sense for acquiring errands
		// from other context because it only can happened when errand opened
		// equal my errand context.
		source: CTX_MY,
		isAcquireFromOtherAgentErrand
	}),
	errand[keyAcquireOtherContacts]
);

const recursiveAcquireErrands = (errands, current, isAcquireFromOtherAgentErrand, results, errors) => dispatch => {
	const errandID = errands[current];
	return dispatch(acquireOthers(errandID, isAcquireFromOtherAgentErrand))
		.then(result => {
			return mcamPromiseByID('acquireErrand', result);
		})
		.then(result => {
			if(!result.acquired) {
				return Promise.reject({
					err: new Error('fail to acquire ' + errandID)
				});
			}
			if(!results) {
				results = {};
			}
			results[errandID] = result;
			return ++current;
		})
		.catch(res => {
			let err;
			if(!res.err) {
				// this error cause by AJAX call
				err = res;
			} else {
				err = res.err;
			}
			if(!errors) {
				errors = {};
			}
			errors[errandID] = err;
			return Promise.resolve(++current);
		})
		.then(current => {
			return dispatch(reloadBasicErrand(errandID))
				.then(() => {
					return current;
				})
				.catch(() => {
					// TODO: send notification but doesn't matter if it fail.
					return Promise.resolve(current);
				});
		})
		.then(current => {
			if(current < errands.length) {
				return dispatch(recursiveAcquireErrands(
					errands
					, current
					, isAcquireFromOtherAgentErrand
					, results
					, errors
				));
			}
			return Promise.resolve({results, errors});
		});
};

function getOtherErrands (state, all) {
	if (all) {
		return getAllUnacquiredErrandsMemoize(state);
	}
	return getSelectedOpenErrandsMemoize(state);
}

const checkAnyOpenErrandContacts = id => (dispatch, getState) => {
	const state = getState();
	if (hasOtherErrandsNoHistorySelector(state) ||
		ocHasOpenErrandMemoize(state)) {
		return Promise.resolve({hasOpen: true});
	} else if (!state.app.errand.contacts.data.noMore) {
		return dispatch(otherContactsCore(id, false, true, true, 0,
			OTHER_CONTACTS_FETCH_MIN_SIZE, noReducerAsync, true))
			.then(data => {
				if (data.list && data.list.length) {
					if (data.list.length !== 1 ||
						data.list[0].id !== getState().app.errand.currentErrand.id) {
						return Promise.resolve({hasOpen: true});
					}
				}
				return Promise.resolve({hasOpen: false});
			});
	}
	return Promise.resolve({hasOpen: false});
};

const moveToAssociateErrands = (
	select
	, ids
	, errands
	, checkAcquire
	, initialThreadId
) => dispatch => {
	let toMoveRelated = []
		, toMoveOC = []
		, toAcquire = []
		;
	$.each(ids, (i, id) => {
		const errand = errands[id];
		if (errand) {
			if (checkAcquire && !errand.acquired) {
				toAcquire.push(id);
			} else if (errand.threadId === initialThreadId) {
				toMoveRelated.push(id);
			} else {
				toMoveOC.push(id);
			}
		}
	});
	if (toMoveRelated.length) {
		console.log("dbg: related errands-to-be-moved: ", toMoveRelated);
		dispatch(moveOpenToAssociate(toMoveRelated, select, true));
		dispatch(addAgentWorkingOnErrand(toMoveRelated.toString()));
	}
	if (toMoveOC.length) {
		console.log("dbg: open errands-to-be-moved: ", toMoveOC);
		dispatch(moveOpenToAssociate(toMoveOC, select, false));
		dispatch(addAgentWorkingOnErrand(toMoveOC.toString()));
	}
	return toAcquire;
}

const acquireOtherErrandsBase = (
	select
	, ids
	, errands
) => (dispatch, getState) => {
	const initialThreadId = initialThreadIDSelector(getState())
		, toAcquire = dispatch(moveToAssociateErrands(
			select
			, ids
			, errands
			, true
			, initialThreadId
		))
		;
	if (!toAcquire.length) {
		return Promise.resolve();
	}
	if (process.env.NODE_ENV !== 'production') {
		console.log("dbg: errands-to-be-acquired: ", toAcquire);
	}
	return dispatch(recursiveAcquireErrands(toAcquire, 0))
		.then(({ results, errors }) => {
			if (results) {
				let toMoveRelated = []
					, toMoveOC = []
					;
				$.each(results, (k, v) => {
					// it should be fine to use back 'errands' within this async
					// callback as id/threadId is not change. This is not true
					// if it is not id/threadId.
					const { id, threadId } = errands[k];
					if (threadId === initialThreadId) {
						toMoveRelated.push(id);
					} else {
						toMoveOC.push(id);
					}
				});
				if (toMoveRelated.length) {
					if (process.env.NODE_ENV !== 'production') {
						console.log(
							"moved related errands after acquire:"
							, toMoveRelated
						);
					}
					dispatch(moveOpenToAssociate(toMoveRelated, select, true));
					dispatch(addAgentWorkingOnErrand(toMoveRelated.toString()));
				}
				if (toMoveOC.length) {
					if (process.env.NODE_ENV !== 'production') {
						console.log(
							"moved other-contact errands after acquire:"
							, toMoveOC
						);
					}
					dispatch(moveOpenToAssociate(toMoveOC, select, false));
					dispatch(addAgentWorkingOnErrand(toMoveOC.toString()));
				}
			} else if (typeof errors !== 'undefined' && errors) {
				let message = "";
				$.each(errors, (i, err) => {
					message = message + err.message + " ";
				});
				dispatch(togglePopAlert(message));
				return Promise.reject({errors: errors});
			}
			// TODO: save result
			if (process.env.NODE_ENV !== 'production') {
				console.log(
					'dbg: acquire many errands done:'
					, {results, errors}
				);
			}
		});
};

const acquireOneOtherErrandBase = (
	errandId
	, threadId
) => (dispatch, getState) => {
	const errand = getState().domain[D_BASIC_ERRANDS].byId[errandId]
		;
	if (!errand) {
		return Promise.reject({id: errandId, thread: threadId});
	}
	return dispatch(acquireOtherErrandsBase(
		true
		, [errandId]
		, {[errandId]: errand}
	));
};

export const acquireOneOtherErrand = (errandId, threadId) => dispatch =>
	dispatch(acquireOneOtherErrandBase(errandId, threadId))
		.catch(({id, thread}) => {
			console.log("failed acquire errand:", {id, thread});
		})
		.then(() => {
			dispatch(showHideOtherContactErrand(false));
		});

const acquireOtherErrandsCore = all => (dispatch, getState) => {
	const state = getState()
		, { ids, errands } = getOtherErrands(state, all)
		;
	if (!ids.length) {
		return Promise.reject();
	}
	return dispatch(acquireOtherErrandsBase(all, ids, errands));
};

export const acquireOtherErrands = all => (dispatch, getState) => {
	if(!all) {
		// acquire had already set this action prior to this.
		dispatch(setAcquiringErrandsProgress(true));
	}
	return dispatch(acquireOtherErrandsCore(all))
		.then(() => {
			dispatch(setAcquiringErrandsProgress(false));
			dispatch(loadList("acquireOtherErrands"));
			const {id} = getState().app.errand.currentErrand;
			return dispatch(checkAnyOpenErrandContacts(id));
		})
		.then(({hasOpen}) => {
			const {id} = getState().app.errand.currentErrand;
			dispatch(setHasAcquirableOther(id, hasOpen));
		})
		.catch(() => {
			dispatch(setAcquiringErrandsProgress(false));
		});
};

export const acquireAllOtherErrands = () => (dispatch, getState) => {
	dispatch(setAcquiringErrandsProgress(true));
	return dispatch(allAcquirableOtherContacts(
		getState().app.errand.currentErrand.id))
		.then(() => {
			return dispatch(acquireOtherErrands(true));
		});
};

export const showOtherContactErrand = (id, threadId) => dispatch => {
	dispatch(changeOtherContactShowIDs(id, threadId));
	dispatch(ocHistory(
		errandExternalParam(id, false) // TODO: do external open apply here?
		, threadId
	));
	dispatch(showHideOtherContactErrand(true));
};

const getAreaData = (areaId, withDomain) => cachedAsync(
	areaId,
	D_AREAS,
	async(postErrandAreaData({areas: areaId}), errand[keyErrandAreaData]),
	withDomain
);

export const errandAreaData = areaId =>
	getAreaData(areaId, XFER_DOMAIN_AREA_DATA);

// allow to fetch area of associated errands (other contacts) multiple request
// together.
const associatedAreaData = areaId => tryCachedAsync(
	areaId,
	D_AREAS,
	multiAsync(postErrandAreaData({areas: areaId}),
		errand[keyAssociatedAreaData]),
	data => mcamByID(FETCH_AREA_DATA_STR, data)
);

const errandExternalParam = (errand, openedByExternal) => ({
	errand, openedByExternal
});

export const associatedExtendedData = errandId => cachedAsync(
	errandId,
	D_EXTENDED,
	multiAsync(postErrandExtendedData(errandExternalParam(errandId, false)),
		errand[keyAssociatedExtendedData]),
	XFER_DOMAIN_EXTEND_DATA
);

const sendAnswer = (p, wantUpdatedData) => (dispatch, getState) => {
	if (wantUpdatedData) {
		p = update(p, {
			return_extended_data: {$set: true}
			, return_acquire_data: {$set: true}
		});
	}
	return dispatch(async(postErrandSendErrand(p), errand[keySendAnswer]))
		.then(result => {
			const { data } = getState().app.errand.sendAnswer;
			if (!data || !data.success) {
				let error;
				if (data.error) {
					error = ". Error:" + data.error;
				} else {
					error = "";
				}
				return Promise.reject(new Error(
					"failed send errand:"
					+ p.update_id
					+ " with error message:"
					+ data.message
					+ error
				));
			} else if (typeof data.secondaryError !== "undefined") {
				dispatch(popErrorOnly(data.secondaryError));
			}
			return result;
		});
};

const updateAnswer = p => async(postErrandUpdateErrand(p),
	errand[keyUpdateAnswer]
);

const updateManualErrand = p => async(postErrandUpdateErrand(p),
	errand[keyUpdateManualErrand]
);

export const reopenErrand = p => async(postErrandReopenErrand(p),
	errand[keyReopenErrand]
);

export const resendAnswer = p => async(postErrandResendErrand(p),
	errand[keyResendAnswer]
);

export const publishErrand = p => async(postErrandPublishErrand(p),
	errand[keyPublishErrand]
);

export const unpublishErrand = p => async(postErrandUnpublishErrand(p),
	errand[keyUnpublishErrand]
);

export const errandSuggestToLibrary = p => async(
	postErrandSuggestErrandForLibrary(p),
	errand[keyErrandSuggestToLibrary]
);

const fetchAreaTags = p => async(
	postErrandFetchAreaTags(p)
	, errand[keyErrandFetchAreaTags]
);

// must return promise as caller may use it to determine if the async completed
// or not. NOTE: this basic errand is full basic errand data. It can be used for
// domain basic errand (caching) but domain basic errand can not use for this
// basic errand field because domain basic errand may be overwritten by other
// endpoint that has the similar basic errand structure but not full data.
export const basicErrand = id => async(
	postOneBasicErrand(id)
	, errand[keyLoadBasicErrand]
);

const detectLanguageAjax = p => {
	return async(postDetectTranslate({text: p.substring(0, 300)}), errand[keyFetchTranslateDetect])
}
export const translationAjax = (texts, from, to) => {
	return async(postTranslate({texts: texts, from: from, to: to}), errand[keyErrandTranslation])
}
export const checkToSendEEAjax = (toAddr) => {
	return async(postCheckEEToBeSent({to: toAddr}), errand[keyCheckEECanSendExternalID])
}

export const detectLangOnly = (text, sessionId) => (dispatch, getState) => {
	return dispatch(detectLanguageAjax(text))
		.then(textLang => {
			if(typeof textLang.error !== 'undefined' && textLang.error =="") {
				return textLang.text;
			}
		});
}
export const detectLanguage = (text, sessionId) => (dispatch, getState) => {
	return dispatch(detectLanguageAjax(text))
		.then(from => {
			if(from.error) {
				if(from.error == emptyObject){
					alert(I("Translation failed."));
				}else{
					alert(from.error)
				}
				return
			}
			if(sessionId){
				const t = getState().app.workflow.fetchWfSettings.data;
				if(t.interface !== from.text) {
					// Then set default translation 'to' same as language from customer 'fromCust'
					dispatch(setChatTranslation(sessionId, {fromCust: from.text, to: from.text, from: t.interface}));
				}else{
					// Otherwise leave it blank so that it will follow what agent selection
					dispatch(setChatTranslation(sessionId, {fromCust: "", to: "", from: t.interface}));
				}
			}
			return from.text
		});
}
export const translation = (text, from, to) => (dispatch, getState) => {
	const t = getState().app.workflow.fetchWfSettings.data;
	if(from){
		if(to){
			return dispatch(translationAjax([text], from, to))
			.then((text) => {
				if(text.error) {
					alert(text.error)
					return
				}
				return text.texts;
			})
		}else{
			return dispatch(translationAjax([text], from, t.interface))
			.then((text) => {
				if(text.error) {
					alert(text.error)
					return
				}
				return text.texts;
			})
		}
	}
};

export const reverseTranslation = (text, from, to) => (dispatch, getState) => {
	const t = getState().app.workflow.fetchWfSettings.data,
	chat = getState().app.errand.chat, ui = getState().app.errand.ui;
	if(to){
		if(from){
			return dispatch(translationAjax([text], from, to))
			.then((text) => {
				if(text.error) {
					alert(text.error)
					return
				}
				return text.texts;
			})
		} else{
			return dispatch(translationAjax([text], t.interface, to))
			.then((text) => {
				if(text.error) {
					alert(text.error)
					return
				}
				return text.texts;
			})
		}
	}
}

const fetchNotesData = (errand, chat) => (dispatch, getState) => {
	const state = getState();
	let notes = 0;
	if (allowInternalCommentMemoize(state)) {
		if (chat) {
			// TODO set notes count for chat
		} else {
			notes = state.app.errand.fetchExtendedData.data.data.errand_notes;
		}
	}
	if(notes > 0) {
		return dispatch(fetchAllNotes(errand, 'errand'));
	}
	return dispatch(noteSize(0, 'errand'));
};

const getWFSettingAndExtendErrand = (eid, wf, chat) => (dispatch, getState) => {
	let extendedData = getState().app.errand.fetchExtendedData.data;
	return dispatch(setErrandWFSettings(eid, extendedData, wf, chat));
};

const setWorkflowSettingsAndSavedData = (eid, wf, chat) => (
	dispatch
	, getState
) => {
	const state = getState();
	dispatch(getWFSettingAndExtendErrand(eid, wf, chat));
	if (!isValidAcquireErrandReviewContext(state)) {
		return;
	}
	const data = reviewData(state);
	if (data) {
		dispatch(syncReviewData(data));
	}
};

const chatServiceUseChatView = true

const enableChatViewIfClosedChat = errand => (
	errand &&
	errand.data.closed &&
	chatServiceUseChatView &&
	errand.data.service == Workflow.Errand.SERVICE_CHAT
)

const checkPostMessageOpen = param => {
	if(typeof param === 'undefined' || param == null ||
		typeof param.postMessageOpen === 'undefined' ||
			param.postMessageOpen === null) {
			return null
	}
	return param.postMessageOpen
}

const fetchCRMData = (id) => {
	return async(getCRMdatas({id}), errand[keyCRMDataFetch]);
};

const multiDispatchesOpenErrand = (
	id
	, errand
	, ctx
	, areas
	, hasEE
	, wf
	, needBasic
	, chat
	, isSwitching
	, extraParams
) => (dispatch, getState) => {
	// get the main thing first
	const p = errandExternalParam(id, false) // TODO: make false changeable
		, erd = errand.data
		, area = erd.area
		, chatSwitchOC = chat && isSwitching
		, state = getState()
		;
	let dispatches = []
		;
	if (!isSwitching) {
		dispatch(setInitialThreadID(errand.threadId));
	}
	if (!chatSwitchOC) {
		dispatches.push(dispatch(errandAreaData(area)));
	}
	if (!chat) {
		if (enableChatViewIfClosedChat(errand)) {
			dispatches.push(dispatch(closedChatErrand(errand.id)));
		} else {
			let postMessageOpen = checkPostMessageOpen(extraParams);
			dispatches.push(dispatch(acquireAndSyncRelated(
				isSwitching
				, id
				, ctx
				, areas
				, true //doNotSyncAcquiredState
				, false //isAcquireFromOtherAgentErrand
				, postMessageOpen
			)));
		}
	} else {
		if (!isSwitching) {
			// Get all agents involved in the chat
			dispatches.push(dispatch(getAgentListInChat(chat.sessionId)));
			// get queue chat sessions
			dispatches.push(dispatch(getQueueChat(areas)));
			// TODO: dispatch sessionData
		}
	}
	return $.when(...dispatches)
		.then((...previousResults) => {
			let dispatches = [];
			if (!chat) {
				if (enableChatViewIfClosedChat(errand) && !isSwitching) {
					chat = closedChatState(getState()).data;
					dispatch(setCurrentErrand(id, hasEE, chat, isSwitching));
				} else {
					let p = {needBasic};
					dispatches.push(dispatch(openErrandData(id, p, errand.threadId)));
				}
			}
			// Set suggested answer to false to reset the suggested answer from prev. errand
			dispatch(setSuggestedAnswerUsed(false));
			// Set agent open the errand into redis and return the status if owner is working on that errand
			dispatches.push(dispatch(addAgentWorkingOnErrand(erd.id)));
			return $.when(...dispatches)
				.then((...results) => {
					return previousResults.concat(results);
				});
		})
		.then(() => {
			// get the not so important things
			if (!chat) {
				dispatch(selectReplyChannelByType(erd.service));
				dispatch(checkAndSwithToExternalForward());
				openExternalSystem(id, errand, getState());
			} else {
				if (isSwitching) {
					// when switching other contact of a chat errand, no
					// parameters need to be change as the chat errand view is
					// not changing. Only the Other Contacts view will change.
					return;
				}
				// TODO clear "History" for chat - chat errand has no history, by definition
			}
			if (hasEE) {
				dispatch(multiDispatchesEE(id));
			}
			dispatch(setWorkflowSettingsAndSavedData(id, wf, chat));
			dispatch(fetchNotesData(id, chat));
			let kbParam = { "loadFirst": true, "hideTimeControl": true }
			let defaultLibrary = getCurrentErrandAreaDataDomain(getState()).library;
			dispatch(loadKnowledgeBase(defaultLibrary, kbParam, false));

			if (features["machine-learning.auto-answer"] ||
				(features["amazon-comprehend"] && features["machine-learning.suggest-answer"])) {
				dispatch(fetchErrandSuggestedAnswer(erd.id))
				.then(data => {
					if (wf.data.autoPickSuggestion && data.suggested_answer && data.suggested_answer.length > 0) {
						let answer = '';
						if (errand.data.answer && errand.data.answer.length > 0) {
							answer = stripHTML(errand.data.answer).trim();
						}
						if (answer.length == 0) {
							let value = data.suggested_answer[0].answer;
							let plain = stripHTML(value);
							dispatch(updateReformatAnswer(value, plain));
							if (data.suggested_answer[0].attachments && data.suggested_answer[0].attachments.length > 0) {
								let currentReply = getCurrentReply(state)
								dispatch(handleLibraryAttachments(data.suggested_answer[0].attachments, currentReply));
							}
						}
						// Since it auto inserted, set it to true
						dispatch(setSuggestedAnswerUsed(true));
					}
				})
			}
			if (features['triggers.data-fetching']){ //the feature is combined with 2 features, feature enable + data fetch url
				dispatch(fetchCRMData(id))
				.then( res =>{
					dispatch(saveCRMData(res.data));
				});
			}
			// Get the status if any agent is working on that errand
			return dispatch(fetchAgentWorkingOnErrand(false, erd.id));
		})
		.then((result) => {
			if (features['link-errand']) {
				dispatch(ErrandMyErrandsAsync(id, 0))
				dispatch(FetchLinkedErrandsAsync(id, 0))
			}
			let dispatchee = [dispatch(otherContacts(id, 0))];
			dispatchee.push(dispatch(historyErrandContacts(id, 0)));
			if (!chat) {
				// chat do not has related/histories errand.
				dispatchee.push(dispatch(fetchRelatedErrandsAndCheckBlueBubble(
					erd
					, isSwitching
				)));
			} else if (!isSwitching) {
				dispatch(loadChatRelatedErrandsAndCheckBlueBubble(chat));
			}
			Promise.all(dispatchee)
				.then(() => dispatch(checkAnyOpenErrandContacts(id)))
				.then(({hasOpen}) => {
					const basic = state.app.errand.basic;
					const current = state.app.errand.currentErrand;
					const wfSettings = state.app.workflow.fetchWfSettings.data
					let agentId = ""
					if (ctx === CTX_MY){
						agentId = wfSettings.activeUserId;
					} else {
						agentId = basic.data.data.agentId;
					}
					dispatch(setHasAcquirableOther(id, hasOpen));
					if (hasOpen && wfSettings.showPopupMessageOtherContactsErrands &&
						!current.smData.closed && wfSettings.activeUserId === agentId) {
						dispatch(showHideAcquireErrand(true));
					}
				})
				.catch(() => {
					// TODO: catch any AJAX error here and send notif.
				});
			if (chatSwitchOC || getCurrentErrand(getState()) !== id) {
				// if current errand already changed then should not proceed
				// signal errand ready as it no longer same as previous request
				// then the request should be dumped.
				return;
			}
			dispatch(SetNotificationReadByErrand(id));
			// Show notification that owner of the errand is currently working on it
			if (result && result.agent_working_on_errand) {
				// Show notification if owner is working on the errand
				dispatch(addNewPopupNotification({
					text: "MsgErrandOwnerWorking"
					, errand: erd.displayId
					, agent: result.agent //erd.agent
				}));
			}
			// NOTE: should always put this last as one might put some init
			// code during opening errand before this line. Those code should
			// update reducers first and should reflect on this action when the
			// action reach reducer.
			dispatch(signalOpenErrandReady(chat));
			console.info("done signalOpenErrandReady:",id);
		});
};

const confirmAcquireAllOtherErrands = () => (dispatch, getState) => {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnAcquireAll
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldAcquireAll;
			}
		}));
	}).then(shouldAcquireAll => {
		if(shouldAcquireAll) {
			dispatch(showHideAcquireErrand(true));
		}
	});
};

function openExternalSystem(id, errand, state){
	const errandAreaData = state.app.errand.errandAreaData.domain;
	const wfs = state.app.workflow.fetchWfSettings;
	let openExternalConfig = {}, fromEmail = "", ges = getExternalSystem(errandAreaData);
	openExternalConfig.hasExSys = ges.hasExSys;
	openExternalConfig.systemUrl = ges.systemUrl;
	openExternalConfig.howOpenExSysByAgent = wfs.data.howToOpenExternalSystemForAgent;
	if(errand !== null && errand.data !== null){
		fromEmail = errand.data.fromAddress;
		if( fromEmail )
			manageAutoOpenExternal( fromEmail, openExternalConfig );
	}
}
const getExternalSystem = (currentArea) =>{
	let hasExt = false, urls = [];
	if( currentArea !== null ){
		if( currentArea.external_system_url_array !== null
			&& currentArea.external_system_url_array.length > 0 ){
			hasExt = true;
			urls = currentArea.external_system_url_array;
		}
	}
	return {
		hasExSys: hasExt,
		systemUrl: urls
	}
}
const manageAutoOpenExternal = (email, extSys) =>{
	let externalSystemUrls = extSys.systemUrl;
	if(externalSystemUrls.length > 0 &&( extSys.howOpenExSysByAgent === 2 || extSys.howOpenExSysByAgent === 3 )){
		for(let i = 0; i < externalSystemUrls.length; i++){
			let url = externalSystemUrls[i].replace('[EMAIL]', email);
			if( url.length > 0 ){
				let external = (extSys.howOpenExSysByAgent == 2
					? window.open(url, '_blank' + i)
					: window.open(url, 'external-system' + i, 'scrollbars=yes,menubar=yes,toolbar=yes,width=1024,height=768'));
				if(external) { // some time browser block the popup which will cause null value on external
					external.focus();
				} else {
					console.log("can not create window - browser blocked popup?");
				}
			}
		}
	}
}
function getCollaborationStatus(collaboration) {
	const { status, answers, queries } = collaboration;
	return {
		status: status,
		num: answers,
		denom: queries
	};
}

const checkForEE = (store, errandID) => {
	if (store && store.app && store.app.workflow && store.app.workflow.errandList &&
		store.app.workflow.errandList.data && store.app.workflow.errandList.data.norm)
	{
		const e = store.app.workflow.errandList.data.norm[errandID];
		if(e && e.data && e.data.collaboration) {
			return getCollaborationStatus(e.data.collaboration);
		}
	}
};

const checkChatCollaboration = ({ errand: { data: { collaboration } } }) => collaboration

const checkForEEViaBasic = store => {
	const basic = getErrandBasicMemoize(store);
	if(basic.data && basic.data.collaboration) {
		return getCollaborationStatus(basic.data.collaboration);
	}
};

export function getAgentAreasAndDefaultEmpty(workflow) {
	if (!workflow.agentAreas.data
		|| !workflow.agentAreas.data.areas
		|| !workflow.agentAreas.data.areas.length) {
		return emptyArray;
	}
	return workflow.agentAreas.data.areas;
}

const handleInvalidOpenReview = (id, cipherKey) => (dispatch, getState) => {
	if (cipherKey) {
		return dispatch(push(reviewErrandURL(id, cipherKey)));
	}
	// TODO: this is insecure hack where it get cipherKey key from basic errand
	// endpoint which is WRONG as it defeat the purpose of cipherKey because
	// basic errand can just be retrieve with id and eventually cipherKey.
	return dispatch(getOneRelatedErrand(id))
		.then(({ data: { cipherKey } }) => dispatch(push(reviewErrandURL(
			id
			, cipherKey
		))));
};

const OPEN_ERRAND_NORM = 1 // click from errand/live-chat list
	, OPEN_ERRAND_VIEW_ONLY = 2 // from URL 'view v5/viewerrand/xxxx'
	, OPEN_ERRAND_COLLABORATION_QUERY = 3 // TODO: ?
	, OPEN_SEARCHED_ERRAND = 4 // search
	, SWITCH_ERRAND_FROM_OTHER_CONTACT = 5 // switch errand from other-contacts
	, OPEN_REVIEW_ERRAND = 6
	;
const validReviewCondition = condition => condition === OPEN_REVIEW_ERRAND
	|| condition === SWITCH_ERRAND_FROM_OTHER_CONTACT;

const openErrandCore = (id, condition, chat, cipherKey, extraParams) =>
	(dispatch, getState) => {
		const state = getState()
			, filterContext = contextMemo(state)
			;
		if (filterContext === CTX_REVIEW && !validReviewCondition(condition)) {
			return dispatch(handleInvalidOpenReview(id, cipherKey));
		}
		const wf = state.app.workflow
			, ernd = state.app.errand
			, areas = getAreasIDViaOrgObj(getAgentAreasAndDefaultEmpty(wf))
			, { initialChatData } = state.server
			, isSwitching = condition === SWITCH_ERRAND_FROM_OTHER_CONTACT
			, chatSwitchOC = isSwitching && !!chat
			;
		let hasEE
			, errand
			;
		if(id == 0){
			return {};
		}
		if(errandViewOnly){
			dispatch(signalViewErrandOnly(true));
		}
		// once open errand core routine start, any previous dirtied input will
		// be cleared and reset.
		dispatch({type: INVALIDATE_LAST_SAVE_START});
		dispatch(showMultipleActions(false));
		let isExternal = false;
		let isExternalChat = false;
		if(typeof externalqueue !== "undefined" &&
			externalqueue.isExternal == true){
			isExternal = true;
			isExternalChat = externalqueue.isChat;
		}
		return Promise.resolve(condition)
			.then(condition => {
				if (condition !== OPEN_SEARCHED_ERRAND) {
					return {condition};
				}
				return dispatch(fetchErrandStatus(id))
					.then(result => {
						if (result.error) {
							return Promise.reject(new Error(result.error));
						}
						return {condition, result};
					});
			})
			.then(({ condition, result }) => {
				if (condition !== OPEN_SEARCHED_ERRAND) {
					// TODO: if manage the state properly this action would
					// not be needed.
					if (condition !== OPEN_ERRAND_VIEW_ONLY
						&& !chatSwitchOC && !isExternalChat
						&& state.domain && state.domain.basicErrands && state.domain.basicErrands
						&& state.domain.basicErrands.byId) {
						errand = state.domain.basicErrands.byId[id]
						//Using errand's basic data now
						//since the one from the wf list is
						//using minimal view
						if(errand && !chat) {
							hasEE = checkForEE(state, id);
						} else if (chat) {
							hasEE = checkChatCollaboration(chat);
						}
					}
				}
				if (!chatSwitchOC) {
					dispatch(notesOperation(NOTEOPR_DEFAULT));
				}
				// When open chat by clicked on notification from integration
				// `chat` is undefined, we should let it lookup for chat info
				// from errandChatList
				if (condition !== OPEN_SEARCHED_ERRAND ) { //|| isExternalChat) {
					return {chat};
				}
				// It is chat errand and not closed then Wait for errandChatList
				// for chat info.
				const { sessionID, status } = result;
				if (sessionID
					&& sessionID != 0
					&& status
					&& status != initialChatData.constants.chatStatusClosed) { // chatSession.STATUS_STOPPED
					return new Promise((resolve, reject) => {
						let totalTries = 0;
						const waitErrandListChat = () => {
							const state = getState()
								, { errandListChat } = state.app.workflow
								;
							let chat;
							if (errandListChat.length > 0) {
								$.each(errandListChat, (i, v) => {
									if (v.errand.id == id) {
										chat = v;
										return false
									}
								});
							}
							if (chat) {
								// Set all chat messages seen
								let ids = [];
								$.each(chat.messages, (i, m) => {
									if (m.seen
										|| m.aid == initialChatData.agentID) {
										return;
									}
									ids.push(m.id);
								});
								if (ids.length > 0) {
									chatSetSeenMessages(dispatch, chat, ids);
								}
								resolve({chat});
							} else {
								// Retry in 500ms
								totalTries++
								if (totalTries < 2) {
									setTimeout(waitErrandListChat, 500);
								} else {
									const err = new Error(
										"tried 2 times wait chat session "
										+ sessionID
									);
									reject({error: err, errorId: ERR_CHAT_ERRAND_OWNED_BY_OTHER});
								}
							}
						};
						waitErrandListChat();
					});
				} else {
					return {chat};
				}
			})
			.then(({ chat }) => {
				if (chatSwitchOC) {
					dispatch(chatSwitchOtherContactErrand(id));
				} else {
					dispatch(setCurrentErrand(id, hasEE, chat, isSwitching));
				}
				if (chat) {
					return {chat, errand: chat.errand};
				} else if (errand) {
					return {basic: true, errand};
				}
				return dispatch(basicErrand(id))
					.then(() => {
						const state = getState()
							, errand = getNormalizedDomain(
								state.domain
								, D_BASIC_ERRANDS
								, id
							)
							;
						if (errand) {
							if (condition !== OPEN_ERRAND_VIEW_ONLY) {
								// Notes:
								// Need to have a second look of this one
								// because when errand open was cached , and basic not fetched anymore
								// it will not trigger this one
								// so when open collaboration requests from launchpad after the errand previously opened in the same tab,
								// open second time will not trigger this
								hasEE = checkForEEViaBasic(state);
								if (hasEE) {
									dispatch(updateCurrentErrandHasEE(hasEE));
								}
							}
							return {errand};
						}

						const err = new Error("error open errand " + id);
						return Promise.reject(err);
					});
			})
			.then(({
				basic
				, chat
				, errand
			}) => dispatch(multiDispatchesOpenErrand(
				id
				, errand
				, filterContext
				, areas
				, hasEE
				, wf.fetchWfSettings
				, !!basic
				, chat
				, isSwitching
				, extraParams
			)))
			.catch(err => {
				if(typeof err.errorId !== 'undefined' && err.errorId === ERR_CHAT_ERRAND_OWNED_BY_OTHER) {
					let msg = I("This chat is closed by customer and owned by other agent. You can't open it.");
					dispatch(togglePopAlert(msg));
					return dispatch(push(SEARCH));
				}
				return Promise.reject(err);
			});
	};

export const openSingleErrand = id => openErrandCore(id, OPEN_ERRAND_VIEW_ONLY);

const warnAcquireAll = I("There are more errands from this customer that you can acquire. Do you want to acquire all of the errands?")
	, warnBttnYes = 1
	, warnBttnNo = 2
	, yesBttnValue = {
		type: warnBttnYes
		, color: 'grey'
		, text: I('Yes')
	}
	, noBttnValue = {
		type: warnBttnNo
		, color: 'blue'
		, text: I('No')
	}
	, warnYesOrNoButtons = [
		yesBttnValue
		, noBttnValue
	]
	, shouldAcquireAll = {shouldAcquireAll: true}
	;

const warnErrandNotSave = I("Are you sure you want to discard your changes?")
	, warnErrandLeaveCallSession = I("Are you sure you want to leave this session?")
	, warnBttnSave = 1
	, warnBttnLeave = 2
	, warnBttnCancel = 3
	, warnBttnClose = 4
	, saveBttnValue = {
		type: warnBttnSave
		, color: 'blue'
		, text: I('Save and leave')
	}
	, leaveBttnValue = {
		type: warnBttnLeave
		, color: 'grey'
		, text: I('Leave without save')
	}
	, saveCloseBttnValue = {
		type: warnBttnClose
		, color: 'blue'
		, text: I('Save and close errand')
	}
	, cancelBttnValue = {
		type: warnBttnCancel
		, color: 'grey'
		, cancel: true
		, text: I('Cancel')
	}
	, warnErrandNotSaveButtons = [
		saveBttnValue
		, leaveBttnValue
		, cancelBttnValue
	]
	, warnSaveOrCancelButtons = [
		saveBttnValue
		, cancelBttnValue
	]
	, warnSaveAndCloseButtons = [
		saveCloseBttnValue
		, saveBttnValue
		, cancelBttnValue
	]
	, shouldSaveErrand = {shouldSaveErrand: true}
	;
function checkAutoSave(state) {
	if (!needFullSaveMemoize(state)) {
		return;
	}
	return shouldSaveErrand;
}

const rawHardSaveErrand = (force, shouldLoadList, saveAndClose) => dispatch =>
	dispatch(saveErrand(force, saveAndClose))
		.then(() => {
			dispatch(markLastSaveTriggered(false));
			if (shouldLoadList) {
				dispatch(loadList("rawHardSaveErrand"));
			}
		});

const hardSaveErrand = (force, shouldLoadList, saveAndClose) => (dispatch, getState) => {
	dispatch(togglePopWaiting(I("Please wait while saving errand...")));
	return dispatch(rawHardSaveErrand(force, shouldLoadList, saveAndClose))
		.then(() => {
			let hasChanged = isCollabInputsChangedMemoize(getState());
			let unsavedAttachments = getEEUploadedAttachments(getState());
			if (hasChanged || unsavedAttachments) {
				dispatch(rawHardSaveCollab(force, shouldLoadList))
				.then(() => {
					dispatch(clearPopWaiting());
				}) ;
			} else {
				dispatch(clearPopWaiting());
			}
		})
		.catch(err => {
			dispatch(clearPopWaiting());
			return Promise.reject(err);
		});
};

const beforeCloseSaveErrand = () => hardSaveErrand(true, true);

export function forceSaveErrand(){
	return (dispatch, getState) => dispatch(hardSaveErrand(true, false,
		false));
}

export function buttonClickSaveErrand() {
	let force = false;
	let saveAndClose = false;
	if(externalqueue.isExternal){
		force = true
		saveAndClose = true;
	}
	return (dispatch, getState) => dispatch(hardSaveErrand(force, false,
		saveAndClose))
		.then(res => {
			const state = getState();
			if (isValidIntegration(state)) {
				const  eid = state.app.errand.currentErrand.id;
				dispatch(removeAgentWorkingOnOneErrand(eid))
			}
			return res;
		});
}

export const backToListView = () => (dispatch, getState) => {
	const state = getState()
		, previousID = getCurrentErrand(state)
		;
	dispatch(closeErrandViewAction(previousID))
	.then(() => {
		dispatch(postExtQueueDoneErrand({errand_id: previousID,
			working_on_it: false}))
		if(isMobile) {
			dispatch(setErrandMobileView(false));
		}
	});
}

const warnForUnsaveChange = () => (dispatch, getState) =>
	new Promise((resolve, reject) => {
		const state = getState()
			, e = state.app.errand
			, previousIsChat = e.chat
			, previousID = getCurrentErrand(state)
			, collabUnsavedAttachments = getEEUploadedAttachments(state)
			;
		if (previousIsChat
			|| previousID === 0
			|| isErrandAnswered(state)
			|| ( !isInputsChangedMemoize(state) && !isCollabInputsChangedMemoize(state) && !collabUnsavedAttachments) ) {
			resolve(checkAutoSave(state));
		} else {
			let warnBttns;
			if (needFullSaveMemoize(state)) {
				warnBttns = warnSaveOrCancelButtons;
			} else {
				warnBttns = warnErrandNotSaveButtons;
			}
			resolve(dispatch(customConfirm(
				warnErrandNotSave
				, warnBttns
			))
			.then(({ button }) => {
				if (button === warnBttnSave) {
					return shouldSaveErrand;
				} else if (button === warnBttnLeave) {
					return checkAutoSave(state);
				}
			}));
		}
	})
	.then(shouldSaveErrand => {
		if (!shouldSaveErrand || !shouldSaveErrand.shouldSaveErrand) {
			return;
		}
		return dispatch(beforeCloseSaveErrand());
	})
	;

const warnForUnsaveChangeOnManualCall = () => (dispatch, getState) =>
	new Promise((resolve, reject) => {
		let warnBttns = "";
		warnBttns = warnSaveAndCloseButtons;
		resolve(dispatch(customConfirm(
			warnErrandLeaveCallSession
			, warnBttns
		))
		.then(({ button }) => {
			if(button == warnBttnClose) {
				dispatch(manualCallStatus(false));
				return true;
			} else if (button == warnBttnSave) {
				dispatch(manualCallStatus(false));
				return false;
			} else if (button == warnBttnCancel) {
				return;
			}
		}).catch(err => {
			return Promise.reject(new Error(err));
		}));
	})
	.then(shouldSaveAndClose => {
		if(shouldSaveAndClose) {
			dispatch(shouldCloseManualCall());
			return false;
		} else {
			dispatch(shouldSaveManualCall(false));
			return true;
		}
	})
	;

export const shouldCloseManualCall = () => (dispatch, getState) => {
	const callState = getState().app.call;
	let id = callState.ui.outboundErrandId;
	let eid = parseInt(id, 10);
	dispatch(doCloseErrand(eid, false, false))
	.then(data => {
		dispatch(resetOutboundErrandId());
		dispatch(toggleManualOrCallPopup(MP_NONE, true));
		dispatch(toggleSipOutgoingPopup(false, ""));
		dispatch(clearManualCallInputs());
		if (callState.ui.showSipOutgoingPopup.show) {
			dispatch(toggleSipOutgoingPopup(false, ""));
			dispatch(showSipAgentList(false));
		}
	});
}

export const shouldSaveManualCall = (withClose) => (dispatch, getState) => {
	let options = {
		isCall: true
		, manual: true
		, isSip: true
		, isClose: withClose
	};
	let params = createUpdateErrandObject(null, options,
		getState());
	dispatch(updateManualErrand(params))
	.then((data) => {
		const callState = getState().app.call;
		let id = callState.ui.outboundErrandId;
		let eid = parseInt(id, 10);
		if(withClose) {
			return dispatch(doCloseErrand(eid, false, false))
			.then(data => {
				dispatch(resetOutboundErrandId());
				dispatch(toggleManualOrCallPopup(MP_NONE, true));
				dispatch(toggleSipOutgoingPopup(false, ""));
				dispatch(clearManualCallInputs());
				if (callState.ui.showSipOutgoingPopup.show) {
					dispatch(toggleSipOutgoingPopup(false, ""));
					dispatch(showSipAgentList(false));
				}
			});
		} else {
			dispatch(clearManualCallInputs());
			dispatch(sipMakeCallFromErrand(false, 0));
			return dispatch(resetOutboundErrandId());
		}
	});
}

export const closeManualCallView = () => async (dispatch, getState) => {
	try {
		const closeWindow = await new Promise((resolve, reject) => {
			resolve(dispatch(warnForUnsaveChangeOnManualCall()));
		});
		if (closeWindow) {
			dispatch(toggleManualOrCallPopup(MP_NONE, true));
			dispatch(toggleSipOutgoingPopup(false, ""));
		}
	} catch (err) {
		console.log("Error :", err);
		return;
	}
};

export const checkSameIDAndUnsaveChange = id => dispatch =>
	dispatch(sameAsOpenedErrandID(id))
	.then(() => dispatch(warnForUnsaveChange()))
	;

export const endOfCall = I("Call has ended")
export const callTransferred = I("Call transferred");
const confirmDoPaging = I("Voice errand {errand} requires paging to {oNumber}. Perform paging?")
	, askBttnYes = 1
	, askBttnNo = 2
	, askYesBttnValue = {
		type: askBttnYes
		, color: 'blue'
		, text: I('Yes')
	}
	, askNoBttnValue = {
		type: askBttnNo
		, color: 'grey'
		, text: I('No')
	}
	, askYesOrNoButtons = [
		askYesBttnValue
		, askNoBttnValue
	]
    , shouldDoPaging = {shouldDoPaging: true}
    ;

export const askForPaging = (msgid, errand, odNumber, eid) => (dispatch, getState) =>{
	new Promise((resolve, reject) => {
		let notification = getState().app.notification;
		let warnBttns = askYesOrNoButtons;
		let msg = confirmDoPaging.replace('{oNumber}', odNumber)
			.replace('{errand}', errand);
		resolve(dispatch(customConfirm(
			msg
			, warnBttns,
		))
		.then(({ button }) => {
			readNotification(msgid);
			if (button === warnBttnYes) {
				return shouldDoPaging;
			}
		}));
    })
	.then(shouldDoPaging => {
		if(shouldDoPaging) {
			dispatch(updateAventaStatus(SIP_CALL_CONNECTING));
			dispatch(postErrandDoPaging({errand: eid,
				outbound: odNumber, type: "page"}))
			.then(data => {
				if(typeof data.result !== 'undefined' && data.result == "fail"){
					dispatch(updateAventaStatus(SIP_CALL_IDLE));
					if(data.endTime != null && data.endTime > 0){
						dispatch(setErrandCloseTimer(eid, data.endTime));
					}
					alert(data.message);
					return
				}
				dispatch(updateAventaStatus(SIP_CALL_CONNECTED));
			});
		} else {
			notifyOS("Cention", endOfCall, 0);
			dispatch(updateAventaStatus(SIP_CALL_IDLE));
			dispatch(postErrandStartCountdown({errand: eid}))
			.then(data =>{
				if(typeof data.result != null && data.result == "success"){
					if(data.endTime > 0){
						dispatch(setErrandCloseTimer(eid, data.endTime));
					}
				}
			});
			setTimeout(function(){
				dispatch(agentStatusOnLoad());
			}, 3000);
		}
	});
}

export const askedForVideoCall = (data, screenShare) => (dispatch, getState) => {
	new Promise((resolve, reject) => {
		let warnBttns = askYesOrNoButtons, alertCallMsg = I("{CLIENT_NAME} is requesting a video call with you, do you want to accept?").replace("{CLIENT_NAME}", data.clientName);
		let rejectEvt = evtAGENT_REJECT_VIDEO_CALL;
		if(screenShare){
			rejectEvt = evtAGENT_REJECT_SCREEN_SHARE;
		}
		if(data) {
			if(screenShare) {
				if(data.coBrowse) {
					alertCallMsg = I("{CLIENT_NAME} is accepting your co-browsing request, do you want to proceed?").replace("{CLIENT_NAME}", data.clientName);
				} else {
					alertCallMsg = I("{CLIENT_NAME} is offering a screen sharing with you, do you want to accept?").replace("{CLIENT_NAME}", data.clientName);
				}
			} else {
				dispatch(handleClientVideoCalling(true));
			}
			resolve(
				dispatch(customConfirm(
				alertCallMsg
				, warnBttns,
			))
			.then(({ button }) => {
				if(button == 1) {
					const state = getState()
					, currentErrand = state.app.errand.currentErrand
					, openedErrand = state.app.errand.currentErrand.id;
					if(screenShare) {
						//offer stop
						//dispatch(handleClientScreenShareOffer(false));
						//client screen sharing mode onz
						dispatch(handleClientScreenShare(true));
					}
					if (isMainMenuWorkflowErrandSelector(state)) {
						if (currentErrand
							&& openedErrand == 0) {
							//currently not open any errand so..
							dispatch(loadAndOpenErrand(data.errandId))
							.then(() => {
								if(screenShare) {
									handleReceiveClientDisplay(data, data.sessionId);
									dispatch(showVideoCallFrame(data.sessionId, true, false));
								} else {
									createAnswerForClient(data, data.sessionId);
									dispatch(showVideoCallFrame(data.sessionId, true));
								}
							})
							.catch(err => {
								return;
							});
						} else {
							if(openedErrand !== data.errandId){
								//currently on chat session but not same one
								//as the requested call come in
								dispatch(loadAndOpenErrand(data.errandId))
								.then(() => {
									if(screenShare) {
										handleReceiveClientDisplay(data, data.sessionId);
										dispatch(showVideoCallFrame(data.sessionId, true, false));
									} else {
										createAnswerForClient(data, data.sessionId);
										dispatch(showVideoCallFrame(data.sessionId, true));
									}
								})
								.catch(err => {
									return;
								});
							} else {
								//currently open the same chat
								if(screenShare) {
									handleReceiveClientDisplay(data, data.sessionId);
									dispatch(showVideoCallFrame(data.sessionId, true, false));
								} else {
									createAnswerForClient(data, data.sessionId);
									dispatch(showVideoCallFrame(data.sessionId, true));
								}
							}
						}
					} else {
						//not working on any errand but accepting the call
						dispatch(redirectToErrands(MY_ERRANDS))
						.then(() =>{
							dispatch(loadAndOpenErrand(data.errandId))
							.then(() => {
								if(screenShare) {
									handleReceiveClientDisplay(data, data.sessionId);
									dispatch(showVideoCallFrame(data.sessionId, true, false));
								} else {
									createAnswerForClient(data, data.sessionId);
									dispatch(showVideoCallFrame(data.sessionId, true));
								}
							})
							.catch(err => {
								return;
							});
						})
					}
					dispatch(handleVideoCallRequest(false));
				} else if(button == 2) {
					if(!screenShare) {
						let screenSharingInProgress = getState().chat.socket.screenSharing;
						if(!screenSharingInProgress) {
							//hide main video frame only when no screen sharing is in progress
							dispatch(showVideoCallFrame(data.sessionId, false));
						}
					} else {
						//offer stop
						//fixme: check if screen sharing already on going before do this.
						dispatch(handleClientScreenShareOffer(false));
					}
					AgentSocket.SendEvent(rejectEvt, {
						sessionId: data.sessionId
					}, r => {
						console.log("Dbg: done agent rejecting call/screen sharing");
					});
					dispatch(handleVideoCallRequest(false));
				} else {
					return;
				}
			})
			.catch(err => {
				//Most cases: Missed call happens
				if(err.cancelled) {
					if(getState().chat.socket.onVideoCallRequest) {
						dispatch(cancelConfirm());
					}
				} else {
					if(typeof err.button === "undefined") {
						console.log("Video Call cancelled here and handled somewhere");
						dispatch(handleVideoCallRequest(false));
					}else {
						AgentSocket.SendEvent(rejectEvt, {
							sessionId: data.sessionId
						});
					}
				}
			}));
		}
	})
	.then((response) => {
		dispatch(handleClientVideoCalling(false));
	});
}

// NOTE: this should be used instead of setCurrentErrand(0) as this include
// logic to detect changes. This action return a promise that MUST be listened
// because rejection from this action meaning user do NOT want to proceed any
// caller action.
export const closeErrandView = (
	force
	, doNotSyncURL
) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
			if (!force) {
				resolve(dispatch(warnForUnsaveChange()));
			} else {
				resolve();
			}
		})
		.then(() => dispatch(setCurrentErrand(0)))
		.then(() => {
			const syncURL = !doNotSyncURL;
			if (syncURL) {
				if (contextMemo(getState()) === CTX_REVIEW) {
					dispatch(push(REVIEW));
				}
			}
		});
};

export const closeErrandViewAction = (
	id
	, force
	, doNotSyncURL
) => (dispatch, getState) => {
	return dispatch(closeErrandView(force, doNotSyncURL))
		.then(() => dispatch(changeErrandListSelection(id, false)));
};

export const closeResetErrandView = (id, force, doNotSyncURL) => dispatch => {
	let p = dispatch(closeErrandViewAction(id, force, doNotSyncURL));
	if (force) {
		p = p.catch(() => {});
	}
	return p.then(() => dispatch(resetErrandView(false)));
};

// NOTE: do NOT direct use this.
const smartCloseErrandView = (
	trace
	, force
	, doNotSyncURL
) => (dispatch, getState) => {
	const state = getState()
		, e = state.app.errand
		, isChat = e.chat
		, { id } = e.currentErrand
		;
	if (isChat || id <= 0) {
		return Promise.resolve();
	}
	if (trace) {
		if (process.env.NODE_ENV !== 'production') {
			console.trace &&
				console.trace("trace: closed errand view not proper way!");
		}
	}
	return dispatch(closeResetErrandView(id, force, doNotSyncURL));
};

export const closeErrandWithoutSyncURL = force => smartCloseErrandView(
	false
	, force
	, true
);

// NOTE: do not use this. This is to detect which close did not close errand
// view properly. Use closeErrandView instead.
export const forceCloseErrandView = () => (dispatch, getState) => {
	if (currentStateMemoize(getState()).state === ST_ACQUIRING) {
		return Promise.resolve();
	}
	return dispatch(smartCloseErrandView(true, true));
};

const errandMobileView = extra => dispatch => {
	if (!extra) {
		return;
	}
	const { mobile } = extra;
	if (mobile) {
		dispatch(setErrandMobileView(true));
	}
};

const checkOpenErrandError = err => dispatch => {
	if (typeof err.err !== 'undefined') {
		const { message } = err.err;
		if (message) {
			dispatch(togglePopAlert(message));
			dispatch(closeErrandView(true));
		}
	}
	// NOTE: cancelled or open errand got error.
	console.log && console.log("error open errand ", err);
};

const openNormalErrand = (id, chat) =>
	openErrandCore(id, OPEN_ERRAND_NORM, chat);

const openSearchErrand = (id, chat) =>
	openErrandCore(id, OPEN_SEARCHED_ERRAND, chat);

const switchOtherContactErrand = (id, chat) =>
	openErrandCore(id, SWITCH_ERRAND_FROM_OTHER_CONTACT, chat);

// this function should only be used by 'normal' open errand. Any additional
// actions that wanna put under this function please check if the
// openErrand-Core a more suitable place before placing inside this code. Note
// that this func mainly deal with auto-save code where it need to detect any
// unsave changes.
const openErrand = (
	id
	, chat
	, isSwitching
	, extra
	, search
	, condition
	, cipherKey
) => (dispatch, getState) => dispatch(sameAsOpenedErrandID(id))
	.then(() => {
		if (getState().app.errand.chat) {
			return;
		}
		return dispatch(warnForUnsaveChange());
	})
	.then(() => {
		if (!isSwitching) {
			if (search) {
				return dispatch(openSearchErrand(id, chat));
			} else if (typeof condition !== "undefined") {
				// TODO: this code not executed but it should be the template
				// that is cipherKey must be there.
				return dispatch(openErrandCore(
					id
					, condition
					, chat
					, cipherKey
				));
			} else {
				return dispatch(openNormalErrand(id, chat));
			}
		}
		return dispatch(switchOtherContactErrand(id, chat));
	})
	.then(res => {
		if (isSwitching) {
			return res;
		}
		dispatch(errandMobileView(extra));
		return res;
	})
	.catch(err => dispatch(checkOpenErrandError(err)))
	;

export const openErrandFromList = (
	id
	, chat
	, extra
	, search
) => openErrand(id, chat, false, extra, search);

// should only be called by 'automatic' code such as useEffect and not from
// human action click because it ignores the cancellable action when there is
// any unsave changes. In another word, the code checking the unsave is placed
// on the place where it will trigger the effect that trigger this function.
// During push URL, there is code to check the unsave changes.
export const openReviewErrand = (id, cipherKey) => (
	dispatch
	, getState
) => dispatch(validateErrandID(id, cipherKey))
	.then(errandId => {
		const { review: { isSwitching} } = locationState(getState());
		if (isSwitching) {
			return dispatch(startSwitchErrand(errandId, false))
				.then(() => dispatch(clearPopWaiting()))
				.catch(err => dispatch(popError(err)))
				.catch(() => dispatch(clearPopWaiting()))
				;
		}
		return dispatch(openErrandCore(
				errandId
				, OPEN_REVIEW_ERRAND
				, false
				, cipherKey
			))
			.then(() => dispatch(errandMobileView(
				openErrandExtraFieldsMemoize(getState())
			)))
			.catch(err => dispatch(popErrorOnly(err)))
			;
	})
	;

const startSwitchErrand = (id, currentErrandChat) => dispatch => {
	dispatch(togglePopWaiting(I("Loading errand and contact data ...")));
	if (!currentErrandChat) {
		return dispatch(openErrand(id, false, true, false, false));
	}
	return dispatch(switchOtherContactErrand(id, currentErrandChat));
};

const openOtherContactErrandBase = (
	id
	, currentErrandChat
	, currentErrandID
	, currentOtherContactID
) => dispatch => dispatch(compareSameID(
	id
	, () => currentOtherContactID
)).then(() => dispatch(startSwitchErrand(id, currentErrandChat)));

export const openOtherContactErrand = (...args) => dispatch =>
	dispatch(openOtherContactErrandBase(...args))
		.then(() => {
			dispatch(clearPopWaiting());
		})
		.catch(err => {
			if (err && err.sameID) {
				return;
			}
			dispatch(clearPopWaiting());
		});

export const openErrandOnLoad = () => (dispatch, getState) => {
	const id = getState().app.errand.currentErrand.onLoadOpen;
	if(id) {
		return dispatch(openErrandCore(id, OPEN_ERRAND_COLLABORATION_QUERY));
	}
};

export function attachmentObjectToArrayIDs(inputs, attachmentType) {
	if(inputs[attachmentType].length > 0) {
		return inputs[attachmentType].map(value => {
			return value.id;
		});
	}
	return [];
}

export function attachmentExtraInfo(inputs, attachmentType) {
	let jstring = "";
	let tmpMap = Object.create(null);
	$.each(inputs[attachmentType], (i,v) => {
		let tmpInfo = {};
		tmpInfo.duration = v.duration;
		tmpInfo.isVoiceRecording = v.isVoiceRecording;
		tmpMap[v.id]= tmpInfo;
	})
	if (tmpMap != null) {
		jstring = JSON.stringify(tmpMap);
		return jstring;
	}
	return "";
}

const warnNoteNotSave = I("Are you sure you want to discard the unsaved note?");

// TODO: errand arg seem useless.
export const editErrandNotes = (note, errand, isChat) => (dispatch, getState) => {
	const inputs = getState().app.errand.inputs,
		cn = inputs.current_edit_note.note;

	let internal_comment = inputs.internal_comment;
	let trim = inputs.plain_internal_comment.replace(/^\s+|\s+$/, '');
	if((trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) &&
		/^(<div>[\s\xA0]*<\/div>\s*)+$/.test(internal_comment)) {
		internal_comment= "";
	}

	if(note > 0 && cn === 0 && internal_comment) {
		// no need check attachment because new internal comment can not has
		// saved attachment and only has uploaded attachments which won't be
		// removed after switch anyway.
		return dispatch(enableConfirm('edit_note', warnNoteNotSave,
			{note, errand, isChat}));
	} else if(cn > 0) {
		if(cn !== note) {
			let notes = [];
			if(isChat == true){
				notes = getState().app.errand.chat.errand.notes;
			} else {
				notes = getState().domain[D_ERRAND_NOTES].byId[errand].notes;
			}
			let found, different;
			$.each(notes, (i,v) => {
				if(v.id === cn) {
					found = true;
					if(v.note != inputs.internal_comment) {
						different = true;
					} else if(v.attachments) {
						if(v.attachments.length !== inputs.internal_comment_saved_attachments.length) {
							different = true;
						} else if(v.attachments.length) {
							$.each(v.attachments, (i,v) => {
								let fnd;
								$.each(inputs.internal_comment_saved_attachments,
									(j,w) => {
									if(v.id === w.id) {
										fnd = true;
										return false;
									}
								});
								if(!fnd) {
									different = true;
									return false;
								}
							});
						}
					}
					return false;
				}
			});
			if(found && different) {
				return dispatch(enableConfirm('edit_note', warnNoteNotSave,
					{note, errand, isChat}));
			}
		} else {
			// just re-focus the comment edit reply box
			dispatch(selectShowReply(RPLY_COMMENT, true));
			return;
		}
	}
	dispatch(confirmEditNote(note, errand, isChat));
};

export const prepareCreateNewInternalComment = () => (dispatch, getState) => dispatch(editErrandNotes(0, getState().app.errand.currentErrand.id));

export const confirmationYes = () => (dispatch, getState) => {
	const a = getState().app.header.uiData.alert;
	dispatch(confirmYes());
	switch(a.opr) {
		case 'delete_note':
			const {note, errand, isChat} = a.data;
			return dispatch(removeOneErrandNotes(note, {errand,
				type: 'errand'}))
				.then(() => {
					dispatch(notesOperation(NOTEOPR_DEFAULT));
					dispatch(checkInternalCommentState(errand));
					if(isChat){
						dispatch({type: CHAT_DELETE_INTERNAL_COMMENT,
							payload: {note, errand}});
					}
				});
			break;
		case 'edit_note':
			// no async action here
			dispatch(confirmEditNote(a.data.note, a.data.errand,
					a.data.isChat));
			break;
		case 'send_answer':
			dispatch(sendReply(update(a.data.option,
				{$merge: {skipSubject: true}})));
			break;
		case ALRT_CFRM_OPR_BLOCK_IP:
			let chat = a.data.chat;
			AgentSocket.SendEvent(evtCHAT_BLOCK_IP, {
				sessionId: chat.sessionId,
				ip: chat.ClientIP,
				email: chat.errand.data.fromAddress,
			}, ack => {
				if (ack.error) {
					let msg = I("An error occurred while blocking the IP address ({ERROR})")
						.replace('{ERROR}', ack.error)
						;
					dispatch(togglePopAlert(msg));
					return;
				}

				if (ack.blocked) {
					dispatch({type:CHAT_IP_BLOCKED, chat: chat});
				}
			});
			break;
	}
	return $.when();
};

export const createErrandNote = (text) => (dispatch, getState) => {
	const e = getState().app.errand, errand = e.currentErrand.id;
	if (errand <= 0) { // take no action if current errand not exists
		return;
	}
	var isChat, ce, sid;
	if(e.chat){
		isChat = true;
		ce = e.chat.errand;
		sid = e.chat.sessionId;
	}
	let param = {
		errand,
		type: 'errand',
		text,
		attachments: ''
	};
	// create new note
	return dispatch(createOneErrandNotes(param))
		.then((data) => {
			dispatch(checkInternalCommentState(errand));
			if(isChat == true){
				const newNote = data.note;
				dispatch({type: CHAT_ADD_INTERNAL_COMMENT,
				payload: {ce, sid, newNote, noteId: 0}});
			} else {
				const en = getState().domain[D_ERRAND_NOTES].byId[errand];
				if(!en || !en.notes || !en.notes.length) {
					console.log('dbg: no notes ');
					return;
				}
				dispatch(errandNotesCount(en.notes.length))
			}
		})
		.catch(err => {
			dispatch(togglePopAlert(I('An error occurred while creating internal comment from chat message.')));
		});
};

export const createOrEditNote = (isChat) => (dispatch, getState) => {
	const e = getState().app.errand, n = e.inputs.current_edit_note,
		errand = e.currentErrand.id;

	var ce, sid;
	if(e.chat){
		ce = e.chat.errand;
		sid = e.chat.sessionId;
	}
	const noteId = n.note;

	let text = e.inputs.internal_comment, plain = e.inputs.plain_internal_comment;
	let trim = plain.replace(/^\s+|\s+$/, '');
	if((trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) &&
		/^(<div>[\s\xA0]*<\/div>\s*)+$/.test(text)) {
		text= "";
	}

	let param = {
		errand,
		type: 'errand',
		text,
		attachments: attachmentObjectToArrayIDs(e.inputs,
			'internal_comment_uploaded_attachments').join(',')
	};

	if(n.note === 0 && text) {
		// create new note
		return dispatch(createOneErrandNotes(param))
			.then((data) => {
				dispatch(notesOperation(NOTEOPR_DEFAULT));
				dispatch(checkInternalCommentState(errand));
				if(isChat == true){
					const newNote = data.note;
					dispatch({type: CHAT_ADD_INTERNAL_COMMENT,
					payload: {ce, sid, newNote, noteId}});
				} else {
					const en = getState().domain[D_ERRAND_NOTES].byId[errand];
					if(!en || !en.notes || !en.notes.length) {
						console.log('dbg: no notes ');
						return;
					}
					dispatch(errandNotesCount(en.notes.length))
				}
				dispatch(reloadBasicErrand(errand));
			});
	} else if(n.note > 0 && text) {
		// edit existing note
		param.saved = attachmentObjectToArrayIDs(e.inputs,
			'internal_comment_saved_attachments').join(',');
		return dispatch(updateOneErrandNotes(n.note, param))
			.then((data) => {
				dispatch(notesOperation(NOTEOPR_DEFAULT));
				dispatch(checkInternalCommentState(errand));
				const newNote = data.note;
				if(isChat == true){
					dispatch({type: CHAT_UPDATE_INTERNAL_COMMENT,
					payload: {ce, sid, newNote}});
				}
			});
	} else {
		console.log('dbg: unknown note:', n.note);
	}
	return $.when();
};

const isOwnEmail = (emailToBeChecked, area) => {
	if(!emailToBeChecked || !area) {
		return false;
	}
	emailToBeChecked = emailToBeChecked.trim().toLowerCase();
	if(area.reply_to_address) {
		if(area.reply_to_address == emailToBeChecked) {
			return true;
		}
	}
	if(!area.serverEmails || !area.serverEmails.length) {
		return false;
	}
	var positiveResult = false;
	$.each(area.serverEmails, function(i,v) {
		if(v == emailToBeChecked) {
			positiveResult = true;
			return false;
		}
	});
	return positiveResult;
};

const clearAnswerState = () => answerState(E_A_UNKNOWN, 0);

// call enable confirm and also clear the answer state if confirmation is
// rejected.
const enableSendConfirm = (opr, text, data) => (dispatch, getState) => {
	return dispatch(enableConfirm(opr, text, data))
		.catch(() => {
			console.log('dbg: catch rejection from confirmation');
			dispatch(clearAnswerState());
		});
};

// create the common update errand object. WARNING: NEVER mutate 'option', if
// you change 'option' please use 'update'.
export function createUpdateErrandObject(obj, option, state) {
	const e = getAppErrand(state)
		, wf = getWorkflowRoot(state)
		, { all, isCall, reply, manual, newId, isSip, isClose, isBulkSend } = option
		, forward = reply === RPLY_EXT_FWD
		;
	let o
		, inpt
		, inputs
		, associatedList = []
		, startManualErrand
		, isCreatingManualErrand
		, isSecureUserID
		, basic
		, extend
		, associatedCipherKeyList = []
		, isManualVoice = false
		;
	if (obj) {
		o = obj;
	} else {
		o = {};
	}
	if (!manual && !isBulkSend) {
		basic = e.basic.data.data;
		extend = e.fetchExtendedData.data.data;
		inpt = INPUTS_OPEN_ERRAND;
		o.current_context_name = contextMemo(state);
		$.each(getMemoizeAssociatedList(state), (k,v) => {
			associatedList.push(k);
			associatedCipherKeyList.push(v.data.cipherKey);
		});
		o.update_id = e.currentErrand.id;
		// Added security for backend to verify current errand ID to update
		o.update_cipher_key = basic.cipherKey;
		// TODO: how about forwarding email? do we allow secure ID to do
		// forwarding?
		if(basic.secureUserId) {
			isSecureUserID = true;
		}
		// although no backend code for this, but it is required to pass the recipient check
		// when isSecureUserID is true
		o.update_from = basic.fromAddress;
	} else {
		inpt = INPUTS_MANUAL_ERRAND;
		if(isCall || isSip) {
			inpt = INPUTS_MANUAL_CALL;
		}
		o.current_context_name = CTX_MANUAL;
		if (isBulkSend) {
			o.current_context_name = CTX_BULK_SEND;
		}
		if (option.manualCreate) {
			isCreatingManualErrand = true;
			if (option.manualCreateType === ME_CREATE_AS_NEW) {
				o.context_new_manual = NEW_MANUAL_CTX;
			}
		} else if(option.manualStart) {
			startManualErrand = true;
		}
		if (isCall) {
			if (typeof newId !== 'undefined' && newId != "") {
				o.update_id = newId;
			} else {
				o.update_id = outboundErrandId(state);
			}
		} else {
			if((typeof isSip === 'undefined' || isSip === false) &&
				e[inpt].reply_channel == RC_VOICE){
				isManualVoice = true;
				o.update_channel = Workflow.Errand.SERVICE_VOICE;
			}
			o.update_id = 0;
		}
		if(typeof isSip !== 'undefined' && isSip == true){
			o.update_id = e.ui.manualCall.newErrandId;
			o.is_sip = true;
			o.update_cipher_key = e.ui.manualCall.cipherKey;
		} else {
			o.is_sip = false;
			o.update_cipher_key = "";
		}
	}
	inputs = e[inpt];
	// below are all common update code for both normal errand operations and
	// manual errand operations.
	o.associated_list = associatedList.join(',');
	o.associated_cipher_keys = associatedCipherKeyList.join(",");
	if (!isBulkSend) {
		o.update_area_id = inputs.area;
	}
	if (!forward && isManualVoice == false) { // external-forward do not support change channel/service
		o.update_channel = inputs.update_channel;
	}

	o.update_auto_answer = e.ui.suggestedAnswerUsed;

	let tags = [];
	$.each(inputs.tags, (i,v) => {
		$.each(v, (j,w) => {
			tags.push(w);
		});
	});
	o.update_tags = tags.join(',');
	o.selected_group_tags = inputs.tags; //TODO: seem useless remove this

	// answer content filtering
	const answer = inputs.update_answer,
		trim = inputs.plain_answer.replace(/^\s+|\s+$/, '');
	if((trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) &&
		/^(<div>[\s\xA0]*<\/div>\s*)+$/.test(answer)) {
		o.update_answer = "";
	} else {
		o.update_answer = answer;
	}
	if (!isBulkSend) {
		o.update_subject = inputs.update_subject.trim();
	}
	o.update_attachment_total = 0; //TODO
	o.update_salutation = inputs.update_salutation;
	o.update_signature = inputs.update_signature;

	// NOTE: this part different from prior version as most checkboxes
	// handle under one single branch `checkboxes` instead of individual
	// state. Same for manual.
	o.update_library = inputs.checkboxes[ECB_SUGGEST_TO_LIBRARY];

	// update_to is different from update_from where update_to is to
	// fill up answer.Response.To field and not errand.Mail. update_from
	// on the other hand is filling errand.Mail.From. So, when come to
	// email manual errand update_from and update_to should have the
	// same value. This is true if update_to only contains one email address
	// however if update_to contains multiple email address update_from
	// should only contain the first email address found in update_to
	let update_to = [], update_cc = [], update_bcc = [], update_forward = [];
	if(!isSecureUserID) { // manual errand always has this flag undefined.
		// there is a little hack for manual errand here as update_to is used
		// for the 'from' field creation manual errand. The manual errand will
		// not be sent out but merely to fill up the update_from later on.
		if(inputs.update_to.length > 0) {
			$.each(inputs.update_to, (i,v) => {
				update_to.push(v.id);
			});
		}
		if(inputs.reply_channel === RC_EMAIL &&
			(!manual || !isCreatingManualErrand)) {
			// CC, BCC option only make sense in email channel. And if it is
			// manual then only manual errand that need to send out will make
			// sense for it.
			if(inputs.update_cc.length > 0) {
				$.each(inputs.update_cc, (i,v) => {
					update_cc.push(v.id);
				});
			}
			if(inputs.update_bcc.length > 0) {
				$.each(inputs.update_bcc, (i,v) => {
					update_bcc.push(v.id);
				});
			}
			if(!manual && forward && inputs.update_forward.length > 0) {
				$.each(inputs.update_forward, (i,v) => {
					update_forward.push(v.id);
				});
			}
			if(all) { // manual errand should NOT has this flag on.
				let addressList = [];
				addressList.push(basic.toAddresses);
				addressList.push(basic.copyAddresses);
				const area = state.domain[D_AREAS].byId[inputs.area];
				if(area) {
					$.each(addressList, (i,v) => {
						if(Array.isArray(v)) {
							$.each(v, (j,w) => {
								if(!isOwnEmail(w, area)) {
									update_cc.push(w);
								}
							});
						}
					});
				}
			}
			if (update_cc.length > 0) {
				o.update_cc = update_cc.join( ',' );
			} else {
				o.update_cc = "";
			}
			if (update_bcc.length > 0) {
				o.update_bcc = update_bcc.join( ',' );
			} else {
				o.update_bcc = "";
			}
			// TODO: only update forward when it is external forward reply
			if (update_forward.length > 0) {
				o.update_forward = update_forward.join(',');
			}
		}
	} else {
		update_to.push(o.update_from);
	}
	if (update_to.length > 0) {
		o.update_to = update_to.join( ',' );
	}
	// // SMS - TODO: what if reply channel changed? Should base on reply channel
	// // and not base on opened errand service as one may change reply channel.
	// if(basic.service == Workflow.Errand.SERVICE_SMS &&
	// 	extend.errand_type == Workflow.Mail.TYPE_SMS) {
	// 	o.response_type = Workflow.Mail.TYPE_SMS;
	// 	o.response_channel = Workflow.Errand.SERVICE_SMS;
	// }
	let combined = attachmentObjectToArrayIDs(inputs, 'uploaded_attachments');
	let extraInfo = attachmentExtraInfo(inputs, 'uploaded_attachments');
	o.update_history = '';
	if (!manual && !isBulkSend) {
		o.done_date = inputs.done_date !== "" ? inputs.done_date : DEFAULT_NO_DUE_DATE;
		o.update_close = inputs.checkboxes[ECB_PARTIAL_ANSWER];
		o.update_pintop = inputs.update_pintop;
		o.update_priority = inputs.update_priority;
		o.update_lock = inputs.update_lock;
		const hc = e.fetchHistory.data.opr;
		if (hc.all) {
			o.update_history = 'all';
		} else {
			let selectedErrands = [];
			// NOTE: this is different from prior version as there isn't
			// actually any 'all'/'question' field. Though 'all' may be
			// added future, but 'question' is not needed as it is also part
			// of the fetch history that generate the selection boxes.
			$.each(hc, (k, v) => {
				if (k !== 'all') {
					if(v.selected) {
						// doesn't care whether int or string as later it'll turn
						// into string no matter how.
						selectedErrands.push(k);
					}
				}
			});
			o.update_history = selectedErrands.join(',');
		}
		if (forward) {
			// this is just hack to make sure the tag exclude question
			// is not in errand for external forward to send out.
			o.update_include_question = true;
		} else {
			o.update_include_question = inputs.checkboxes[ECB_INC_QUESTION];
			if (wf.fetchWfSettings.data.showIncludeAllHistories) {
				o.include_same_thread_errands = true;
			}
		}

		if(wf.fetchWfSettings.data['edit-question']){
			o.update_question = inputs.update_question;

			if (inputs.update_question != e.ui.lastSave.previous.update_question) {
				o.question_changed = "T" ;
			}
		} else {
			o.update_question = basic.body;
		}
		const rereactid = /data-reactid="([0-9.":$\\s-]+)/gi;
		o.update_question = o.update_question.replace(rereactid, "");

		// previously saved attachments
		const saved = attachmentObjectToArrayIDs(inputs, 'saved_attachments');
		combined = combined.concat(saved);

		// if(o.update_answer_forward) { // TODO: seem useless
		// 	o.update_answer_forward = o.update_answer_forward.replace(rereactid, "");
		// }

		// // TODO: seem useless because one should not be allowed to change
		// // original incoming mail's subject. And there backend goweb do not
		// // handle this part of code when it is non-manual errand.
		// const acq = e.acquireErrand.data.acquire.data;
		// if(acq.answer_subject) {
		// 	o.update_question_subject = acq.answer_subject;
		// } else {
		// 	o.update_question_subject = I("No Subject");
		// }

		//TODO
		//o.external_expert_copy_text_status = document.getElementById('ExternalExpertCopyTextStatus').innerHTML;

		const colAnsAttach = inputs.update_external_expert_Answer_attachments;
		if(colAnsAttach && colAnsAttach.length) {
			let attachments = [];
			$.each(colAnsAttach, (i,v) => {
				attachments.push(v.mailId + '/' + v.id);
			});
			o.update_external_expert_Answer_attachments = attachments.join(',');
		}
	} else {
		// manual errand hack as manual errand no incoming author and thus any
		// outgoing recipient should be the incoming author too.
		// If update_to contains multiple email addresses put the first address
		// in update_from
		if (o.update_to) {
			const toAddressList = o.update_to.split(',');
			o.update_from = toAddressList[0];
		}
		o.update_priority = inputs.update_priority;
		if (!isCall && isCreatingManualErrand) {
			// create manual errand never send out the errand so no need update
			// this field.
			o.update_to = '';
			// start manual errand (create and send) do not fill up the question
			// part because the errand will only has the answer part as it is
			// initiated by agent. On the other hand, creating manual errand
			// which do not send out will update the question field and not
			// answer field as this manual errand creation work more like
			// creating references.
			o.update_question = o.update_answer;
			o.update_answer = '';
		} else if (startManualErrand) {
			o.update_lock = inputs.checkboxes[ECB_LOCK2ME];
		}
	}
	o.update_uploaded_attachments = "0";
	if (combined.length > 0) {
		o.update_uploaded_attachments = "0," + combined.join(",");
	}
	if (extraInfo.length > 0) {
		o.update_uploaded_attachments_extrainfo = extraInfo;
	}
	// if(inputs.uploaded_attachments.length > 0) {
	// 	uploaded = Object.keys(inputs.uploaded_attachments).map(v => {
	// 		return inputs.uploaded_attachments[v].id;
	// 	});
	// 	o.update_uploaded_attachments += ua.join(",");
	// }
	if(inputs.archive_attachments.length > 0) {
		let uaf = Object.keys(inputs.archive_attachments).map(v => {
			return inputs.archive_attachments[v].id;
		});
		o.update_archive_attachments = uaf.join(",");
	}
	if (forward) {
		const files = selectedExtFwdQuestionAttachmentsSelector(state);
		if (files.length) {
			let q = [];
			$.each(files, (i, v) => {
				if (v && !sameValidContentIdInAttachments(v.contentId, inputs.saved_attachments)) {
					q.push(v.id);
				}
			});
			o.update_errand_attachments_with_mail = q.join(",");
		}
	} else if(inputs.question_attachments) {
		let q = [];
		$.each(inputs.question_attachments, (k, v) => {
			if (v) {
				q.push(k);
			}
		});
		o.update_errand_attachments_with_mail = q.join(",");
	}
	//if(inputs.checkboxes[ECB_INC_HISTORIES]) {
	if (o.update_history != '') {
		let q = [];
		$.each(inputs.selected_history_attachments, (k, v) => {
			if (v) {
				q.push(k);
			}
		});
		o.selected_history_attachments = q.join(",");
	}
	if(inputs.library_attachments.length > 0) {
		let idArr = [];
		for(let i=0; i < inputs.library_attachments.length; i++){
			idArr.push(inputs.library_attachments[i].id)
		}
		o.update_library_attachments = idArr.join(",");
	}
	if (inputs.reply_channel === RC_FACEBOOK) {
		if(inputs.quickReplyId != 0){
			o.update_fb_quick_reply_id = inputs.quickReplyId;
		}
	}
	
	if (manual) {
		//TODO: test social channel manual errand
		if (inputs.reply_channel === RC_FACEBOOK ||
			inputs.reply_channel === RC_VK ||
			inputs.reply_channel === RC_LINE ||
			inputs.reply_channel === RC_TWITTER ||
			inputs.reply_channel === RC_WHATSAPP ||
			inputs.reply_channel === RC_LINKEDIN) {
			if(inputs.selected_social_media) {
				const socialMedia = inputs.selected_social_media.split('#');
				o.update_facebook_account = socialMedia[0];
				switch(inputs.reply_channel) {
					case RC_FACEBOOK:
						o.update_from = socialMedia[1];
						o.update_to = socialMedia[1];
						o.update_close = false;
						o.update_channel = Workflow.Errand.SERVICE_MANUAL_FACEBOOK;
						break;
					case RC_VK:
						o.update_from = socialMedia[1];
						o.update_to = socialMedia[1];
						o.update_close = false;
						o.update_channel = Workflow.Errand.SERVICE_MANUAL_VKONTAKTE;
						break;
					case RC_LINE:
						o.update_channel = Workflow.Errand.SERVICE_MANUAL_LINE;
						break;
					case RC_TWITTER:
						o.update_from = socialMedia[1];
						o.update_to = socialMedia[1];
						o.update_close = false;
						o.update_channel = Workflow.Errand.SERVICE_MANUAL_TWITTER;
						break;
					case RC_WHATSAPP:
						o.update_channel = Workflow.Errand.SERVICE_MANUAL_WHATSAPP;
						o.update_wa_template_code = e.inputs.templCode;
						break;
					case RC_LINKEDIN:
						o.update_from = socialMedia[1];
						o.update_to = socialMedia[1];
						o.update_close = false;
						o.update_channel = Workflow.Errand.SERVICE_MANUAL_LINKEDIN;
						break;
				}
			}
		} else if (isCall) {
			o.update_channel = Workflow.Errand.SERVICE_MANUAL_VOICE;
			const phoneData = selectedPhoneDataMemoize(state);
			if (phoneData) {
				o.update_to = phoneData.value;
			}
		}
	}
	const ext = state.external; //TODO: get external from React router
	if(ext.externalManual) {
		o.external_manual = true
	}
	if(ext.openedByExternal) {
		o.openedByExternal = ext.openedByExternal;
	}
	if(manual || ext.externalManual) {
		o.update_question_subject = o.update_subject;
	}
	// add internal comment during manual errand creation
	if (manual) {
		let text = inputs.internal_comment, plain = inputs.plain_internal_comment;
		let trim = plain.replace(/^\s+|\s+$/, '');
		if((trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) &&
			/^(<div>[\s\xA0]*<\/div>\s*)+$/.test(text)) {
			text= "";
		}
		o.update_manual_internal_comment = text;
		o.update_manual_internal_comment_attachments = attachmentObjectToArrayIDs(inputs,
			'internal_comment_uploaded_attachments').join(',')
	}
	if (typeof inputs.selected_library_question !== "undefined") {
		o.selected_library_question = inputs.selected_library_question;
	}
	return o;
}

const warnSendEmptySubject = I('Are you sure you want to send without a subject?'),
	warnCreateEmptySubject = I('Are you sure you want to create an errand without a subject?');

const checkEmptySubject = option => (dispatch, getState) => {
	const e = getState().app.errand, {manual, manualStart, isSip} = option;
	const isCall = isCallMemoize(getState());
	let inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
	if(manual && isSip) {
		inpt = INPUTS_MANUAL_CALL;
	}
	if(!e[inpt].update_subject) {
		let text;
		if(!manual || manualStart) {
			text = warnSendEmptySubject;
		} else {
			text = warnCreateEmptySubject;
		}
		return new Promise((resolve, reject) => {
			dispatch(enableConfirm(
					ALRT_CFRM_OPR_OPTIONAL
					, text
					, {option}
				))
				.then(() => {
					resolve({option});
				})
				.catch(() => {
					reject({option});
				});
		});
	}
	return Promise.resolve({option});
};

const checkWithoutSend = option => (dispatch, getState) => dispatch(checkEmptySubject(option))
	.then(({option}) => Promise.resolve({
		param: createUpdateErrandObject(undefined, option, getState())
		, option
	}))
	.then(({param, option}) => dispatch(checkSizeLimit(param, option)))
	.then(({param, option}) => dispatch(checkValidForward(param, option)))
	.then(({param, option}) => dispatch(checkEmptyAnswer(param, option)))
	/* TODO: Double check for CCC-3509 */
	.then(({param, option}) => dispatch(checkEmptySignature(param, option)))
	.then(({param, option}) => dispatch(checkEmptyRecipient(param, option)));


const checkManualErrandPreview = option => (dispatch, getState) =>  {
	return Promise.resolve({
		param: createUpdateErrandObject(undefined, option, getState())
		, option
	})
	.then(({param, option}) => dispatch(checkEmptyRecipient(param, option)).catch(() => Promise.reject(I("Error when preparing errand for preview."))));
}

//
// Manual Errand code
//
const checkIfNeedCloseManualErrand = (
	param
	, option
) => (dispatch, getState) => {
	const resultData = getAppErrand(getState()).updateManualErrand.data;
	if (typeof resultData.error !== 'undefined' && resultData.error) {
		return Promise.reject({param, option, err: resultData.error});
	}
	let errandID = resultData.result;
	let cipherKey = resultData.cipherKey;
	let displayID = resultData.displayID;
	let extRefId = resultData.extRefId;
	// TODO: why closing errand need all the attachment IDs to carry along? Does
	// not look right, probably because param carry extra info is it? Then one
	// should just use a plain object param then? Why close errand can carry
	// attachment? Many questions can be throw here. Create (without send)
	// manual errand will put everything under errand.Mail part. Is this commit
	// change the behaviour now by putting attachments in answer part?
	let attIdStr = "";
	$.each(resultData.answer_mail_attachments, (i,v) => {
		attIdStr += v.id + ",";
	});
	if(attIdStr.length > 0){
		attIdStr = attIdStr.substr(0, attIdStr.length-1)
	}
	if(option.manualCreateType === ME_CREATE_AS_CLOSED) {
		return dispatch(closeErrand(update(param,
			{
				update_archive_attachments: {$set: ""},
				update_uploaded_attachments: {$set: attIdStr},
				update_id: {$set: errandID},
				update_cipher_key: {$set: cipherKey}
			})))
			.then(() => displayID);
	}
	return {displayID, errandID, cipherKey, extRefId};
};

const sendManualErrand = (param, option) => (dispatch, getState) => {
	option.isSend = true;
	return dispatch(askForClassification(param, option, false))
		.then(({param, option}) => dispatch(answerTranslation(param, option, true)))
		.then(({param, option}) => dispatch(sendAnswer(param)))
		.then(() => {
			const resultData = getAppErrand(getState()).sendAnswer.data;
			let errandID = resultData.errandID;
			let cipherKey = resultData.cipherKey;
			let displayID = resultData.displayID;
			return {displayID, errandID, cipherKey};
		})
		.catch(err => Promise.reject({ param, option, error: err }));
}

const manualErrandCreation = (param, option) => (dispatch, getState) => {
	if (option.manualStart) {
		// Send an errand
		return dispatch(sendManualErrand(param, option));
	}
	// Create an errand
	return dispatch(updateManualErrand(param))
		.then((data) => {
			let result = dispatch(checkIfNeedCloseManualErrand(param, option));
			let updatedErrand = mcamByID('UpdateErrand',data);
			if(typeof updatedErrand !== 'undefined' &&
				typeof updatedErrand.answer_mail_attachments !== 'undefined' &&
				updatedErrand.answer_mail_attachments != null &&
				updatedErrand.answer_mail_attachments.length > 0){
				dispatch(updateManualErrandSavedAttachments(
					updatedErrand.answer_mail_attachments));
				dispatch(deleteAllUploadadManualAttachments(emptyArray));
			}
			return result;
		});
};

const createManualErrand = (type, createType, isSip) => (dispatch, getState) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log("dbg: create manual errand:", {type, createType});
	}
	const isCall = isCallMemoize(getState());
	let manualCreate, manualCreateType, manualStart;
	if (!isCall && type === ME_START) {
		manualStart = true;
	} else {
		manualCreate = true;
		manualCreateType = createType;
		if (isCall && createType === ME_CREATE_AS_NEW) {
			dispatch(togglePopAlert(txtInvalidCreateOutboundErrandOption));
			return;
		}
	}
	if(isCall) {
		dispatch(manualCallState(ME_ST_BUSY));
	} else {
		dispatch(manualErrandState(ME_ST_BUSY));
	}
	dispatch(checkWithoutSend({
			isCall
			, manual: true
			, manualCreate
			, manualCreateType
			, manualStart
			, isSip
		}))
		.then(({ param, option }) => dispatch(manualErrandCreation(
			param
			, option
		)))
		.then(eIds => {
			let errandID = eIds.displayID;
			let realId = eIds.errandID;
			let cipherKey = eIds.cipherKey;
			let extRefId = eIds.extRefId;
			console.log('created manual errand ID', errandID);
			dispatch(manualCallStatus(true));
			if (isCall) {
				if(isSip) {
					dispatch(updateOutboundErrandId(realId));
				} else {
					dispatch(resetOutboundErrandId());
				}
				dispatch(clearOutboundCallSid());
			}
			if(isCall) {
				if(isSip){
					dispatch(manualCallState(ME_ST_CREATED, errandID, realId,
					cipherKey, extRefId));
				} else {
					dispatch(manualCallState(ME_ST_CREATED, errandID, realId,
					cipherKey));
				}
			} else {
				dispatch(manualErrandState(ME_ST_CREATED, errandID, realId,
					cipherKey));
			}
		})
		.catch(e => {
			let err;
			if (e instanceof Error) {
				err = 'JS error: ' + e;
			} else if (e) {
				if(e.error){
					err = 'error create manual errand: ' + e.error;
				}
			}
			if (err) {
				dispatch(togglePopAlert(err));
			}
			if(isCall) {
				dispatch(manualCallState(ME_ST_IDLE));
			} else {
				dispatch(manualErrandState(ME_ST_IDLE));
			}
		});
};

export const continueSipTransfer = (isForceSave) => async(dispatch, getState) => {
	const state = getState();
	let xferData = sipGetCallXferData(state)
	let conn = sipCallConn(state);
	if (typeof xferData === 'undefined' || xferData == null){
		if(isForceSave == true ){
			dispatch(forceSaveErrand())
		}
		return Promise.resolve(true);
	}
	let xferMode = sipCallCurrentTransferMode(state)
	console.info("continueSipTransfer: xferData:", xferData, "xferMode:",
		xferMode);
	if (xferData.isManual) {
		if(xferData.isExternalForward) {
			dispatch(sipCloseManualErrandAfterColdTransfer(xferData.eid,
				xferData.mCipherKey, xferData.extRefId));
			dispatch(sipColdTransfer(conn, xferData.sipId,
				xferData.targetAgentId, xferData.displayId, true, true));
		} else {
			const isCall = isCallMemoize(getState());
			let options = {
				isCall
				, manual: true
				, isSip: true
			};
			dispatch(manualCallState(ME_ST_BUSY));
			let params = createUpdateErrandObject(null, options,
				getState());

			const updateErrand = await new Promise((resolve, reject) => {
				resolve(dispatch(updateManualErrand(params)))
			});
			if(updateErrand) {
				if(xferMode == WARM_TRANSFER){
					dispatch(sipWarmFinalizeTransfer(conn,
						xferData.displayId, xferData.eid,
						xferData.mCipherKey, true,
						xferData.isExternalForward));
				} else {
					dispatch(forwardManualSipToAgent(xferData.eid,
						xferData.targetAgentId, xferData.mCipherKey,
						xferData.extRefId))
					.then(data => {
						if(xferData.isExternalForward == false){
							dispatch(removeAgentWorkingOnErrand(
								xferData.eid));
						}
						dispatch(sipColdTransfer(conn, xferData.sipId,
							xferData.targetAgentId, xferData.displayId,
							xferData.isExternalForward, true));
					});
				}
				dispatch(manualCallState(ME_ST_IDLE));
			}
		}
	} else {
		dispatch(forceSaveErrand())
		.then(res => {
			let dispatchee;
			let extRefId = sipGetRefId(conn);
			if(xferData.isExternalForward) {
				dispatchee = doCloseErrand(eid, false, false,
					extRefId);
			} else {
				if(xferMode == COLD_TRANSFER){
					dispatchee = forwardErrandToAgent(xferData.eid,
						xferData.targetAgentId,true, extRefId)
				} else {
					dispatch(sipWarmFinalizeTransfer(conn,
						xferData.displayId, xferData.eid,
						xferData.mCipherKey, false,
						xferData.isExternalForward));
					return Promise.resolve(true);
				}
			}
			dispatch(dispatchee)
			.then(data => {
				if(xferData.isExternalForward == false){
					dispatch(removeAgentWorkingOnErrand(xferData.eid));
				}
				if(xferMode == COLD_TRANSFER){
					dispatch(sipColdTransfer(conn, xferData.sipId,
					xferData.targetAgentId, xferData.displayId,
					xferData.isExternalForward, false));
				}
			});
		});
	}
	return Promise.resolve(true);
}

export const submitSipManualErrand = (
	conn
	, eid
	, sipId
	, displayId
	, targetAgentId
	, extRefId
	, isExternalForward
	, mCipherKey
	, isClose
) => async (dispatch, getState) => {
	const state = getState();
	let isRecording = sipCallIsRecording(state);
	if(isRecording) {
		console.info("submitSipManualErrand: stop recording");
		let transferdata = {eid, sipId, displayId, targetAgentId,
			extRefId, isExternalForward, mCipherKey, isClose, isManual:true};
		dispatch(sipSetCallTransferData(transferdata));
		dispatch(stopRecordingSip(eid));
	} else {
		dispatch(submitManualErrand(ME_CREATE, ME_CREATE_AS_MY,
			true, true, true, isClose))
		.then(res => {
			if(isExternalForward) {
				dispatch(sipCloseManualErrandAfterColdTransfer(eid,
					mCipherKey, extRefId));
				dispatch(sipColdTransfer(conn, sipId, targetAgentId, displayId, true, true));
			} else {
				dispatch(forwardManualSipToAgent(eid,
					targetAgentId, mCipherKey, extRefId))
				.then(data => {
					if(isExternalForward == false){
						dispatch(removeAgentWorkingOnErrand(eid));
					}
					dispatch(sipColdTransfer(conn, sipId, targetAgentId,
					displayId, isExternalForward, true));
				});
			}
		});
	}

	return Promise.resolve(true)
};

export const finalizeErrandWarmTransfer = (
	eid
	, displayId
	, conn
	, mCipherKey
	, isManual
	, isExternalTransfer
) => async (dispatch, getState) => {
	const state = getState();
	let isRecording = sipCallIsRecording(state);
	let recordingIsPaused = isRecordingPaused();
	if(isRecording || recordingIsPaused) {
		let transferdata = {eid, sipId: "", displayId, targetAgentId: "",
			extRefId: "", isExternalForward: isExternalTransfer, mCipherKey,
			isClose: false, isManual};
		dispatch(sipSetCallTransferData(transferdata));
		dispatch(stopRecordingSip(eid));
	} else {
		dispatch(sipWarmFinalizeTransfer(conn, displayId, eid,
              mCipherKey, isManual, isExternalTransfer));
	}
	return Promise.resolve(true)
};

export const submitManualErrand = (
	type
	, createType
	, forceUploadedAttachments
	, isSip
	, sipClose
	, isClose
) => async (dispatch, getState) => {
	const state = getState();
	const isCall = isCallMemoize(getState());
	const makeCallFromErrand = sipMakeCallCurrentErrand(getState());
	const errandState = getState().app.errand;
	let errandID = errandState.ui.manualCall.createdId; //displayid
	let meState = getState().app.errand.ui.manual.state;
	let forMinimizeCall = false;
	if(showManualSelector(state) === MP_NONE && showManualCallSelector(state) === MP_MINIMIZE) {
		forMinimizeCall = true;
	}
	if(isCall || forMinimizeCall){
		meState = getState().app.errand.ui.manualCall.state;
		if(makeCallFromErrand) {
			//only saving subject and description as those are editable
			dispatch(manualCallState(ME_ST_BUSY));
			dispatch(saveErrand(true, false));
		} else {
			if(errandState.ui.manualCall.createdId !== "" &&
				errandState.manualCallInputs.uploaded_attachments !== null &&
				errandState.manualCallInputs.uploaded_attachments.length > 0 ){
				let options = {
						isCall
						, manual: true
						, manualCreate: false
						, manualCreateType: createType
						, manualStart: false
						, newId: errandState.ui.manualCall.newErrandId
					};
				let params = createUpdateErrandObject(null, options,
					getState());
				// notice that updateErrand called twice when creating manual sip call (Create errand on outgoing call)
				// which introduce bug on fetching attachment
				// so purposely excluded the sip call from updated below and take the latter
				if(!isSip) {
					dispatch(updateManualErrand(params));
				}
			}
		}
	}
	if (meState === ME_ST_IDLE) {
		dispatch(createManualErrand(type, createType, isSip));
		return Promise.resolve(true);
	} else {
		if(isSip) {
			if(!makeCallFromErrand) {
				console.log("dbg: Saving manual call errand...");
				let options = {
					isCall
					, manual: true
					, isSip
				};
				dispatch(manualCallState(ME_ST_BUSY));
				let params = createUpdateErrandObject(null, options,
					getState());

				const updateErrand = await new Promise((resolve, reject) => {
					resolve(dispatch(updateManualErrand(params)))
				});
				if (updateErrand) {
					console.log("dbg: done updating errand ");
					let realId = errandState.ui.manualCall.newErrandId;
					let cipherKey = errandState.ui.manualCall.cipherKey;
					if(typeof sipClose !== 'undefined' && sipClose == true){
						if(!isClose) {
							dispatch(manualCallState(ME_ST_IDLE));
						}
					} else {
						dispatch(manualCallState(ME_ST_CREATED, errandID, realId,
							cipherKey));
					}
				}
			}
		}
	}
	if(!isSip) {
		dispatch(toggleManualOrCallPopup(MP_NONE));
	}
	if(makeCallFromErrand) {
		dispatch(manualCallState(ME_ST_CREATED, errandID));
		console.info("dbg: done save current errand");
	} else {
		console.info("dbg: done submit manual errand");
	}
	return Promise.resolve(true);
};

export const transferSipErrand = (
	eid
	, sipId
	, targetAgentId
	, displayId
	, extRefId
	, isExternalForward
) => async (dispatch, getState) => {
	const state = getState();
	let conn = sipCallConn(state);
	let isRecording = sipCallIsRecording(state);
	if(isRecording) {
		let transferdata = {eid, sipId, displayId, targetAgentId,
			extRefId, isExternalForward, mCipherKey:"" , isClose:false,
				isManual:false};
		dispatch(sipSetCallTransferData(transferdata));
		dispatch(stopRecordingSip(eid));
	} else {
		let dispatchee = null;
		dispatch(forceSaveErrand())
		.then(res => {
			let extRefId = sipGetRefId(conn);
			if(isExternalForward) {
				dispatchee = doCloseErrand(eid, false, false,
					extRefId);
			} else {
				dispatchee = forwardErrandToAgent(eid,
					targetAgentId,true, extRefId);
			}
			dispatch(dispatchee)
			.then(data => {
				if(isExternalForward == false){
					dispatch(removeAgentWorkingOnErrand(eid));
				}
				dispatch(sipColdTransfer(conn, sipId, targetAgentId,
				displayId, isExternalForward, false));
			});
		});
	}
	return Promise.resolve(true);
};

const warnAcquireErrands = I('You have selected some open errands that you have not yet acquired. Are you sure you want to continue without those errands?');

export const sendReply = option => (dispatch, getState) => {
	const state = getState();
	dispatch(answerState(E_A_SENDING, state.app.errand.basic.data.data.id));
	const others = getSelectedOpenErrandsMemoize(state);
	option.isSend = true;
	new Promise((resolve, reject) => {
			if (others.length > 0) {
				resolve(dispatch(enableConfirm(
					ALRT_CFRM_OPR_OPTIONAL
					, warnAcquireErrands
					, null
				)));
			} else {
				resolve();
			}
		})
		.then(() => dispatch(checkWithoutSend(option)))
		.then(({ param, option }) => dispatch(askForClassification(
			param
			, option
			, false
		)))
		.then(({ param, option }) => dispatch(sendErrandReply(param, option)))
		.catch(e => {
			if(e instanceof Error) {
				console.log('dbg: JS error:', {e});
			} else {
				const { param, option, err } = e;
				if (err) {
					dispatch(togglePopAlert(err.message));
				}
			}
			dispatch(clearAnswerState());
		});
};

const rawSaveErrand = (force, isAutoSave, saveAndClose) => (dispatch, getState) => {
	dispatch(saveErrandStart());
	const state = getState()
		, e = state.app.errand
		, id = e.currentErrand.id
		;
	dispatch(answerState(E_A_SAVING, id));
	if (!force && !isInputsChangedMemoize(state)) {
		console.log('dbg: no change so no save');
		dispatch(clearAnswerState());
		// promise must resolve with undefined here as indication of no saving
		// occurs.
		return Promise.resolve();
	}
	let obj;
	if (isAutoSave) {
		obj = {auto_save: true};
	} else {
		if(saveAndClose == true){
			obj = {save_close: true};
		} else {
			obj = null;
		}
	}
	return dispatch(updateAnswer(createUpdateErrandObject(obj, DEF_OPT, state)))
		.then(data => {
			// TODO: directly get from reducer state
			const { result, error } = mcamResult(data);
			if (error) {
				return Promise.reject({err: error});
			}
			dispatch(clearAnswerState());
			dispatch(reloadBasicErrand(id))
				.catch(err => {console.log('dbg reload-errand-catch:', err)});
			return {result: result.result};
		})
		.catch(({ err, notified }) => {
			// TODO: send notification
			dispatch(clearAnswerState());
			console.log('dbg save-errand-catch:', {err, notified});
		});
};

const saveErrand = (force, saveAndClose) => rawSaveErrand(force, false, saveAndClose);

function saveErrandFactory() {
	let lastTimeout = Date.now();
	return isAutoSaveTimeout => (dispatch, getState) => {
		if (!isAutoSaveTimeout && (Date.now() - lastTimeout) < TMR_AUTO_SAVE) {
			// when agent stopped typing and before auto save timeout
			return;
		}
		const state = getState()
			, errand = state.app.errand
			;
		if (!isErrandActionInProgressSelector(state)
			&& isIdleAnswer(state)
			&& errand.ui.lastSave.canSave) {
			return dispatch(rawSaveErrand(false, true))
				.then(result => {
					if (!getState().app.errand.ui.lastSave.triggered) {
						dispatch(markLastSaveTriggered(true));
					}
					if (result && result.result) {
						lastTimeout = Date.now();
					}
				});
		} else {
			return dispatch(saveErrandStart());
		}
	};
}

const tmrAgentIdle = TMR_AGENT_NO_TYPING + THROTTLED_TMR;

// periodic auto save code.
export const enableAutoSave = () => dispatch => {
	const saveErrandFunc = saveErrandFactory();
	dispatch(addTimeout(
		tmrAgentIdle
		, AGENT_BUSY_TYPING
		, () => { 
			// run this function if AGENT_BUSY_TYPING (action) has not been
			// dispatched in tmrAgentIdle amount of time
			dispatch(agentNotTyping());
			dispatch(saveErrandFunc(false));
		}
	));
	// run save errand if SAVE_ERRAND (action) has not been dispatched in 
	// TMR_AUTO_SAVE amount of time
	return dispatch(addTimeout(
		TMR_AUTO_SAVE
		, SAVE_ERRAND
		, () => dispatch(saveErrandFunc(true))
	));
};

export const stopAutoSave = () => dispatch => {
	dispatch(removeTimeout(SAVE_ERRAND));
	dispatch(removeTimeout(AGENT_BUSY_TYPING));
};

// popup alert modal and also clear the answer state.
const sendAlert = text => (dispatch, getState) => {
	const p = dispatch(togglePopAlert(text));
	dispatch(clearAnswerState());
	return p;
};

const shouldCheckLimitSize = (state, manual) => {
	const e = state.app.errand;
	let result = {};
	if(!manual) {
		const basic = e.basic.data.data, extend = e.fetchExtendedData.data.data;
		if(basic.service === Workflow.Errand.SERVICE_SMS &&
			extend.errand_type === Workflow.Mail.TYPE_SMS) {
			result.sms = {}; // mark this as sms service
			if(extend.max_sms_size > 0) {
				result.sms.size = extend.max_sms_size;
			}
		} else {
			const maxEmailSize = extend.total_allowed_size,
				encodingFactor = extend.encoding_factor;
			if(maxEmailSize > 0	&& encodingFactor > 0) {
				result.email = {maxEmailSize, encodingFactor};
			}
		}
	} else {
		//TODO: temporary ignore this for manual errand
	}
	return result;
};

const warnSendEmptyAnswer = I('Are you sure you wish to send an empty answer?'),
	warnCreateEmptyAnswer = I('Are you sure you wish to create an empty question errand?'),
	ErrCannotSendCollab = I("This collaboration query can not be sent, external expert answer is pending from the same person!");

const checkSizeLimit = (param, option) => (dispatch, getState) => {
	let exceededSizeAlert;
	const shouldCheck = shouldCheckLimitSize(getState(), option.manual);
	if(shouldCheck.sms) {
		const size = shouldCheck.sms.size;
		if(size && param.update_answer.length > size) {
			exceededSizeAlert = I("Errand has exceeded maximum allowed size of {MAXSIZE} bytes. Current size is {CURRSIZE} bytes.")
				.replace('{MAXSIZE}', size)
				.replace('{CURRSIZE}', param.update_answer.length);
		}
	} else if(shouldCheck.email) {
		const {maxEmailSize, encodingFactor} = shouldCheck.email;
		let questionLength;
		if(param.update_question) {
			questionLength = param.update_question.length;
		} else {
			questionLength = 0;
		}
		const total = param.update_attachment_total +
			(questionLength + param.update_answer.length) *
			encodingFactor;
		const roundedTotal = Math.round(total);
		if(roundedTotal > maxEmailSize) {
			exceededSizeAlert = I("Errand has exceeded maximum allowed size of {MAXSIZE} bytes. Current size is {CURRSIZE} bytes.")
				.replace('{MAXSIZE}', maxEmailSize)
				.replace('{CURRSIZE}', roundedTotal);
		}
	}
	if(exceededSizeAlert) {
		return new Promise((resolve, reject) => {
			dispatch(sendAlert(exceededSizeAlert))
				.then(() => {
					reject({param, option});
				})
				.catch(() => {
					reject({param, option});
				});
		});
	}
	return Promise.resolve({param, option});
};

const checkValidForward = (param, option) => (dispatch, getState) => {
	if(option.reply === RPLY_EXT_FWD && param.update_forward == "") {
		return new Promise((resolve, reject) => {
			dispatch(sendAlert(I("You have to fill in an address for Forward to external.")))
				.then(() => {
					reject({param, option});
				})
				.catch(() => {
					reject({param, option});
				});
		});
	}
	return Promise.resolve({param, option});
};

export const checkEmptyAnswer = (param, option) => (dispatch, getState) => {
	let answerCanNotEmpty;
	if (!option.manual || option.manualStart || option.isCall || option.isSip) {
		answerCanNotEmpty = true;
	}
	if (answerCanNotEmpty) {
		if (option.reply !== RPLY_COLLABORATE) {
			if(option.isCall || option.isSip) {
				param.update_answer = getState().app.errand.manualCallInputs.update_answer;
			}
			if (param.update_answer) {
				return Promise.resolve({param, option});
			}
		} else if (!option.answerQuery) {
			// normal collaboration
			if (param.htmlBody) {
				return Promise.resolve({param, option});
			}
		} else {
			// direct answering collaboration
			if (param.message) {
				return Promise.resolve({param, option});
			}
		}
	} else {
		if (param.update_question) {
			return Promise.resolve({param, option});
		}
	}
	let text;
	if (answerCanNotEmpty) {
		text = warnSendEmptyAnswer;
	} else {
		text = warnCreateEmptyAnswer;
	}
	return dispatch(enableConfirm(
		ALRT_CFRM_OPR_OPTIONAL
		, text
		, {param, option}
	));
};

const MUST_REPLY_TO = 0
	, MUST_TO = 1
	, MUST_MANUAL_CREATE = 2
	, OPTIONAL_REPLY_TO = 0
	, OPTIONAL_FORWARD = 1
	, OPTIONAL_MANUAL_CREATE = 2
	, warnEmptyRecipientTexts = {
		must: {
			[MUST_REPLY_TO]: I("Please provide a reply-to address.")
			, [MUST_TO]: I("You have to fill in a To address to send.")
			, [MUST_MANUAL_CREATE]: I("Please enter a From address.")
		}
		, optional: {
			[OPTIONAL_REPLY_TO]: I("Are you sure you wish to send the errand without selecting a reply-to address?")
			, [OPTIONAL_FORWARD]: I("Are you sure you want to forward the errand without selecting a reply-to address?")
			, [OPTIONAL_MANUAL_CREATE]: I("Are you sure you wish to create an errand without a from address?")
		}
	}
	;
export const checkEmptyRecipient = (param, option) => (dispatch, getState) => {
	const { manual, reply } = option;
	if (reply === RPLY_EXT_FWD) {
		return Promise.resolve({param, option});
	}
	let isInvalidRecipient, must, messageType;
	if (reply === RPLY_COLLABORATE) {
		if(!param.internals.length && !param.areas.length && (!param.externalExpertAddresses ||
			!param.externalExpertAddresses.length)) {
			isInvalidRecipient = true;
			must = true;
			messageType = MUST_TO;
		}
	} else if (option.forwardToArea) {
		if (!param.update_to) {
			isInvalidRecipient = true;
			messageType = OPTIONAL_FORWARD;
		}
	} else if (!manual || option.manualStart) {
		if (!param.update_to) {
			isInvalidRecipient = true;
			must = true;
			if(!manual) {
				messageType = MUST_REPLY_TO;
			} else {
				// option.manualStart true
				messageType = MUST_TO;
			}
		}
	} else if (manual && option.manualCreate) {
		if (!param.update_from) {
			isInvalidRecipient = true;
			if(option.manualCreate !== ME_CREATE_AS_CLOSED) {
				must = true;
				messageType = MUST_MANUAL_CREATE;
			} else {
				messageType = OPTIONAL_MANUAL_CREATE;
			}
		}
	}
	if (isInvalidRecipient) {
		return new Promise((resolve, reject) => {
			let mustOrOptional;
			if(must) {
				mustOrOptional = 'must';
			} else {
				mustOrOptional = 'optional';
			}
			const text = warnEmptyRecipientTexts[mustOrOptional][messageType];
			if(must) {
				dispatch(sendAlert(text))
					.then(() => {
						reject({param, option});
					});
			} else {
				dispatch(enableConfirm('empty_recipient', text))
					.then(() => {
						// confirmed yes for empty recipient
						resolve({param, option});
					})
					.catch(() => {
						// reject and not proceed
						reject({param, option});
					});
			}
		});
	}
	return Promise.resolve({param, option});
};

export const checkEmptySignature = (param, option) => (dispatch, getState) => {
	if(option.reply === RPLY_ERRAND) {
		if(param.update_signature === 0 ) {
			return new Promise((resolve, reject) => {
				const feat = getState().server.features, forceSig = feat["agent.enforce.signature.answer.errand"];
				const text = I("Please select a signature");
				if(forceSig) {
					dispatch(sendAlert(text))
						.then(() => {
							reject({param, option});
						});
				} else {
					resolve({param, option});
				}
			});
		}
	}
	return Promise.resolve({param, option});
};

const OPT_TAG_ONE = I('Are you sure you wish to close the errand without choosing a classification?')
	, OPT_TAG_MANY = I('You are about to close errands without tags, do you want to proceed?')
	;
function getOptTagMsg(primaryIDs, secondaryIDs, associatedListLength) {
	if (primaryIDs.length + secondaryIDs.length + associatedListLength > 1) {
		return OPT_TAG_MANY;
	}
	return OPT_TAG_ONE;
}

function getOptionalTaggingMsg(isOptional, ids, associatedListLength) {
	if (!isOptional || !ids.length) {
		return "";
	}
	return getOptTagMsg(ids, [], associatedListLength);
}

function filter(v) {
	return v.needTagging;
}

function filterIDs(state, func) {
	return func(state).filter(filter);
}

function getAssociateTagList(state) {
	let tags = [];
	$.each(getMemoizeAssociatedList(state), (k, { data }) => {
		const { id, area } = data;
		// TODO: check if there is already tag and no need push tagged errand
		tags.push({id, area});
	});
	return tags;
}

function hasAssociatedList(state) {
	let has;
	$.each(getMemoizeAssociatedList(state), k => {
		if (k) {
			has = true;
			return false;
		}
	});
	return has;
}

function checkAndGetCurrentErrandTag(param) {
	if (param.update_tags) {
		return [];
	}
	return [{id: param.update_id, area: param.update_area_id}];
}

function getArrayOf(arr, key) {
	if (!arr.length) {
		return [];
	}
	return $.map(arr, v => {
		return v[key];
	});
}

const formInitErrandsTags = ids => {
	let ts = {};
	$.each(ids, (i,v) => {
		ts[v] = [];
	});
	return ts;
};

// return promise from start classification func. Only non-manual errand will
// trigger this function.
const prepareClassification = (
	param
	, option
	, isDelete
	, chat
) => (dispatch, getState) => {
	const state = getState()
		, e = state.app.errand
		, inputs = e.inputs
		, associatedList = getMemoizeAssociatedList(state)
		;
	let errands
		, tags = {}
		, areas = {[inputs.area]: true}
		, es = {}
		, chatAssociatedErrands
		, chatAssociatedErrandCipherKeys
		, updatedOption
		;
	if (chat) {
		chatAssociatedErrands = [];
		chatAssociatedErrandCipherKeys = [];
		updatedOption = {chatAssociatedErrands, chatAssociatedErrandCipherKeys};
	}
	if (!chat || !param.update_tags) {
		errands = [{id: e.currentErrand.id, area: inputs.area}];
	} else {
		// if chat already have the tag(s) then no need popup tagging.
		// NOTE: this part different between live chat and errand classification
		// popup where live chat will not appear in the popup if it already been
		// tag meanwhile current non-chat errand will still appear in the popup
		// as long as there is any associated errand has no tag.
		errands = [];
		updatedOption.previousUpdateTags = param.update_tags;
	}
	$.each(associatedList, (i, v) => {
		const { id, area, cipherKey } = v.data;
		if (!areas[area]) {
			areas[area] = true;
		}
		if (!es[id]) {
			es[id] = {id, area};
			tags[id] = [];
			if (chat) {
				chatAssociatedErrands.push(id);
				chatAssociatedErrandCipherKeys.push(cipherKey);
			}
		}
	});
	$.each(areas, (k, v) => {
		if (v) {
			dispatch(associatedAreaData(k));
		}
	});
	$.each(es, (k, v) => {
		if (v) {
			errands.push(v);
		}
	});
	if (chat) {
		option = update(option, {$merge: updatedOption});
	}
	const p = dispatch(startClassification({
			param
			, errands
			, option
			, tags
			, actType: isDelete
		}));
	$.each(es, (k, v) => {
		// the fetching must come after classification enabled for accurate
		// reflection that reducer can capture all the needed tag
		if (v) {
			// TODO: fetch also associate extended data getting previous tagged
			// tags
			//dispatch(associatedExtendedData(v.id));
		}
	});
	return p;
};

export const askForClassification = (param, option, isDelete, chat, isForceAuto) => (dispatch, getState) => {
	const state = getState()
		, { manual, multiple } = option
		, single = !multiple
		, notChatNotMultiple = !chat && single
		// associated errands (other contacts errands) only valid to be
		// handled together for situation closing or answering an opened
		// errand (non-manual). Deleting an errand will not take associated
		// errands into account. Multi-errands actions will NOT take into
		// account. Closing a chat errand will NOT take into account also
		// (TODO: debatable).
		, checkAssociatedErrands = single && !manual
		, f = state.app.workflow.fetchWfSettings.data
		, optionalErrandTag = f['mustConfirmNoClassificationJavascript']
		;
	let associatedListLength = 0
		, tagErrandIds = emptyArray
		, confirmMsg = ""
		, forceErrandTag = f['mustConfirmNoClassificationCention']
		;

	if(isForceAuto != null && isForceAuto == true){
		option = update(option, {noNeedTag: {$set: true}});
		return Promise.resolve({param, option});
	}
	if(f['mustConfirmNoClassificationCentionUseCustom']){
		if(option.isSend){
			forceErrandTag = f['mustConfirmNoClassificationCentionSend'];
		}

		if(option.isClose){
			forceErrandTag = f['mustConfirmNoClassificationCentionClose'];
		}

		if(isDelete){
			forceErrandTag = f['mustConfirmNoClassificationCentionDelete'];
		}
	}

	if (!manual) {
		const forceChatErrandTag = f['chat.forced-tag']
			, optionalChatErrandTag = f['chat.optional-tag']
			, chatErrandIds = filterIDs(state, getSelectedChatErrandsWithArea)
			;
		let errandIds
			, associatedList = emptyArray
			;
		if (notChatNotMultiple) {
			errandIds = checkAndGetCurrentErrandTag(param);
		} else {
			errandIds = filterIDs(state, getSelectedErrandsWithArea);
		}
		if (checkAssociatedErrands) {
			associatedList = getAssociateTagList(state);
			associatedListLength = associatedList.length;
		}
		if (forceChatErrandTag && forceErrandTag) {
			if (!notChatNotMultiple && !checkAssociatedErrands) {
				tagErrandIds = filterIDs(state, getSelectedAllErrandsWithArea);
			} else {
				// single opened errand can not determine whether need tagging
				// from getSelectedAllErrandsWithArea which base on errand-list
				// that can be outdated.
				tagErrandIds = errandIds.concat(chatErrandIds)
					.concat(associatedList);
			}
		} else if (forceChatErrandTag) {
			tagErrandIds = chatErrandIds;
			confirmMsg = getOptionalTaggingMsg(optionalErrandTag, errandIds,
				associatedListLength);
		} else if (forceErrandTag) {
			if (!checkAssociatedErrands) {
				tagErrandIds = errandIds;
			} else {
				tagErrandIds = errandIds.concat(associatedList);
			}
			confirmMsg = getOptionalTaggingMsg(optionalChatErrandTag,
				chatErrandIds, associatedListLength);
		} else if (optionalErrandTag && errandIds.length > 0) {
			let ids;
			if (optionalChatErrandTag) {
				ids = chatErrandIds;
			} else {
				ids = emptyArray;
			}
			confirmMsg = getOptTagMsg(errandIds, ids, associatedListLength);
		} else if (optionalChatErrandTag && chatErrandIds.length > 0) {
			confirmMsg = getOptTagMsg(chatErrandIds, emptyArray,
				associatedListLength);
		} else {
			return Promise.resolve({param, option});
		}
	} else {
		// manual errand handling
		if ((!optionalErrandTag && !forceErrandTag) || param.update_tags) {
			return Promise.resolve({param, option});
		} else if (!forceErrandTag && optionalErrandTag) {
			confirmMsg = OPT_TAG_ONE;
		}
	}
	// Filter errands from tagging if area' tag not defined
	if (!single && tagErrandIds.length > 0) {
		let areaMap = new Map();
		let areaWithoutTags = [];
		$.each(tagErrandIds, (i, v) => {
			if(areaMap.has(v.area)) {
				return;
			}
			areaMap.set(v.area, v.area);

			let tagList;
			if(isDelete){
				tagList = getState().app.errand.fetchAreaTags.data[v.area].delete_tags;
			}else{
				tagList = getState().app.errand.fetchAreaTags.data[v.area].normal_tags;
			}
			if(!tagList || (tagList && tagList.length === 0)){
				areaWithoutTags.push(v.area);
			}
		})
		if (areaWithoutTags.length > 0) {
			tagErrandIds = tagErrandIds.filter(function(item) {
				return !areaWithoutTags.includes(item.area);
			})
		}
	}

	const forceTagging = () => {
		const isCall = isCallMemoize(state);
		let forMinimizeCall = false;
		if(showManualSelector(state) === MP_NONE && showManualCallSelector(state) === MP_MINIMIZE) {
			forMinimizeCall = true;
		}
		let canSkipTag = false;
		let tagList = getClassificationTagsMemoize(getState());
		if (manual || associatedListLength == 0) {
			let tags
			, errands = []
			;
			if (single) {
				let id
				, areaId
				;
				if (chat) {
					id = chat.errand.id;
					// TODO: getChatArea can return undefined when mis-setup organisation
					// is disabled but area still active.
					areaId = getChatArea(chat).Id;
				} else {
					let e = getState().app.errand
					, inpt = (manual) ? ((isCall || forMinimizeCall) ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND
					;
					id = e.currentErrand.id;
					areaId = e[inpt].area;
					if(!manual){
						if(!isDelete){
							tagList = getState().domain.areas.byId[areaId].normal_tags;
						}else{
							tagList = getState().domain.areas.byId[areaId].delete_tags;
						}
					}else{
						tagList = getManualErrandAreaTags(getState());
						id = param.update_id;
					}
				}
				tags = {[id]: []}
				errands = [{id, area: areaId}]
				if(!tagList || (tagList && tagList.length === 0)){
					canSkipTag = true;
				}
			} else {
				if (option.tags) {
					tags = option.tags;
				} else {
					tags = formInitErrandsTags(getArrayOf(tagErrandIds, "id"));
					option = update(option, {tags: {$set: tags}});
				}
				errands = tagErrandIds;
			}
			if(canSkipTag){
				if(single){
					return Promise.resolve({param, option});
				}
			}
			return dispatch(startClassification({
					param
					, errands
					, option
					, tags
					, actType: isDelete
				}));
		}
		return dispatch(prepareClassification(param, option, isDelete, chat));
	}
	if (confirmMsg != "") {
		return dispatch(enableConfirm(
				ALRT_CFRM_OPR_OPTIONAL
				, confirmMsg
				, {param, option}
			))
			.then(() => {
				if (tagErrandIds.length == 0) {
					return Promise.resolve({param, option});
				}
				return forceTagging();
			});
	}
	// No errands required to tag
	if (!manual && tagErrandIds.length == 0) {
		option = update(option, {noNeedTag: {$set: true}});
		return Promise.resolve({param, option});
	}
	return forceTagging();
};

const joinTags = tags => {
	let ts = [];
	$.each(tags, (i,v) => {
		$.each(v, (j,w) => {
			ts.push(w);
		});
	});
	return ts.join(',');
};

// start classification tagging process and return a promise with resolve mean
// classification finished and reject mean cancel button clicked.
const startClassification = value => dispatch => dispatch(enableClassification(
	'start'
	, value
));

const enableClassification = (opr, value) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	if(opr !== 'confirm') {
		if(opr === 'start') {
			return new Promise((resolve, reject) => {
				dispatch({
					type: ERRAND_CLASSIFICATION
					, payload: {opr, value, promise: {resolve, reject}}
				});
			});
		} else if(opr === 'cancel') {
			const c = getState().app.errand.inputs.classification
				, { promise, param, option } = c
				, error = "";
			if(option.isSip && option.manual && option.newId) {
				//cancelling tagging on outbound SIP call
				dispatch({type: ERRAND_CLASSIFICATION, payload: {opr: 'done'}});
				return;
			}
			promise.reject({param, option, error});
		}
		dispatch({type: ERRAND_CLASSIFICATION, payload: {opr, value}});
		return;
	}
	const state = getState()
		, e = state.app.errand
		, c = e.inputs.classification
		, { errands, index, same, firstTags, tags, option } = c
		, { id } = errands[index]
		;
	// this part maybe execute at last confirmation of errand tagging but here
	// trying to mimic the old version js code.
	let { param } = c
		, currentTags
		, inputs
		, currentErrandId
		, manual
		;
	if(option && option.manual) {
		manual = true;
		inputs = (isCall ? e.manualCallInputs : e.manualInputs);
	} else {
		inputs = e.inputs;
		currentErrandId = e.currentErrand.id
	}
	if(manual || id === currentErrandId) {
		currentTags = inputs.tags;
		param = update(param, {update_tags: {$set: joinTags(currentTags)}});
	} else {
		const tag = 'tags_' + id;
		currentTags = tags[id];
		param = update(param, {$merge: {[tag]: joinTags(currentTags)}});
	}
	if(index >= errands.length - 1) {
		// must resolve first then only trigger the done classification action.
		const { promise } = c;
		promise.resolve({param, option});
		dispatch({type: ERRAND_CLASSIFICATION, payload: {opr: 'done'}});
		return Promise.reject(c); // signal no need auto advance next errand
	}
	value = {param, index: index+1};
	if ((!firstTags || !firstTags.length) && currentTags &&
		currentTags.length) {
		let tagsMap = {};
		const tags = getClassificationTagsMemoize(state);
		$.each(currentTags, (i, v) => {
			tagsMap[v.join(":")] = getTagName(tags, v);
		});
		value.firstTags = currentTags;
		value.tagsMap = tagsMap;
	}
	dispatch({type: ERRAND_CLASSIFICATION, payload: {opr: 'next', value}});
	return new Promise((resolve, reject) => {
		new Promise(resolveArea => {
			const {
					id: areaID
					, data
					, useCurrent
				} = getClassificationAreaDataMemoize(getState())
				;
			if (!data) {
				dispatch(togglePopWaiting(I('Loading area tag data...')));
				let dispatchee;
				if (option.multiple || useCurrent) {
					dispatchee = getAreaData(areaID);
				} else {
					dispatchee = associatedAreaData(areaID);
				}
				dispatch(dispatchee)
					.then(() => {
						const {
								data
							} = getClassificationAreaDataMemoize(getState());
						dispatch(clearPopWaiting());
						resolveArea(data);
					})
					.catch(() => {
						// TODO: send notification we can not resolve area if
						// not already send.
						dispatch(clearPopWaiting());
						resolveArea();
					});
			} else {
				resolveArea(data);
			}
		})
		.then(areaData => {
			// TODO: how to handle this in review context? Should we retrieve
			// back those owner tagged associated errands' tags? Because of the
			// existing code where first tag will apply to the rest of
			// associated errands, reviewer first tags (which is correctly init
			// with owner's tags now), will always apply to associated errands'
			// tags. This'll give erroreous result when owner use different tags
			// for other associated errands which can only happen in rare
			// situation such as mismatch tags of different area or no tagging
			// for main errand. These different associated errand's tags from
			// the owner isn't retrieved back to reviewer at the moment. If we
			// need solve this, sending errands need to carry those associated
			// errands' area within the send reply endpoint parameter, so that,
			// those errands' tag-ids can be translated back to their name using
			// the area-ids. These area-ids can't just rely those errands
			// current selected area which can be changed without changing the
			// saved associated errands' tags.
			const {
					firstTags
					, tagsMap
					, isDeleting
				} = getState().app.errand.inputs.classification
				;
			if (areaData && same && firstTags && firstTags.length) {
				// same flag is used to determine if the same tag should be used
				// for next errand in tagging. This will overwrite any already
				// tagged tag. Also need to determine those tags used to
				// overwrite next errand must be valid tags for the next errand.
				let updatedTags = []
					, notFoundTags
					, areaTags
					;
				if (isDeleting) {
					areaTags = areaData.delete_tags;
				} else {
					areaTags = areaData.normal_tags;
				}
				$.each(firstTags, (i, v) => {
					if (isInsideAreaTags(areaTags, v)) {
						updatedTags.push(v);
					} else {
						if (!notFoundTags) {
							notFoundTags = [v];
						} else {
							notFoundTags.push(v);
						}
					}
				});
				value.tag = updatedTags;
				if (notFoundTags) {
					let noTagNames = [];
					$.each(notFoundTags, (i, v) => {
						noTagNames.push(tagsMap[v.join(":")]);
					});
					value.noTag = noTagNames;
				}
				dispatch({
					type: ERRAND_CLASSIFICATION
					, payload: {opr: 'update', value}
				});
				if (!notFoundTags) {
					resolve(value);
					return;
				}
			}
			reject(value);
		});
	});
};

export const confirmTagging = () => dispatch =>
	dispatch(enableClassification('confirm'))
		.then(() => dispatch(confirmTagging()))
		.catch(e => {
			if (e instanceof Error) {
				console.trace && console.trace("error in confirm tagging:", e);
			}
		});

export const cancelTagging = () => enableClassification('cancel');

const emphasize = text => '<u><em>' + text + '</em></u>',
	paragraphSeparator = "<br />\n<br />\n<p>";

const translate = (sendText, to) => (dispatch, getState) => {
	const texts = [
		"Automatic translation from {0}" + " to " + getLanguageName(to) + ":",
		"Original text:",
		//"Question automatically translated from " + "en" + " to " + to + ":",
		//"Subject"
	];
	return dispatch(translationAjax(texts, "en", to))
		.then(translatedText => {
			return dispatch(detectLanguageAjax(sendText))
				.then(from => {
					return dispatch(translationAjax([sendText], from.text, to))
						.then(translatedAnswer => ({
							translatedAnswer: translatedAnswer.texts,
							translatedText: translatedText.texts,
							from: from.text
						}));
				});
		});
};
const canSendEEToSender = ( externalExpertAddress ) => (dispatch, getState) => {
	return dispatch(checkToSendEEAjax(externalExpertAddress))
		.then(result => {
			return result;
		});
};

const getTranslatedText = (orignalText, {translatedText, translatedAnswer, from}) => {
	return [
		emphasize(translatedText[0].replace("{0}", getLanguageName(from)))
		, translatedAnswer.join(paragraphSeparator) //answer translation
		, emphasize(translatedText[1])
		, orignalText
		//, emphasize(message[2])
		//, message[3] + ": " + question //question translation
	].join(paragraphSeparator);
};

export const checkTranslate = (param, option, isCollaboration, isManual) => (dispatch, getState) => {
	const state = getState(), wf = state.app.workflow, e = state.app.errand,
		canTranslate = wf.fetchWfSettings.data.translation;
	let to, textKey;
	if (isCollaboration) {
		to = e.collaborationInputs.translateTo;
		textKey = 'htmlBody';
	} else if(isManual) {
		const isCall = isCallMemoize(getState());
		to = e.manualInputs.translate_to;
		if(isCall) {
			to = e.manualCallInputs.translate_to;
		}
		textKey = 'update_answer';
	} else {
		to = e.inputs.translationTo;
		textKey = 'update_answer';
	}
	const sendText = param[textKey];
	if (canTranslate && to && typeof sendText !== 'undefined') {
		return dispatch(translate(sendText, to))
			.then(result => ({
				param: update(param, {[textKey]: {
					$set: getTranslatedText(sendText, result)
				}}),
				option
			}));
	}
	return Promise.resolve({param, option});
};


export const canSendCollab = (param, option) => (dispatch, getState) => {
	const state = getState(), wf = state.app.workflow, e = state.app.errand;
	if(param.collabChannel !== RC_GOOGLECHAT){
		return Promise.resolve({param, option});
	}else if(param.collabChannel === RC_GOOGLECHAT){
		let sender = "";
		if(param.externalExpertAddresses.length > 0){
			sender = param.externalExpertAddresses[0];
		}
		if(sender === ""){
			return Promise.resolve({param, option});
		}
		return dispatch(canSendEEToSender(sender))
		.then(result =>{
			if(typeof result !== 'undefined' && !result.canSend){
				return dispatch(popError(ErrCannotSendCollab))
				.then(() =>{
				return Promise.reject({param, option});
				});
			}else{
				return Promise.resolve({param, option});
			}
		}
		);

	}
	return Promise.resolve({param, option});
};

const answerTranslation = (param, option, isManual) => checkTranslate(param, option, false, isManual);

const sendErrandReply = (param, option) => (dispatch, getState) => dispatch(answerTranslation(param, option, false))
	.then(({param, option}) => dispatch(sendAnswerAndClearState(param, option)))
	.catch(err => Promise.reject({param, option, err}));

const sendAnswerAndClearState = (param, option) => (dispatch, getState) => {
	dispatch(stopAutoSave());
	return dispatch(sendAnswer(param, true))
		.then(() => {
			const state = getState()
				, app = state.app
				, domain = state.domain
				, e = app.errand
				, { return_data } = e.sendAnswer.data
				, wf = app.workflow
				, ctx = contextMemo(state)
				, ce = e.currentErrand
				, areas = getAreasIDViaOrgObj(wf.agentAreas.data.areas)
				, ep = errandExternalParam(ce.id, false)
				, errand = domain[D_BASIC_ERRANDS].byId[ce.id]
				, pickUpNext = agentPickUpNext(getState())
				;
			dispatch(postReplyActions(option.reply));
			let qType = getExtQueueType();
			if(qType.length > 0 ){
				if (qType != SOLIDUS) {
					return dispatch(removeAgentWorkingOnOneErrand(ce.id));
				} else {
					window.location.href = "about:blank";
				}
			}
			dispatch(clearAnswerState());

			// TODO: only if reply answer need to refresh basic and acquire
			// errand
			dispatch(syncExtendedData(return_data.extended));
			dispatch(basicErrand(ce.id));
			dispatch(syncAcquireData(return_data.acquire));
			if(!pickUpNext) {
				dispatch(fetchHistory(ep, errand.threadId));
				const prefView = wf.fetchWfSettings.data.agentErrandListViewPref;
				if(prefView !== PREF_LIST_VIEW){
					if(!features["show-remain-errand-view-after-answer"]) {
						dispatch(closeErrandView());
					}
				}
			}
			if (externalqueue.telavox == true) {
				dispatch(signalViewErrandOnly(false));
			}
			dispatch(tryDoPickupNextWithoutCloseView());
		});
};

const pickupNextErrand = (notThisErrandId) => (dispatch, getState) => {
	return dispatch(loadList("pickupNextErrand"))
	.then(data => {
		// Pickup next worked on All Errands and My Errands
		let nextErrandId;
		const wf = getState().app.workflow, errandList = wf.errandList.data;
		if (errandList.order && errandList.order.length > 0) {
			nextErrandId = errandList.order[0];
		}
		if(nextErrandId == notThisErrandId &&
			errandList.order.length > 1){
			for(var i=1; i <errandList.order.length; i++){
				if(errandList.order[i] != notThisErrandId){
					nextErrandId = errandList.order[i];
					break;
				}
			}
		}
		return {nextErrandId};
	})
	.then(({nextErrandId}) => {
		if (nextErrandId > 0) {
			return dispatch(openNormalErrand(nextErrandId))
				.then(() => ({pickupNext: nextErrandId}));
		}
	});
}

const tryPickupNext = (notThisErrandId, currentContext) => (dispatch, getState) => {
	if(!IsContextSearch(currentContext)){
		if (agentPickUpNext(getState())) {
			return dispatch(pickupNextErrand(notThisErrandId));
		}
	}
	return Promise.resolve();
};

function returnNothing() {}

const tryPickupNextErrand = (
	notThisErrandId
	, forceClose
	, unselect
) => (dispatch, getState) => {
	const currentContext = contextMemo(getState());
	const wf = getState().app.workflow, prefView = wf.fetchWfSettings.data.agentErrandListViewPref;
	return dispatch(tryPickupNext(notThisErrandId, currentContext))
	.then(result => {
		if (result) {
			return result;
		}
		if (forceClose || agentPickUpNext(getState())) {
			let dispatchee;
			if (!unselect) {
				dispatchee = closeErrandView(true);
			} else {
				let unselectID;
				if (typeof unselect === "number") {
					unselectID = unselect;
				} else {
					unselectID = notThisErrandId
				}
				dispatchee = closeErrandViewAction(unselectID, true);
			}
			return dispatch(dispatchee).then(returnNothing);
		}
	})
	.then(result => {
		if (!result) {
			if(IsContextSearch(currentContext)){
				return dispatch(push(SEARCH));
			}
			if(prefView === PREF_LIST_VIEW){
				dispatch(closeErrandView(true));
			}
			return dispatch(loadList("tryPickupNextErrand"));
		}
	});
};

const tryDoPickupNextWithoutCloseView = (notThisErrandId) => tryPickupNextErrand(notThisErrandId, false, false);

// notThisErrandId: number
// unselect: number (errand ID to unselect) or bool
export const doPickupNext = (notThisErrandId, unselect) => tryPickupNextErrand(notThisErrandId, true, unselect);

function isInputTagsStillValid(inputTags, areaTags) {
	let valid = true;
	$.each(inputTags, (i,v) => {
		if(!isInsideAreaTags(areaTags, v)) {
			valid = false;
			return false;
		}
	});
	return valid;
}

const confirmIfNewAreDataEffectInputsTags = (whatChange, areaData, manual) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	let input = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
	if(!isInputTagsStillValid(getState().app.errand[input].tags,
		areaData.normal_tags)) {
		return dispatch(enableConfirm('area_change', I('The new selected area does not have access to all the chosen tags. If you proceed and choose the area, the non-available tags will be removed. Do you want to continue?')))
			.then(() => {
				whatChange.tag = true;
				return whatChange;
			});
	}
	return Promise.resolve(whatChange);
};

const areaHasChannel = (areaData, channel) => {
	const accounts = areaData[CH_AREA_LINK[channel]];
	if (accounts && accounts.length) {
		return true;
	}
	return false;
}

const confirmMutateAccountOrAddress = (whatChange, areaData) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	const inpt = (isCall ? getState().app.errand.manualCallInputs : getState().app.errand.manualInputs), c = inpt.reply_channel;
	if(c === RC_EMAIL || c === RC_SMS || !inpt.update_to.length &&
		inpt.selected_indexes.account == UNSELECT &&
		areaHasChannel(areaData, c)) {
		return Promise.resolve(whatChange);
	}
	return dispatch(enableConfirm('area_change', I('Selecting different area will wipe any selected account or filled address. Proceed?')))
		.then(() => {
			whatChange.manualAccountOrAddress = true;
			return whatChange;
		});
};

const dummyConfirm = whatChange => (dispatch, getState) => {
	if(true) { // check if need change or not
		return dispatch(enableConfirm('area_change', I('Are you sure you want to change?')))
			.then(() => {
				whatChange.dummy = true;
				return whatChange;
			});
	}
	return Promise.resolve(whatChange);
};

const areaDataChange = (areaData, areaId, manual) => (dispatch, getState) => {
	let isCall = isCallMemoize(getState());
	dispatch(confirmIfNewAreDataEffectInputsTags({}, areaData, manual))
		.then(changes => {
			// other checking - follow the same pattern:
			// return dispatch(funcThatCheckChangeAndReturnPromiseThroughThunk)
			// the func take in whatChange and update it inside `then` func.
			if(manual) {
				return dispatch(confirmMutateAccountOrAddress(changes,
					areaData));
			}
			return changes;
		})
		// other area change data that effect on user already inputs data should
		// do the checking promise.then and use the enable confirm and return
		// dispatch of the enable confirm action.
		.then(changes => {
			// before change the front-end data, save the errand area at
			// backend.
			const state = getState();
			if (manual) {
				// assume manual do not has any backend errand first.
				if (isCall) {
					// always reset selected phone id as different area may not
					// carry the same phone.
					dispatch(resetOutboundPhoneId());
				}
				return changes;
			}
			let e = state.app.errand
			, errandId = e.currentErrand.id
			, cipherKey = e.basic.data.data.cipherKey;
			return dispatch(changeErrandArea(areaId, errandId, cipherKey))
				.then(() => {
					return changes;
				});
		})
		.then(changes => {
			// here user had click all yes button to confirm wanna change area.
			// console.log('dbg: what need change when area change:', changes);
			if(changes.tag) {
				// action to make changes to the inputs tags data.
				dispatch(updateInputTags(areaData.normal_tags, manual, isCall));
			}
			if(changes.manualAccountOrAddress) {
				dispatch(defaultManualAccountAndAddress());
				dispatch(selectReplyChannel(RC_EMAIL, true));
			}
			dispatch(defaultPersonalization(areaData, manual));
			dispatch(areaOperation('change', areaId, manual, isCall));
			let kbParam={"loadFirst":true, "hideTimeControl":true}
			dispatch(loadKnowledgeBase(areaData.library, kbParam,true));
			if (!manual) {
				dispatch(domainTransfer(XFER_CHANGED_DOMAIN_AREA_DATA
					, {domain: areaData, id: areaId}
				));
			}
		})
		.catch(e => {
			if(e instanceof Error) {
				console.log('dbg: JS error:', {e});
			}
		});
};

const defaultPersonalization = (area, manual) => (dispatch, getState) => {
	const e = getState().app.errand,
		ext = e.fetchExtendedData.data,
		currentReply = e.ui.manual.currentReply;

	let reply = (manual ? RPLY_MANUAL : RPLY_ERRAND);
	let sl = area.area_salutation_pref;
	if(!area.area_admin_tick_sal){
		if(area.user_salutation_pref > 0){
			sl = area.user_salutation_pref;
		}
	}
	let sg = area.area_signature_pref;
	if(!area.area_admin_tick_sign){
		if(area.user_signature_pref > 0){
			sg = area.user_signature_pref;
		}
	}
	if(!manual && ext && ext.data){
		if(ext.data.answer_salutation > 0){
			sl = ext.data.answer_salutation;
		}
		if(ext.data.answer_signature > 0){
			sg = ext.data.answer_signature;
		}
	}
	if(reply == RPLY_MANUAL && currentReply == ME_START){
		dispatch(setPersonalizationDefault("update_signature_default", true, reply));
		dispatch(setPersonalizationDefault("update_salutation_default", true, reply));
	}
	dispatch(setPersonalization("update_signature", sg, reply));
	dispatch(setPersonalization("update_salutation", sl, reply));
};

export const changeArea = (value, manual) => (dispatch, getState) => {
	// switch area is expensive, make sure area is not same then only switch.
	const isCall = isCallMemoize(getState());
	let inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
	if(getState().app.errand[inpt].area === value) {
		return $.when();
	}
	const a = getNormalizedDomain(getState().domain, D_AREAS, value);
	if(!a) {
		dispatch(togglePopWaiting(I('Loading area data...')));
		return dispatch(getAreaData(value))
			.then(() => {
				return dispatch(clearPopWaiting());
			})
			.then(() => {
				const a = getNormalizedDomain(getState().domain, D_AREAS,
					value);
				dispatch(areaDataChange(a, value, manual));
			})
			.catch(() => {
				dispatch(clearPopWaiting());
			});
	}
	dispatch(areaDataChange(a, value, manual));
	return $.when();
};

export const changeErrandInternalState = (id, promptAgent, name) => (dispatch, getState) => {
	let errandData = getState().app.errand.basic.data;
	if (errandData !== null && errandData.acquired && errandData.data !== null
		&& errandData.data.state !== id){
		let p = {errandId: errandData.id, state: id, name: name};
		dispatch(togglePopWaiting(I('updating state...')));
		return dispatch(async(postErrandChangeInternalState(p),
			errand[keyErrandChangeInternalState]))
			.then((retval) => {
				dispatch(clearPopWaiting());
				if(typeof retval.state !== 'undefined' &&
						retval.state === id && promptAgent == true) {
					if(window.confirm(I("Notify sender of state change?"))){
						dispatch(postErrandSendInternalStateChange(p));
					}
				}
			})
			.catch(() => {
				dispatch(clearPopWaiting());
			});
	}
};

export const moveErrandsToFolder = (ids, cipherKeys, folderId, param) => (dispatch, getState) => {
	let parameters;
	if (param) {
		parameters = param;
	} else {
		parameters = {};
	}
	parameters.list = ids;
	parameters.cipher_keys = cipherKeys
	parameters.folder = folderId;
	parameters.force = true;
	parameters.openedByExternal = getState().external.openedByExternal;
	return dispatch(moveToFolderWithoutLoadList(parameters));
};

const saveErrandIfNeeded = () => (dispatch, getState) => {
	const needFullSave = checkAutoSave(getState());
	if (!needFullSave || !needFullSave.shouldSaveErrand) {
		return Promise.resolve();
	}
	return dispatch(hardSaveErrand());
}

export const moveErrandToFolder = (id, folderId) => dispatch =>
	dispatch(saveErrandIfNeeded())
	.then(() => dispatch(moveErrandToFolderWithoutSave(id, folderId)));

const moveErrandToFolderWithoutSave = (id, folderId) => (dispatch, getState) => {
	const state = getState();
	return dispatch(moveErrandsToFolder(
			getGroupedErrandListString(state)
			, "" // not applicable
			, folderId
			, createUpdateErrandObject(null, DEF_OPT, state)
		))
		.then(() => {
			// TODO: do we allow to reselect back current errand if agent
			// mistakenly move the errand back to my errand when the errand
			// already in my errand.
			dispatch(doPickupNext(0, id));
		});
};

const getToastType = (status) => {
	let toastType = TOAST_TYPE.danger;
	switch (status) {
		case "success":
			toastType = TOAST_TYPE.success
			break;
		case "warning":
			toastType = TOAST_TYPE.warning
			break;
		default:
			break;
	}
	return toastType;
}

export const linkErrand = (ids) => (dispatch,getState) => {
	let parameters = {};
	parameters.type = "link"
	parameters.errands = ids;
	return dispatch(linkErrandAsync(parameters))
	.then(() => {
		const state = getState()
			, {
				message,
				linkedStatus,
				error
			} = state.app.workflow.linkErrand.data
		;
		let toastType = getToastType(linkedStatus)
		if (error){
			return dispatch(togglePopAlert("Error while link errands. "+ error));
		}

		// return dispatch(togglePopAlert(message));
		return dispatch(toggleToastAlert(toastType, TOAST_POSITION.topRight, message));

	})
	.catch(() => {});
} ;

export const acquireLinkErrand = (ids, currentErrand, type, allSelected) => (dispatch,getState) => {
	let parameters = {};
	parameters.type = type
	parameters.errands = ids.toString();
	parameters.currentErrand = currentErrand ;
	if (allSelected) {
		parameters.allSelected = "true" ;
	} else {
		parameters.allSelected = "false" ;
	}
	return dispatch(linkErrandAsync(parameters))
	.then(() => {
		const state = getState()
			, {
				message,
				error,
				linkedEidList,
				linkedStatus,
				linkedId,
				linkedErrands
			} = state.app.workflow.linkErrand.data
			;
		let toastType = getToastType(linkedStatus)
		if (error){
			return dispatch(togglePopAlert("Error while " + type + " errands. "+ error));
		}

		// dispatch(FetchLinkedErrandsAsync(currentErrand,0)) ;
		if (type == "link" && linkedEidList ) {
			if (linkedEidList.length> 0) {
				dispatch(moveLinkedToAssociate(linkedEidList, false, false))
			}
		}
		return dispatch(toggleToastAlert(toastType, TOAST_POSITION.topRight, message));
		// return dispatch(togglePopAlert(message));
	})
	.catch(() => {});
} ;

export const putBackToInbox = (errandId, multiple) => dispatch =>
	dispatch(saveErrandIfNeeded())
	.then(() => dispatch(putBackToInboxWithoutSave(errandId, multiple)));

const putBackToInboxWithoutSave = (errandId, multiple) => (dispatch, getState) => {
	const state = getState();
	let parameters = {
		openedByExternal: state.external.openedByExternal || (getExtQueueType() != "")
	};
	let qType = getExtQueueType();
	if (multiple) {
		const ids = getSelectedErrandWithCipherKey(state);
		parameters.list = getArrayOf(ids, "id").join(",");
		parameters.cipher_keys = getArrayOf(ids, "cipherKey").join(",");
		parameters.update_id = 0;
		parameters.update_cipher_key = "";
	} else {
		let currentErrand = getDomainBasicErrands(state).byId[errandId];
		let associatedList = getGroupedErrandIDsWithCipherKey(state);
		parameters.list = getArrayOf(associatedList, "id").join(",");
		parameters.cipher_keys = getArrayOf(associatedList, "cipherKey").join(",");
		parameters.update_id = errandId;
		parameters.update_cipher_key = currentErrand.data.cipherKey;
	}
	if (externalqueue.solidus == true || externalqueue.puzzel == true ||
		externalqueue.clearinteract == true || externalqueue.enghouse == true ||
		externalqueue.zisson == true) {
		if(state.app.errand.ui.navBtnBusy == true ){
			return;
		}
		dispatch(setNavButtonBusy(true));
	}
	return dispatch(returnToInbox(parameters))
		.then(() => {
			dispatch(setNavButtonBusy(false));
			if (!multiple) {
				if(qType.length > 0 ){
					if (qType != SOLIDUS) {
						try {
							window.close();
						} catch (e) {
							console.log("dbg: unable to close browser window: ", e);
						}
					}
					dispatch(setNavButtonBusy(false));
					window.location.href = "about:blank";
				}
				return;
			}
			if(IsContextSearch(contextMemo(getState()))){
				dispatch(showMultipleActions(false));
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			} else {
				dispatch(loadList("putBackToInboxWithoutSave"));
			}
		})
		.then(() => {
			if (!multiple) {
				if (externalqueue.telavox == true) {
					dispatch(signalViewErrandOnly(false));
				}
				dispatch(doPickupNext(errandId, true));
			}
		});
};

export const queueToMe = (errandIds) => (dispatch, getState) => {
	const state = getState();
	if(typeof externalqueue !== "undefined" && externalqueue.isExternal ==
		true && externalqueue.solidus == true){
		let parameters = {openedByExternal: true};
		parameters.list = errandIds.join(',');
		return dispatch(doQueueToMe(parameters))
			.then(()=>{
				if (qType != SOLIDUS) {
					try {
						window.close();
					} catch (e) {
						console.log("dbg: unable to close browser window: ", e);
					}
				}
				window.location.href = "about:blank";
			});
	}
};

export const handleReopenErrand = (errandId, cipherKey, channel) => (dispatch, getState) => {
	let parameters = {};
	parameters.update_id = errandId;
	parameters.update_cipher_key = cipherKey;
	if (channel) {
		parameters.update_channel = channel;
	}
	dispatch(reopenErrand(parameters))
	.then(()=>{
		let state = getState();
		let reopenResult = state.app.errand.reopenErrand;
		let data = reopenResult.data;
		if(data.error){
			alert("Error :", data.error);
		}else{
			let newErrandId = data.id;
			dispatch(openNormalErrand(newErrandId, false));
		}
	})
	.catch(err => {
		console.log('dbg: error while reopen errand', err);
	});
}

export const resendErrand = (currentReply) => (dispatch, getState) => {
	let option = { all: "",reply: currentReply, skipSubject:"", previous:"" };
	let parameters = createUpdateErrandObject(null, option, getState());
	dispatch(resendAnswer(parameters))
	.then((rs) =>{
		let state = getState();
		let resendResult = state.app.errand.resendAnswer;
		let data = resendResult.data;
		if(data.error){
			alert("Error :", data.error);
		}else{
			dispatch(push(SEARCH));
		}
	})
	.catch(err => {
		console.log("dbg: error while resend errand", err);
	});
}

export const forwardErrandToArea = (errandID, areaID) => dispatch =>
	dispatch(saveErrandIfNeeded())
	.then(() => dispatch(forwardErrandToAreaWithoutSave(errandID, areaID)));

const forwardErrandToAreaWithoutSave = (errandID, areaID) => forwardErrandsToArea(errandID, "", areaID, false);

const areaNotification = aId => async(postAreaNotification({area: aId}), errand[keyGetAreaNotification]);

const forwardAgentConfirmMessage = I("Are you sure you want to forward the errand to the agent?")
	, forwardAgentReplyToEmptyMessage = I("Are you sure you want to forward the errand without selecting a reply-to address?")
	;
const checkForwardAgentEmptyReplyTo = (param, skip) => dispatch => {
	if (skip || param.update_to) {
		return Promise.resolve();
	}
	return dispatch(enableConfirm(
			ALRT_CFRM_OPR_OPTIONAL
			, forwardAgentReplyToEmptyMessage
			, {param}
		));
};

const confirmBeforeForward = (param, wfSettings, skipEmptyAddr) => dispatch => {
	if (!wfSettings["mustConfirmMoveErrand"]) {
		return Promise.resolve();
	}
	return dispatch(checkForwardAgentEmptyReplyTo(param, skipEmptyAddr))
		.then(() => dispatch(enableConfirm(
			ALRT_CFRM_OPR_OPTIONAL
			, forwardAgentConfirmMessage
			, {param}
		)));
};

function isMultipleErrands(errandIds) {
	return !!errandIds;
}

function createParameterForwardToAgent(state, agentId, list, cipher_keys,
	extRefId) {
	let parameters;
	if (!isMultipleErrands(list)) {
		const associatedList = getGroupedErrandIDsWithCipherKey(state);
		parameters = createUpdateErrandObject(null, DEF_OPT, state);
		parameters.list = getArrayOf(associatedList, "id").join(",");
		parameters.cipher_keys = getArrayOf(associatedList, "cipherKey").join(",");
		parameters.extRefId = extRefId;
		parameters.update_salutation = 0;
		parameters.update_signature = 0;
	} else {
		parameters = {list, cipher_keys};
	}
	parameters.agent = agentId;
	parameters.openedByExternal = checkOpenedByExternal(state);
	return parameters;
}

const waitForwardingErrandsAction = I("forwarding errand(s) in progress");

export const forwardManualSipToAgent = (eid, targetAgentId, mCipherKey,
	extRefId) => (dispatch, getState) => {
	const state = getState();
	let parameters = {};
	parameters.list = eid;
	parameters.agent = targetAgentId;
	parameters.update_id = eid;
	parameters.update_cipher_key = mCipherKey;
	console.log("dbg: Forwarding created call errand ", eid);
	parameters.extRefId = extRefId;
	return dispatch(forwardToAgent(parameters));
};

const forwardErrandsToAgentBase = (agentId, noAsk, list, extRefId, ...args) => (dispatch, getState) => {
	const state = getState()
		, parameters = createParameterForwardToAgent(
			state
			, agentId
			, list
			, extRefId
			, ...args
		)
		, multiple = isMultipleErrands(list)
		, wfSettings = state.app.workflow.fetchWfSettings.data
		;
	if(typeof noAsk !== 'undefined' && noAsk == true){
		return dispatch(forwardToAgent(parameters));
	}
	console.log("dbg: action forward errand(s):", {args, parameters});
	return dispatch(confirmBeforeForward(
			parameters
			, wfSettings
			, multiple
		))
		.then(res => {
			if (multiple) {
				dispatch(popPleaseWait(waitForwardingErrandsAction));
			}
			return res;
		})
		.then(() => dispatch(forwardToAgent(parameters)));
};

export const forwardMultipleErrandsToAgent = (
	agentId
	, errandIds
	, cipherKeys
) => (dispatch, getState) => dispatch(forwardErrandsToAgentBase(
		agentId
		, false
		, errandIds
		, cipherKeys
	))
	.then(() => {
		const state = getState()
			, {
				error
				, success
				, message
				, failed
			} = state.app.workflow.forwardToAgent.data
			;
		let text;
		if (error) {
			text = error;
		} else if (!success && message) {
			text = message;
		} else {
			text = message;
			if (failed) {
				const texts = [];
				$.each(failed, (errandId, errorMessage) => {
					texts.push(`[${errandId}: ${errorMessage}]`);
				});
				text += ". " + I("Forward to agent - failed errands:") +
					texts.join(", ");
			}
		}
		if(IsContextSearch(contextMemo(state))) {
			// dispatch(selectAllErrandsSearchResult(false));
			dispatch(showMultipleActions(false));
			dispatch(handleProcessing(true));
			dispatch(handleResetOffset());
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		} else {
			dispatch(selectAllErrands("", false));
			dispatch(loadList("forwardMultipleErrandsToAgent"));
		}
		return dispatch(togglePopAlert(text));
		// TODO: wait for loadList before present result as popup as below:
		// return dispatch(loadList()).then(() => dispatch(togglePopAlert(text)));
	})
	.catch(() => {});

export const forwardErrandToAgent = (errandId, agentId, noAsk, refId) => dispatch =>
	dispatch(saveErrandIfNeeded())
	.then(() => dispatch(forwardErrandToAgentWithoutSave(errandId, agentId,
		noAsk, refId)));

const forwardErrandToAgentWithoutSave = (errandId, agentId, noAsk, refId) => dispatch =>
	dispatch(forwardErrandsToAgentBase(agentId, noAsk, null,null, refId))
		.then(() => {
			let qType = getExtQueueType();
			if (qType.length > 0) {
				if (qType != SOLIDUS) {
					try {
						window.close();
					} catch (e) {
						console.log("dbg: unable to close browser window: ", e);
					}
				}
				window.location.href = "about:blank";
			}
			if (externalqueue.telavox == true) {
				dispatch(signalViewErrandOnly(false));
			}
			dispatch(doPickupNext());
		})
		.catch(err => {
			if (err instanceof Error) {
				console.trace &&
					console.trace("trace forward to agent error: " + err);
			}
		});

const doErrandPrint = (errandId, withHistory) => (dispatch, getState) => {
	if(withHistory){
		let errandIds = getErrandHistoryIdsSelector(getState());
		return dispatch(errandPrint(errandIds));
	}else{
		return dispatch(errandPrint(errandId));
	}
}

export const fetchPrintErrand = (errandId, viewAction, withHistory) => (dispatch, getState) => {
	if(!viewAction) {
		return dispatch(printErrandAction(viewAction));
	}
	dispatch(doErrandPrint(errandId, withHistory))
	.then( (data) =>{
		dispatch(printErrandAction(viewAction));
		dispatch(printErrands(errandId));
	});
}

export const doDeleteErrand = (id, multiple) => dispatch =>
	dispatch(saveErrandIfNeeded())
	.then(() => dispatch(doDeleteErrandWithoutSave(id, multiple)));

const deleteConfirmMsg = I("Are you sure you want to delete the selected errand?");

const doDeleteErrandWithoutSave = (id, multiple) => (dispatch, getState) => {
	const state = getState()
		, openedByExternal = (state.external.openedByExternal ||
			(getExtQueueType() != ""))
		, option = createOption(multiple)
		;
	let deleteFail = false;
	return Promise.resolve()
		.then(() => {
			if (!multiple) {
				return {param: {}, option};
			}
			return dispatch(fetchAreaTagsWithCommaSeperatorAreaIds(getSelectedAreas(state)))
				.then(() => {
					const ids = getSelectedErrandWithCipherKey(state);
					// TODO: properly initiate errands and tags, include already
					// tagged
					option.tags = formInitErrandsTags(getArrayOf(ids, "id"));
					return {
						param: {}
						, option
						, ids
					};
				});
		})
		.then(result => {
			if (!state.app.workflow.fetchWfSettings.data["mustConfirmDelete"]) {
				return result;
			}
			return dispatch(optionalConfirm(deleteConfirmMsg))
				.then(() => result);
		})
		.then(({param, option, ids}) => dispatch(askForClassification(
			param
			, update(option, {ids: {$set: ids}})
			, true
		)))
		.then(({param, option}) => {
			const { multiple, ids } = option
				, updateParam = {openedByExternal}
				;
			let tags, list;
			if (!multiple) {
				let e = getAppErrand(state)
				if(typeof e.inputs !== 'undefined' &&
						e.inputs.update_subject.length > 0 ){
					updateParam.update_subject = e.inputs.update_subject;
				}
				updateParam.delete_tag = param.update_tags;
				updateParam.update_id = id;
				if (hasAssociatedList(state)) {
					let associatedList = getGroupedErrandIDsWithCipherKey(state);
					updateParam.list = getArrayOf(associatedList, "id").join(",");
					updateParam.cipher_keys = getArrayOf(associatedList, "cipherKey").join(",");
				} else {
					let currentErrand = getDomainBasicErrands(state).byId[id];
					updateParam.list = id + ""; // for it to be string
					updateParam.cipher_keys = currentErrand.data.cipherKey;
				}
				param = update(param, {
					$merge: updateParam
					, $unset: ["update_tags"]
				});
			} else {
				updateParam.list = getArrayOf(ids, "id").join(",");
				updateParam.cipher_keys = getArrayOf(ids, "cipherKey").join(",");
				param = update(param, {$merge: updateParam});
			}
			return dispatch(deleteErrands(param))
				.then(result => {
					const { wip, err } = getState().app.workflow.deleteErrands;
					// TODO: wip should always false by the time async then return.
					// Checking wip is redundant and err might always null too for then.
					// 'async'.err only deal with network between browser and server
					// error and should appear in catch by right. Any business domain
					// error (Cention error) is not handled.
					if(wip == false && typeof err !== 'undefined' &&
						err != null){
						deleteFail = true;
						return dispatch(togglePopAlert(err.message));
					}
					return multiple
				});
		})
		.then(() => {
			if(deleteFail == true){
				return
			}
			if (multiple) {
				// FIXME: Should separate tagging for diff areas (next tag just
				// like threaded errands);
				if(IsContextSearch(contextMemo(getState()))){
					dispatch(showMultipleActions(false));
					dispatch(handleProcessing(true));
					dispatch(handleResetOffset());
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
				} else {
					dispatch(loadList("doDeleteErrandWithoutSave"));
				}
			} else {
				let qType = getExtQueueType();
				if(qType.length > 0 ){
					dispatch(closeErrandView(true))
					.then(()=> {
						if (qType != SOLIDUS) {
							try {
								window.close();
							} catch (e) {
								console.log("dbg: unable to close browser window: ", e);
							}
						}
						window.location.href = "about:blank";
					});
				}
				if (externalqueue.telavox == true) {
					dispatch(signalViewErrandOnly(false));
				}
				dispatch(doPickupNext());
			}
		})
		.catch(err => {
			if (err instanceof Error) {
				console.trace &&
					console.trace("error in delete errand(s) action: " + err);
			} else {
				console.log("user cancel to add classification", err);
			}
		});
};

function createOption(multiple) {
	if (!multiple) {
		return update(DEF_OPT, {});
	}
	return {multiple: true};
}

// NOTE: only trigger this once and never trigger this inside loop as there is
// loadList inside it if multiple is true.
const triggerClose = (ids, cipherKeys, param, multiple) => (dispatch, getState) => {
	if (multiple) {
		return dispatch(closeErrands(Object.assign({}, {list: ids, cipher_keys: cipherKeys}, param)))
			.then(data => {
				const state = getState()
					, { wip, err } = state.app.workflow.closeErrands
					;
				// TODO: wip should always false by the time async then return.
				// Checking wip is redundant and err might always null too for then.
				// 'async'.err only deal with network between browser and server
				// error and should appear in catch by right. Any business domain
				// error (Cention error) is not handled.
				if(wip == false && typeof err !== 'undefined' && err != null){
					dispatch(togglePopAlert(err.message));
				}
				if(IsContextSearch(contextMemo(state))){
					dispatch(showMultipleActions(false));
					dispatch(handleProcessing(true));
					dispatch(handleResetOffset());
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
				} else {
					dispatch(loadList("triggerClose"));
				}
				return data;
			})
			.catch(err => {
				console.log('dbg: error while close errands', err);
			});
	} else {
		return dispatch(closeErrand(param))
			.then(data => data)
			.catch(err => {
				console.log('dbg: error while close single errand', err);
			});
	}
};

const doNothing = () => {};

// arrayIDs: array of integer errand IDs
// param: object with fields tag_XXXX where XXXX is the errand ID of arrayIDs.
const closeMultipleErrands = (arrayIDs, cipherKeys, param) => {
	if (!arrayIDs || !arrayIDs.length) {
		console.log && console.log("dbg: empty errand for closing");
		return doNothing;
	}
	return triggerClose(arrayIDs.join(","), cipherKeys.join(","), param, true);
}

const initStepCloseErrand = (id, multiple) => (dispatch, getState) => {
	const state = getState()
		, ext = state.external.openedByExternal
		, manualBasicCall = (state.app.workflow.ui.showManualCall === MP_BASIC_CALL || state.app.workflow.ui.showManualCall === MP_MINIMIZE)
			&& !sipMakeCallCurrentErrand(state)
		;
	let param = {}
		, chat
		, option = createOption(multiple)
		;
	if (!multiple) {
		chat = state.app.errand.chat;
		if (chat) {
			const tags = flattenTagLevels(getChatTagLevels(chat));
			if (tags) {
				// Previously this seem like redundant as it may trigger
				// unnecessary websocket close errand. Any updated tags BEFORE
				// classification is real time with backend websocket. No need
				// trigger websocket close if already tagged. NOW this part is
				// needed as indication current live chat no need tagging when
				// preparing tagging of closing chat with associated errand
				// list.
				param.update_tags = tags.join(',');
			}
			option = update(option, {chat: {$set: chat}});
		} else {
			if(manualBasicCall) {
				let newErrandId = state.app.errand.ui.manualCall.newErrandId;
				option = update(option, {manual: {$set: true}, isCall: {$set: true} ,isSip: {$set: true}, newId: {$set: newErrandId}});
			}
			param = createUpdateErrandObject(null, option, state);
			if (!param.update_to) {
				const confirmMsg = I("Are you sure you wish to close the errand without selecting a reply-to address?");
				return dispatch(enableConfirm(
						ALRT_CFRM_OPR_OPTIONAL
						, confirmMsg
						, {param, option}
					))
					.then(() => ({param, option}));
			}
		}
	} else {
		// TODO: we may wanna to wait for this action to finish before continue
		// classification but we should use cache-able action for fetch area tags
		// before doing this.

		//FIXME: No need to fetchAreaTags when force tag feature is disabled
		//hence no tag popup, needed review
		return dispatch(fetchAreaTagsWithCommaSeperatorAreaIds(getSelectedAreas(state)))
		.then(() => {
			return Promise.resolve({param, option});
		});
	}
	option.isClose = true;
	return Promise.resolve({param, option});
};

const fetchAreaTagsWithCommaSeperatorAreaIds = areas => fetchAreaTags({areas});

const triggerCreateErrand = (sessionId) => dispatch => {
    return new Promise((resolve, reject) => {

        AgentSocket.SendEvent(
            evt_CREATE_ERRAND,
            {
                sessionId: sessionId,
            },
            ack => {
                if (ack && ack.error) {
                    reject({ error: ack.error });
                    return;
                }
                resolve({ sessionId });
            }
        );
    })

    .catch(err => {
        const msg = err.error || "Failed to create errand.";
        dispatch(togglePopAlert(msg));
    });
};
const tagClosingChat = (chat, tags) => dispatch =>
	new Promise((resolve, reject) => {
		if (chat.Role == CHAT_crOwner && tags) {
			AgentSocket.SendEvent(
				evtCHAT_SET_TAGS
				, {sessionId: chat.sessionId, tags: tags}
				, ack => {
					if (ack.error) {
						reject({
							error: ack.error
							, id: chat.errand.data.id
							, setTag: true
						});
					} else {
						dispatch({
							type: CHAT_UPDATE_TAGS
							, chat
							, Tags: ack.Tags
						});
						resolve({chat});
					}
				}
			);
		} else {
			resolve({chat});
		}
	});

const chatSetTagErrMsg = I("{CHAT_ID} An error occurred when adding the tag. Please contact support. {ERROR}")
	, closeChatErrMsg = I("{CHAT_ID} An error occurred when closing chat. Please contact support. {ERROR}")
	;

const triggerCloseChat = (chat, tags, associatedErrandData) => dispatch =>
	dispatch(tagClosingChat(chat, tags))
		.then(({ chat }) => new Promise((resolve, reject) => {
			AgentSocket.SendEvent(
				evtCHAT_FINISH_SESSION
				, {sessionId: chat.sessionId, summary: chat.summary}
				, ack => {
					// TODO: backend chat server actually never return ack for
					// finish session event - ack always undefined.
					if (ack && ack.error) {
						reject({error: ack.error, id: chat.errand.data.id});
						return;
					}
					resolve({chat});
				}
			);
		}))
		.then(result => {
			if (associatedErrandData) {
				// handling closing associated errands.
				const { list, cipher_keys, param } = associatedErrandData;
				return dispatch(closeMultipleErrands(list, cipher_keys, param));
			}
			return result;
		})
		.catch(res => {
			if (res) {
				const { error, id, setTag } = res
					;
				let msg
					;
				if (setTag) {
					msg = chatSetTagErrMsg;
				} else {
					msg = closeChatErrMsg;
				}
				msg = msg
					.replace('{CHAT_ID}', 'CHAT#'+id)
					.replace('{ERROR}', error)
					;
				return dispatch(togglePopAlert(msg))
					.then(() => ({chat}));
			}
		});

const closeSingleErrandAfterTagging = (
	id
	, param
	, option
) => dispatch => {
	const { chat, noNeedTag, chatAssociatedErrands, chatAssociatedErrandCipherKeys } = option
		;
	if (chat) {
		let tags
			, associatedErrands
			;
		if (!noNeedTag) {
			const { update_tags } = param;
			if (chat.Role == CHAT_crOwner && update_tags) {
				tags = $.map(update_tags.split(/,/g), str2Int);
			}
		}
		if (chatAssociatedErrands && chatAssociatedErrands.length) {
			param = update(param, {$unset: ["update_tags"]});
			associatedErrands = {list: chatAssociatedErrands, cipher_keys: chatAssociatedErrandCipherKeys, param}
		}
		return dispatch(triggerCloseChat(chat, tags, associatedErrands));
	} else {
		return dispatch(triggerClose(id, param.update_cipher_key, param,
			false));
	}
};

const closeErrandsAfterTagging = (param, { multiSelects }) => dispatch => {
	const { chatErrandIds, errandIds } = multiSelects;
	let dispatches = [];
	if (chatErrandIds.length > 0) {
		$.each(chatErrandIds, (i,v) => {
			const chat = v.chat
				, paramTags = param["tags_"+chat.errand.data.id]
				;
			let tags = [];
			if (paramTags) {
				tags = $.map(paramTags.split(/,/g), str2Int);
			} else {
				let tagLevel = getChatTagLevels(chat);
				tags = flattenTagLevels(tagLevel);
			}
			dispatches.push(dispatch(triggerCloseChat(chat, tags)));
		});
	}
	if (errandIds.length > 0) {
		const eids = getArrayOf(errandIds, "id")
			, id = eids.join(",")
			, cipherKeys = getArrayOf(errandIds, "cipherKey").join(",")
			;
		dispatches.push(dispatch(triggerClose(id, cipherKeys, param, true)));
	}
	return $.when(...dispatches);
};

const getSelections = (args, multiple, state) => {
	if (!multiple) {
		return args;
	}
	const { param, option } = args
		, chatErrandIds = getSelectedChatErrandsWithArea(state)
		, errandIds = getSelectedAllErrandsWithArea(state)
		;
	return {
		param
		, option: update(option, {multiSelects: {$set: {errandIds, chatErrandIds}}})
	};
};
export const doCreateErrand =(id) => dispatch =>
	dispatch(triggerCreateErrand(id));
export const doCloseErrand = (id, multiple, isForceAuto, extRefId) => dispatch =>
	dispatch(saveErrandIfNeeded())
	.then(() => dispatch(doCloseErrandWithoutSave(id, multiple, isForceAuto,
		extRefId)));

export const doCloseErrandWithoutSave = (id, multiple, isForceAuto,
		extRefId) => (dispatch, getState) => dispatch(initStepCloseErrand(id,
			multiple))
	.then(args => getSelections(args, multiple, getState()))
	.then(({ param, option }) => dispatch(askForClassification(
		param
		, option
		, false
		, option.chat
		, isForceAuto
	)))
	.then(({ param, option }) => {
		if (!multiple) {
			if(isForceAuto != null && isForceAuto){
				param.forceAutoClose = true;
			}
			param.extRefId = extRefId;
			return dispatch(closeSingleErrandAfterTagging(
				id
				, param
				, option
			));
		} else {
			return dispatch(closeErrandsAfterTagging(param, option));
		}
	})
	.then(result => {
		if (!multiple) {
			if(typeof externalqueue !== "undefined" &&
				(externalqueue.isExternal == true ||
				externalqueue.telavox == true)){
				if(externalqueue.isChat == true){
					if(getState().app.workflow.errandListChat.length <= 1){
						dispatch(chatCheckCanClose(getExtQueueType()));
					}
				} else {
					let qType = getExtQueueType();
					if(qType.length > 0 ){
						dispatch(closeErrandView(true))
						.then(()=> {
							if (qType != SOLIDUS) {
								try {
									window.close();
								} catch (e) {
									console.log("dbg: unable to close browser window: ", e);
								}
							}
							window.location.href = "about:blank";
						});
					}
					if (externalqueue.telavox == true) {
						dispatch(signalViewErrandOnly(false));
					}
				}
			}
			return dispatch(doPickupNext())
				.catch(err => {
					console.log("dbg: error trigger pickup next", err);
				});
		}
	})
	.catch(err => {
		if (err instanceof Error) {
			console.trace &&
				console.trace("close errand(s) error: " + err);
		} else {
			console.log &&
				console.log("dbg: user cancel close errand(s) action", err);
		}
	});

function getPreviewURL(id, previewType) {
	return centionWebURLWithIdAndQuery(ErrandEmailPreview, id);
}

function getPreviewManualURL(){
	return generateCentionWebURL(ErrandEmailManualPreview);
}

function getSaveAsEmlURL(id, previewType) {
	return centionWebURLWithIdAndQuery(ErrandEmailDownload, id);
}

function openPreviewWindow(id, previewType) {
	return new Promise((resolve, reject) => {
		const res = window.open(getPreviewURL(id, previewType), '_blank');
		if (!res) {
			reject({err: "open window return " + res});
		} else {
			resolve();
		}
	});
}

export const previewEmail = (previewType, option) => (dispatch, getState) => {
	console.log('dbg: preview button click', {type: previewType});
	return openPreviewWindow(getCurrentErrand(getState()), previewType);
};

function getIdAndParamForEmailProcess(state, email_type, option) {
	const param = {email_type};
	// console.log('dbg: preview with post param', param);
	createUpdateErrandObject(param, option, state);
	return [param, getCurrentErrand(state)];
}

const previewEmailWithPostBase = (emailType, option, samePage) => (dispatch, getState) => {
	const [ params, id ] = getIdAndParamForEmailProcess(
			getState()
			, emailType
			, option
		)
		, url = getPreviewURL(id)
		;
	dispatch(checkTranslate(params, {all: false}, false, false))
		.then((tparams,option) => {
		let outparam = tparams.param;
		if(params.update_answer != outparam.update_answer){
			params.translated_answer = outparam.update_answer;
		}
		if (samePage) {
			return dispatch(preparePostLink(PLF_PREVIEW, {url,
				params}));
		} else {
			return openPreviewWindowWithPost(url, params);
		}
		});
};

const previewManualEmailWithPostBase = (params, samePage) => (dispatch, getState) => {
	const url = getPreviewManualURL();
	if (samePage) {
		return dispatch(preparePostLink(PLF_PREVIEW, {url, params}));
	}
	return openPreviewWindowWithPost(url, params);
};

const previewEmailOnNewPage = (emailType, option) => previewEmailWithPostBase(emailType, option, false);

const previewEmailOnSamePage = (emailType, option) => dispatch => {
	dispatch(previewEmailWithPostBase(emailType, option, true));
	dispatch(showPostPreview());
};

const previewManualErrandEmailOnSamePage = param => dispatch => {
	dispatch(previewManualEmailWithPostBase(param, true));
	dispatch(showPostPreview());
};

export const previewEmailWithPost = (emailType, option) => (dispatch,
	getState)=> {
	dispatch(setClearPreivewOrSaveEmlBusy(true));
	dispatch(popWaitingWithoutCatch(I("Preparing for preview...")));
	return dispatch(rawHardSaveErrand())
		.then(() => {
			dispatch(checkManualErrandPreview({manual: false}))
			.then(() => {
				dispatch(clearPopWaiting());
				return dispatch(previewEmailOnSamePage(emailType, option));
			})
		})
		.catch(err => {
			if (!err) {
				err = I("Error when preparing errand for preview.");
			}
			return dispatch(togglePopAlert(err));
		})
		.catch(DummyFunction)
		.then(() => dispatch(setClearPreivewOrSaveEmlBusy()));
};

export const previewManualErrandEmailWithPost = (emailType, type, createType) => (dispatch, getState) => {
	let manualCreate, manualCreateType, manualStart;
	if(type === ME_START) {
		manualStart = true;
	} else {
		manualCreate = true;
		manualCreateType = createType;
	}
	dispatch(setClearPreivewOrSaveEmlBusy(true));
	dispatch(popWaitingWithoutCatch(I("Preparing for preview...")));
	return dispatch(checkManualErrandPreview({manual: true, manualCreate, manualCreateType,
		manualStart}))
		.then(({param, option}) => {
			param = update(param, {
				return_extended_data: {$set: true}
				, return_acquire_data: {$set: true}
				, email_type: {$set: emailType}
				, manual_type: {$set: type}
			});
			dispatch(clearPopWaiting());
			return dispatch(previewManualErrandEmailOnSamePage(param));
		})
		.catch(err => {
			if (!err) {
				err = I("Error when preparing errand for preview.");
			}
			return dispatch(togglePopAlert(err));
		})
		.catch(DummyFunction)
		.then(() => dispatch(setClearPreivewOrSaveEmlBusy()));
};

const removeUnuseParamFields = [
	"update_answer"
	, "update_question"
	, "update_subject"
];

let postDownloadResolve, postDownloadReject;

export const postDownloadOnEnd = result => dispatch => {
	if (postDownloadResolve) {
		postDownloadResolve(result);
	}
};

export const postDownloadOnError = err => dispatch => {
	if (postDownloadReject) {
		postDownloadReject(err);
	}
};

const postDownloadHandle = (format, url, params) => dispatch => {
	return new Promise((resolve, reject) => {
		postDownloadResolve = resolve;
		postDownloadReject = reject;
		dispatch(preparePostLink(format, {url, params}));
	});
};

export const postDownloadJSON = (url, params) => postDownloadHandle(
	PLF_DOWNLOAD
	, url
	, {json: encodeURIComponent(JSON.stringify(params))}
);

const saveAsEmlBase = (emailType, option) => (dispatch, getState) => {
	const [ rawParams, id ] = getIdAndParamForEmailProcess(
			getState()
			, emailType
			, option
		)
		, params = update(rawParams, {$unset: removeUnuseParamFields})
		;
	return dispatch(postDownloadHandle(PLF_SAVE_AS_EML, getSaveAsEmlURL(id), params));
};

export const saveAsEml = (emailType, option) => dispatch => {
	dispatch(setClearPreivewOrSaveEmlBusy(true));
	dispatch(popWaitingWithoutCatch(I("Downloading email as *.eml ...")));
	return dispatch(rawHardSaveErrand())
		.then(() => dispatch(saveAsEmlBase(emailType, option)))
		.then(() => dispatch(clearPopWaiting()))
		.catch(err => {
			if (!err) {
				err = I("Error when downloading email in eml format.");
			}
			return dispatch(togglePopAlert(err));
		})
		.catch(DummyFunction)
		.then(() => dispatch(setClearPreivewOrSaveEmlBusy()));
};

export function promiseProcessEmail(dispatch, reply, func) {
	return new Promise(resolve => {
		const emailType = EMAIL_TYPE_FROM_REPLY[reply];
		if (typeof emailType === "undefined") {
			console.log("dbg: preview N/A:", {reply});
			resolve();
			return;
		}
		resolve(dispatch(func(emailType, {reply})));
	});
}

export function promiseProcessManualErrandEmail(dispatch, reply, type, createType, func) {
	return new Promise(resolve => {
		const emailType = EMAIL_TYPE_FROM_REPLY[reply];

		if (typeof emailType === "undefined") {
			console.log("dbg: preview N/A:", {reply});
			resolve();
			return;
		}
		resolve(dispatch(func(emailType, type, createType)));
	});
}

export const saveAsEmlClick = () => (dispatch, getState) => promiseProcessEmail(dispatch, getCurrentReply(getState()), saveAsEml);

// convert front-end channel identifier into backend URL channel identifier
// which now happen to be the same string.
function channelToURLChannel(channel) {
	return channel;
}

const smUserProfile = (channel, p) => async(
	postSocialMediaActions(
		channelToURLChannel(channel)
		, p
	)
	, errand[keySocialMediaUserProfile]
);

const smSharedPostHistory = (channel, p) => async(
	postSocialMediaActions(
		channelToURLChannel(channel)
		, p
	)
	, errand[keySocialMediaPostsHistory]
);

const smUpdateMessageByChannel = (channel, p) => async(
	postSocialMediaUpdateAns(
		channelToURLChannel(channel)
		, p
	)
	, errand[keySocialMediaPostsUpdateAns]
);

export const socialMediaReplyAction = (eid, action, channel, status) => (dispatch, getState) =>{
	let state = getState();
	let option = {all: "",reply:"", skipSubject:"", previous:""};
	let confirmMsg = I("Are you sure you want to delete the selected errand?");
	const F = state.app.workflow.fetchWfSettings.data;
	let uiReply = state.app.errand.ui.reply;
	let p = {errand: eid, status: status}
	switch( action ){
		case MEDIA_ACTION.FB_PROFILE:
		case MEDIA_ACTION.TWT_PROFILE:
		case MEDIA_ACTION.VK_PROFILE:
			dispatch(handleMediaProfile(channel, p));
			break;
		case MEDIA_ACTION.FB_EMOTION_HISTORY:
			dispatch(async(postFBEmotionList(p), errand[keyErrandFBEmotionlist]))
				.then(()=>{
					dispatch(toggleSocialMediaUI(uiReply.showReactionPopup, 'reactions'));
				});
			break;
		case MEDIA_ACTION.FB_HISTORY:
		case MEDIA_ACTION.TWT_HISTORY:
		case MEDIA_ACTION.VK_HISTORY:
			dispatch(handleMediaHistory(channel, p));
			break;
		case MEDIA_ACTION.FB_LIKE:
		case MEDIA_ACTION.TWT_LIKE:
		case MEDIA_ACTION.VK_LIKE:
		//case MEDIA_ACTION.INS_LIKE:
			dispatch(handleMediaLUHUDAction(channel, p, "like"));
			break;
		case MEDIA_ACTION.FB_HIDE:
		case MEDIA_ACTION.INS_HIDE:
			if(F["mustConfirmDelete"]){
				confirmMsg = I("Are you sure you want to hide the selected errand?");
				dispatch(enableSendConfirm('hide errand from social media', confirmMsg, {option}))
				.then(yn =>{
					if( typeof yn !== 'undefined' ){
						dispatch(handleMediaLUHUDAction(channel, p, "hide"));
					}
				});
			}else{
				dispatch(handleMediaLUHUDAction(channel, p, "hide"));
			}
			break;
		case MEDIA_ACTION.FB_UNHIDE:
		case MEDIA_ACTION.INS_UNHIDE:
			if(F["mustConfirmDelete"]){
				confirmMsg = I("Are you sure you want to unhide the selected errand?");
				dispatch(enableSendConfirm('unhide errand from social media', confirmMsg, {option}))
				.then(yn =>{
					if( typeof yn !== 'undefined' ){
						dispatch(handleMediaLUHUDAction(channel, p, "unhide"));
					}
				});
			}else{
				dispatch(handleMediaLUHUDAction(channel, p, "unhide"));
			}
			break;
		case MEDIA_ACTION.FB_UNLIKE:
		case MEDIA_ACTION.TWT_UNLIKE:
		case MEDIA_ACTION.VK_UNLIKE:
		//case MEDIA_ACTION.INS_UNLIKE:
			dispatch(handleMediaLUHUDAction(channel, p, "unlike"));
			break;
		case MEDIA_ACTION.FB_DELETEQ:
		case MEDIA_ACTION.TWT_DELETEQ:
		case MEDIA_ACTION.VK_DELETEQ:
		case MEDIA_ACTION.INS_DELETEQ:
			if(F["mustConfirmDelete"]){
				dispatch(enableSendConfirm('delete errand from social media', confirmMsg, {option}))
				.then(yn =>{
					if( typeof yn !== 'undefined' ){
						dispatch(handleMediaLUHUDAction(channel, p, "delete"));
					}
				});
			}else{
				dispatch(handleMediaLUHUDAction(channel, p, "delete"));
			}
			break;
		case MEDIA_ACTION.TWT_RETWEET:
			dispatch(handleMediaLUHUDAction(channel, p, "retweet"));
			break;
		case MEDIA_ACTION.TWT_UNRETWEET:
			console.log("API doesnt have support for retweet");
			dispatch(handleMediaLUHUDAction(channel, p, "unretweet"));
			break;
		case MEDIA_ACTION.FB_RATING:
			dispatch(async(postFBRatings(p), errand[keyErrandFBRatings]))
			.then(()=>{
				dispatch(toggleSocialMediaUI(uiReply.showSendPMPopup, 'ratings'));
			});
			break;
		case MEDIA_ACTION.FB_SENDPM:
		case MEDIA_ACTION.TWT_SENDPM:
		case MEDIA_ACTION.VK_SENDPM:
			dispatch(toggleSocialMediaUI(uiReply.showSendPMPopup, 'sendpm'));
			break;
		case MEDIA_ACTION.FB_UPDATE_ANS:
		case MEDIA_ACTION.VK_UPDATE_ANS:
			dispatch(toggleSocialMediaUI(uiReply.showSendPMPopup, 'updateans'));
			break;

		case MEDIA_ACTION.FB_DELETE_ANS:
		case MEDIA_ACTION.TWT_DELETE_ANS:
		case MEDIA_ACTION.VK_DELETE_ANS:
			p.types = "deleteAnswer";
			p.message = "";
			dispatch(smUpdateMessageByChannel(channel, p));
			break;
		default:
			console.log("dbg: unhandled socialMediaReplyAction: %s action", action);
	}
}

const twitterCheckFriendShip = (channel, p) => async(
	postSocialMediaActions(
		channelToURLChannel(channel)
		, p
	)
	, errand[keySocialMediaCheckFriendship]
);

const smSendMessageByChannel = (channel, p) => async(
	postSocialMediaPMS(
		channelToURLChannel(channel)
		, p
	)
	, errand[keySocialMediaPostsMessages]
);

export const deliverMessageToSocialMedia = (eid, action, channel) => (dispatch, getState) =>{
	let state = getState();
	let msgBody = state.app.errand.inputs.pms_body;
	let p = {errand: eid, message: msgBody};
	switch( action ){
		case 'sendpm':
			if(channel === RC_TWITTER){
				if(msgBody !== ""){
					let tp = {errand: eid, action:"checkFriendship"};
					dispatch(twitterCheckFriendShip(channel, tp))
					.then(res =>{
						if(typeof res.status != 'undefined' && res.status){
							dispatch(smSendMessageByChannel(channel, p));
						}else{
							alert(I("This twitter account is not a follower, we are unable to send a direct message."));
						}
					});
				}else{
					alert(I("Empty message will not be sent."));
				}
			}else{
				if(msgBody !== ""){
					dispatch(smSendMessageByChannel(channel, p))
					.then(rs =>{
						if(rs !== null && rs.status !== null)
							alert(rs.status);
						else
							console.log("dbg: sending failed, ", rs);
					});
				}else{
					alert(I("Empty message will not be sent."));
				}
			}
			break;
		case 'updateAnswer':
			p.types = "updateAnswer";
			if(msgBody !== "")
				dispatch(smUpdateMessageByChannel(channel, p));
			else
				alert(I("!updateAns: Empty message will not be sent."));
			break;
		default:
			console.log("dbg: unhandled socialMediaReplyAction: %s action", action);
	}
}

const smEmotionAction = (channel, p) => async(
	postSocialMediaActions(
		channelToURLChannel(channel)
		, p
	)
	, errand[keySocialMediaPostActions]
);

//handleMediaLUHUDAction used to handle like/unlike/hide/unhide/delete/retweet actions
const handleMediaLUHUDAction = (channel, p, type) => (dispatch, getState) =>{
	let ma = {};
	p.action = type;
	dispatch(smEmotionAction(channel, p))
	.then(r =>{
		console.log(`dbg: Action ${type} for ${channel} performed.`);
		dispatch(handleMediaActions(p, type));
	});
}

const handleMediaProfile = (channel, p) => (dispatch, getState) => {
	let ma = {};
	if (channel === RC_FACEBOOK || channel === RC_VK) {
		p.action = "person";
	} else {
		p.action = "profile";
	}
	dispatch(smUserProfile(channel, p))
		.then(r => {
			let personId = 0;
			if (channel === RC_FACEBOOK) {
				ma = MEDIA_ACTION.FB_URL;
				personId = r.personId;
			} else if (channel === RC_VK) {
				ma = MEDIA_ACTION.VK_URL;
				personId = r.personId;
			} else if (channel === RC_TWITTER) {
				ma = MEDIA_ACTION.TWT_URL;
				personId = r.customer;
			}
			if (personId != 0) {
				openWindow(ma.url + personId, ma.capName);
			} else {
				dispatch(togglePopAlert(I("User profile not found.")));
			}
		});
}

const handleMediaHistory = (channel, p) => (dispatch, getState) =>{
	let ma = {};
	p.action = 'history';
	dispatch(smSharedPostHistory(channel, p))
		.then(r => {
			if (r === null) {
				return
			}
			let postId = 0
				, url = ""
				;
			if (channel === RC_TWITTER) {
				let cId = r.customer;
				ma = MEDIA_ACTION.TWT_URL;
				postId = r.postId
				url = ma.url + cId + "/status/" + postId;
			} else {
				if (channel === RC_FACEBOOK) {
					ma = MEDIA_ACTION.FB_URL;
				} else if (channel === RC_VK) {
					ma = MEDIA_ACTION.VK_URL;
				}
				url = ma.url + r.post;
			}
			openWindow(url, ma.capName);
		});
}

const openWindow = (url, title) => {
	window.open(url, `${title}`, 'scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768');
};

export const notesUploadAttachment = param => multiAsync(
	postErrandNotesUploadAttachment(param),
	errand[keyErrandNotesUploadAttachment]
);

const notesDeleteAttachment = fid => async(
	postErrandNotesDeleteAttachment({fid}),
	errand[keyErrandNotesDeleteAttachment]
);

export const errandUploadAnswerAttachment = (param) => multiAsync(
	postErrandUploadAnswerAttachment(param),
	errand[keyErrandUploadAnswerAttachment]
);

export const uploadAgentAttachment = (p, route, reply, chat) => (dispatch, getState) => {
	const ps = [];
	let state = getState();
	for (let i=0; i<p.length; i++) {
		let dispatchee;
		if(reply !== RPLY_COMMENT) {
			dispatchee = errandUploadAnswerAttachment(p[i]);
		} else {
			dispatchee = notesUploadAttachment(p[i]);
		}
		ps.push(dispatch(dispatchee)
			.then(r => {
				if (chat && !chatHasExternalCollaboration(reply)) {
					if (reply !== RPLY_COMMENT) {
						dispatch(uploadedChatAttachments(chat, r));
					} else {
						dispatch(uploadedInternalCommentAttachments(r));
					}
				} else if (reply === RPLY_COMMENT) {
					dispatch(uploadedInternalCommentAttachments(r));
				} else if (reply === RPLY_MANUAL_COMMENT) {
					dispatch(uploadedManualInternalCommentAttachments(r));
				} else if (reply === RPLY_MANUAL) {
					if(showManualCallSelector(state) == MP_BASIC_CALL){
						dispatch(uploadedManualCallErrandAttachments(r));
					} else {
						dispatch(uploadedManualErrandAttachments(r));
					}
				} else if (reply !== RPLY_COLLABORATE) {
					dispatch(uploadedAnswerAttachments(r));
				} else if (reply !== "Library") {
					dispatch(uploadedColAttachments(r));
				}
				return r;
			}));
	}
	return Promise.all(ps);
};

export const uploadOneAgentAttachment = ({ data, info }, reply, chat) => (dispatch, getState) => {
	return dispatch(uploadAgentAttachment([data], undefined, reply, chat))
		.then(results => {
			if (results.length) {
				return results[0];
			}
			return results;
		});
};

export const uploadClientNoteAttachment = (p, route) => (dispatch, getState) => {
	return dispatch(notesUploadAttachment(p))
		.then(r => {
			if(r) {
				dispatch(uploadedNoteAttachments(r))
			}
		});
};

const errandRemoveTemporaryAttachment = id => dispatch => dispatch(async(
	postErrandRemoveTemporaryAttachment({fid: id}),
	errand[keyErrandRemoveTemporaryAttachment]))
	.then(response => {
		if(response === false) {
			const err = new Error('failed delete temporary answer attachment');
			return Promise.reject({err});
		}
	});

const msgAttachmentNotDelete = I("Attachment was not deleted.");

const updateAttachmentState = (reply, id) => dispatch => {
	if(reply === RPLY_COMMENT) {
		return dispatch(deleteUploadedInternalCommentAttachment(id));
	} else if(reply === RPLY_MANUAL_COMMENT) {
		return dispatch(deleteUploadedManualInternalCommentAttachment(id));
	} else if(reply === RPLY_MANUAL) {
		return dispatch(deleteUploadedManualAttachment(id));
	} else if(reply !== RPLY_COLLABORATE) {
		return dispatch(deleteUploadedAnswerAttachment(DEL_ANSWER_ATTACHMENT,
			id));
	} else {
		return dispatch(deleteUploadedColAttachment(DEL_ANSWER_ATTACHMENT, id));
	}
};

export const deleteUploadedAttachment = (reply, id) => (dispatch, getState) => {
	let dispatchee;
	if(reply !== RPLY_COMMENT) {
		dispatchee = errandRemoveTemporaryAttachment(id);
	} else {
		dispatchee = notesDeleteAttachment(id);
	}
	return dispatch(dispatchee)
		.then(() => {
			return dispatch(updateAttachmentState(reply, id));
		})
		.catch(({err}) => {
			// NOTE: purposely remove orphanage temporary attachment even
			// deletion failed else it's frustrating to have that in RAM and
			// can't be removed.
			dispatch(updateAttachmentState(reply, id));
			return dispatch(togglePopAlert(msgAttachmentNotDelete));
		});
};

export const deleteAnswerAttachment = (actionFor, aId, reply) => (dispatch, getState) => {
	switch(actionFor){
		case DEL_ANSWER_ATTACHMENT:
			dispatch(deleteUploadedAttachment(reply, aId));
			break;
		default:
			console.log(`dbg: action ${actionFor} for attachment Id ${aId} not performed.`);
	}
};

const fetchFile = (p) => async(
	getFileContent(p),
	errand[keyErrandAttachmentViaURL]
);

export const fetchFileFromURL = (file, type) => dispatch => {
	dispatch(fetchFile(file))
	.then(function(data) {
		if(type == 'txt'){
			let txtPreview = data;
			if(data.length > 1000) {
				txtPreview = data.substring(0,1000)+'...';
			}
			dispatch(setTextFilePreviewContent(txtPreview));
		}else if(type == 'csv'){
			let csvData = data.split(/\r?\n|\r/);
			let tableData = "<table class='table table-bordered table-striped'>";
			for(let count=0; count < csvData.length; count++) {
				let cellData = csvData[count].split(",");
				tableData += "<tr>";
				for(let cellCount = 0; cellCount < cellData.length; cellCount++){
					if(count === 0){
						tableData += "<th>" + cellData[cellCount] + "</th>";
					}else{
						tableData += "<td>" + cellData[cellCount] + "</td>";
					}
				}
				tableData += "</tr>";
			}
			tableData += "</table>";
			dispatch(setCSVFilePreviewContent(tableData));
		}
	});
}

const errandRemoveAllTempAttachment = p => multiAsync(
	postErrandRemoveTemporaryAttachment(p),
	errand[keyErrandRemoveAllTempAttachment]
);

const warnNoAttach = I("Error: Attachment not found."), // TODO put swedish translation
	attachmentNotDelete = I("Attachment was not deleted.");

export const deleteAllAgentAttachment = eid => (dispatch, getState) => {
	const state = getState(), inpts = state.app.errand.inputs,
		uploadedAttchs = inpts.uploaded_attachments,
		archiveAttchs = inpts.archive_attachments,
		libraryAttchs = inpts.library_attachments,
		qAttchs = inpts.question_attachments,
		colAnsAttach = inpts.update_external_expert_Answer_attachments,
		savedAttchs = inpts.saved_attachments;
	if(!uploadedAttchs.length && !archiveAttchs.length && !qAttchs &&
		!colAnsAttach.length && !savedAttchs.length) {
		return dispatch(togglePopAlert(warnNoAttach));
	}
	$.each(uploadedAttchs, (i,v) => {
		const id = v.id;
		dispatch(errandRemoveAllTempAttachment({fid: id}))
		.then(rs => {
			if(rs === true) {
				dispatch(deleteUploadedAnswerAttachment(DEL_ANSWER_ATTACHMENT,
					id));
			} else {
				return dispatch(togglePopAlert(attachmentNotDelete));
			}
		});
	});
	$.each(archiveAttchs, (i,v) => {
		dispatch(clearSelectedArchive(DEL_ANSWER_ATTACHMENT, v.id));
	});
	$.each(libraryAttchs, (i,v) => {
		dispatch(clearSelectedArchive(DEL_ANSWER_ATTACHMENT, v.id));
	});
	$.each(qAttchs, (id,v) => {
		if(v) {
			dispatch(selectQuestionAttachment(id, false));
		}
	});
	$.each(savedAttchs, (i,v) => {
		dispatch(deleteSavedAnswerAttachment(v.id, DEL_ANSWER_ATTACHMENT));
	});
	$.each(colAnsAttach, (i,v) => {
		dispatch(clearCollaborateAnsAttachment(v.id));
	});
};

const customerByAddress = p => async(getCustomerByAddress({errandId: p}), errand[keyGetCustomerByAddress]);
const contactCardEntry = id => async(getContactCardEntry(id), errand[keyGetContactCardEntry]);
export const getContactCard = (tgl) => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand;
	const cus = cerd.contactCard.customer;
	if(tgl) {
		dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
		dispatch(customerByAddress(cerd.id))
		.then(rs =>{
			rs.mcam.channels.forEach(function(d){
				if(typeof d.type != undefined && d.type == "Result"){
					let content = JSON.parse(d.content);
					if(typeof content.error != 'undefined') {
						console.log(content.error)
						//if new customer, skip error popout
						if(cus.id !== 0) {
							dispatch(popErrorOnly(content.error));
						}
					}
					if(typeof content.result != 'undefined') {
						dispatch(contactCardEntry(content.result))
						.then(re => {
							if(re != null) {
								if(typeof re.error != 'undefined') {
									console.log(re.error)
									dispatch(updateContactCard("error", ERROR_MESSAGE));
									dispatch(popErrorOnly(re.error));
								} else {
									dispatch(updateContactCard("list", re));
									dispatch(showContactCard(tgl));
									dispatch(clearPopWaiting());
								}
							}
						});
					}
				}
			});
		});
	} else {
		dispatch(showContactCard(false));
	}
};

const addContactCardEntry = (p) => async(postAddEntryToContactCard(p), errand[keyAddEntryToContactCard]);
export const addContactIntoCard = (context) => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand,
		cus = cerd.contactCard.customer, ui = errand.ui.contactCard;
	let p = {
		groupId: cus.id,
		city: cus.city,
		groupName: ui.name,
		postcode: cus.postcode,
		externalId: cus.externalId,
		serviceType: ui.channelType,
		contact: ui.contactInput.replace('\t','').trim(),
		customService: ui.customLabel,
		filename: ui.avatarFilename,
		filetype: ui.avatarFiletype,
		companyId: cus.companyId
	};
	dispatch(addContactCardEntry(p))
	.then(rs =>{
		if(rs != null) {
			if(typeof rs.error != "undefined"){
				console.log(rs.error)
				dispatch(updateContactCard("error", ERROR_MESSAGE));
				dispatch(popErrorOnly(ERROR_MESSAGE));
			}else if(typeof rs.failed != "undefined"){
				if(context === "contactBook"){
					dispatch(updateContactBook("failed", rs.failed));
				} else if(context === "contactCard"){
					dispatch(updateContactCard("failed", rs.failed));
				}
				dispatch(popErrorOnly(ERROR_MESSAGE));
			}else if(typeof rs.customer != "undefined"){
				if((cus.id === rs.customer.id) || (cus.id === 0 && rs.customer.id > 0)) {
					dispatch(setContactCard("contactInput", ""));
					dispatch(setContactCard("customLabel", ""));
					dispatch(setContactCard("error", ""));
					if(context === "contactBook"){
						dispatch(showContactBook(false));
						let msg =  I(`Successfully added to existing contact`);
						dispatch(toggleToastAlert(TOAST_TYPE.success, TOAST_POSITION.topRight, msg));
						// dispatch(showContactCard(true))
						//use the below if want open contactCard straight away after add
						// dispatch(getContactCard(true))
						// dispatch(fetchCustomerNotes());
						// dispatch(fetchCompanyList());
					}
					dispatch(updateContactCard("add", rs));
					// upon add contacts, refresh the channels for reply
					if (cerd && cerd.id) {
						dispatch(fetchClientsAddressList(cerd.id));
					}
				}
			}
			dispatch(clearPopWaiting())
		}
	});
};

export const refreshContactCard = (context, rs) => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand,
		cus = cerd.contactCard.customer, ui = errand.ui.contactCard;
	//TK-TODO: refactor unneccesary condition
	if(rs != null) {
		if(typeof rs.error != "undefined"){
			console.log(rs.error)
			dispatch(updateContactCard("error", ERROR_MESSAGE));
		}else if(typeof rs.failed != "undefined"){
			if(context === "contactBook"){
				dispatch(updateContactBook("failed", rs.failed));
			} else if(context === "contactCard"){
				dispatch(updateContactCard("failed", rs.failed));
			}
		}else if(typeof rs.customer != "undefined"){
			if((cus.id === rs.customer.id) || (cus.id === 0 && rs.customer.id > 0)) {
				dispatch(setContactCard("contactInput", ""));
				dispatch(setContactCard("customLabel", ""));
				dispatch(setContactCard("error", ""));
				dispatch(setContactCard("date", rs.customer.date));
				if(context === "contactBook"){
					dispatch(showContactBook(false));
				}
				dispatch(updateContactCard("add", rs));
				// upon add contacts, refresh the channels for reply
				if (cerd && cerd.id) {
					dispatch(fetchClientsAddressList(cerd.id));
				}
			}
		}
	};
	// if(rs !== null) {
	// 	dispatch(updateContactCard("add", rs));
	// 	if (cerd && cerd.id) {
	// 		dispatch(fetchClientsAddressList(cerd.id));
	// 	}
	// }
};

export const addSuggestContactIntoCard = (groupId, serviceType, contact) => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand;
	let p = {
		groupId: groupId,
		serviceType: serviceType,
		contact: contact,
	};
	dispatch(addContactCardEntry(p))
	.then(rs =>{
		if(rs != null) {
			if(typeof rs.error != "undefined"){
				console.log(rs.error)
			}else {
				// upon add contacts, refresh the channels for reply
				if (cerd && cerd.id) {
					dispatch(fetchClientsAddressList(cerd.id));
				}
			}
		}
	});
};

const removeContactCardEntry = id => async(deleteContactCardEntry(id), errand[keyDeleteContactCardEntry]);
export const removeContactFromCard = (id, list) => (dispatch, getState) => {
	const cerd = getState().app.errand.currentErrand;
	dispatch(removeContactCardEntry(id))
	.then(rs =>{
		if(typeof rs.error != "undefined") {
			console.log(rs.error);
			dispatch(updateContactCard("error", ERROR_MESSAGE));
			return dispatch(popErrorOnly(ERROR_MESSAGE));
		} else if(id == rs.id) {
			dispatch(updateContactCard("remove", id));
			// upon remove any contacts, refresh the channels for reply
			if (cerd && cerd.id) {
				dispatch(fetchClientsAddressList(cerd.id));
			}
			//if delete last one remaining, we close
			if(list.length == 1) {
				dispatch(showContactCard(false));
			} else {
				dispatch(getContactCard(true));
			}
		}
		dispatch(clearPopWaiting())
	});
};

const getCustomerNotesAsync = (p) => async(getCustomerNotes(p), errand[keyGetCustomerNotes]);
export const fetchCustomerNotes = () => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand;
	let p = {
		errand: cerd.id,
		type: "client"
	};
	dispatch(getCustomerNotesAsync(p))
	.then(rs =>{
		if(rs != null) {
			if(typeof rs.status != "undefined") {
				console.log(rs.status)
			} else {
				dispatch(setCustomerNotes(rs.notes));
			}
		}
	});
};

export const postCustomerNoteAsync = (p) => async(postCustomerNote(p), errand[keyPostCustomerNote]);
export const addCustomerNote = () => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand,
	cc = errand.ui.contactCard;
	let attachment = [];
	$.each(cc.noteAttachment, (i, k) =>{
		attachment.push(k.id);
	});
	let p = {
		errand: cerd.id,
		type: "client",
		text: cc.noteText.trim(),
		attachments: (attachment.length > 0 ? attachment.join(',') : "")
	};
	dispatch(postCustomerNoteAsync(p))
	.then(rs =>{
		if(rs != null) {
			if(typeof rs.status != "undefined") {
				console.log(rs.status)
			}
			if(typeof rs.note != "undefined" && rs.note != null) {
				dispatch(updateCustomerNote("add", rs.note));
				dispatch(setContactCard("noteText", ""));
				dispatch(setContactCard("noteAttachment", []))
			}
		}
	});
};

export const removeCustomerNote = id => (dispatch, getState) => {
	const state = getState(), erd = state.app.errand, cerd = erd.currentErrand;
	let p = {type: "client", errand: cerd.id, note: id};

	dispatch(async(postDeleteCustomerNote(p), errand[keyDeleteCustomerNote]))
	.then(rs =>{
		if(typeof rs.error != "undefined") {
			console.log(rs.error);
		} else {
			dispatch(updateCustomerNote("remove", id));
		}
	});
};

const customerAvatarAsync = (p) => async(postCustomerAvatar(p), errand[keyCustomerAvatar]);
export const saveCustomerAvatar = () => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cc = errand.ui.contactCard,
		cerd = errand.currentErrand, customer = cerd.contactCard.customer;
	let fd = new FormData();
	let i = 0;
	let rand = Math.floor(Math.random() * 6)+ ''+ i +''+ Math.floor(''+new Date() / 1000);
	let blob = dataURItoBlob(cc.avatarPreview);
	fd.append("id", customer.id);
	fd.append("uploadedAvatar", blob);
	fd.append("localName", rand);
	fd.append("shouldUpdate",(cc.avatarPreview !== "" ? true : false))
	dispatch(customerAvatarAsync(fd))
	.then(rs =>{
		dispatch(setContactCard("avatarPreview", rs.url, rs.filename, rs.filetype));
	});
};

const customerAvatarDelAsync = (p) => async(postCustomerDelAvatar(p), errand[keyCustomerAvatar]);
export const delCustomerAvatar = () => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand,
		cc = errand.ui.contactCard,
		cerd = errand.currentErrand, customer = cerd.contactCard.customer;
	let p = {
		id: customer.id
	};
	dispatch(customerAvatarDelAsync(p))
	.then(rs =>{
		dispatch(setContactCard("avatarPreview", ""));
		dispatch(setContactCard("avatar", ""));
	});
};
const contactBookAsync = p => async(getContactBook(p), errand[keyGetContactBook]);
export const fetchContactBook = (search) => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, ui = errand.ui.contactBook;
	let p = {
		searchText: search
	};
	if(ui.show){
		dispatch(contactBookAsync(p))
		.then(rs =>{
			if(rs != null) {
				dispatch(updateContactBook("list", rs));
			}
		});
	}
};

export const getCustomerContacts = id => (dispatch, getState) => {
	dispatch(contactCardEntry(id))
	.then(re => {
		if(re != null) {
			if(typeof re.error != 'undefined') {
				console.log(re.error)
				dispatch(updateContactCard("error", ERROR_MESSAGE));
			} else {
				dispatch(updateContactCard("list", re));
			}
		}
	});
};

const announcementAsync = p => async(getAnnouncement(p), errand[keyGetAnnouncement]);
export const fetchAnnouncement = id => (dispatch, getState) => {
	dispatch(announcementAsync(id))
	.then(re => {
		if(re != null) {
			if(typeof re.error != 'undefined') {
				console.log(re.error)
				dispatch(updateAnnouncement("error", ERROR_MESSAGE));
			} else {
				dispatch(updateAnnouncement("list", re));
			}
			return re;
		}
	});
};

const fetchErrandStatus = id => async(getErrandStatus(id),
	errand[keyGetErrandStatus]
);

export const findAndOpenLastErrandInThread = id => (dispatch, getState) => {
	dispatch(async(getLastErrandInThread(id), errand[keyLastErrandInThread]))
	.then(rs => {
		let errandId = (rs && rs.errandId) ? rs.errandId : id;
		dispatch(loadAndOpenErrand(errandId));
	});
};

export const fetchValidErrandId = (id, cipherKey) => (dispatch, getState) => {
	return dispatch(async(getErrandId({id, cipherKey}), errand[keyErrandId]));
};

const validateErrandID = (
	id
	, cipherKey
) => dispatch => dispatch(fetchValidErrandId(id, cipherKey))
	.then(result => {
		if (result.errandId) {
			return result.errandId;
		}
		return Promise.reject(new Error(I("Failed to validate {ERRAND_ID}.").replace("{ERRAND_ID}", id)));
	});

export const loadAndOpenErrandFromSearch = id => (dispatch, getState) => {
	let isWorkflow = isMainMenuWorkflowErrandSelector(getState());
	if(!isWorkflow) {
		dispatch(redirectToErrands(MY_ERRANDS))
		.then(() =>{
			dispatch(loadAndOpenErrand(id));
		})
	} else {
		dispatch(loadAndOpenErrand(id));
	}
}

export const loadAndOpenErrand = id => openErrandCore(id, OPEN_SEARCHED_ERRAND);
export const openSingleChatErrand = (id,chat) => openErrandCore(id, OPEN_ERRAND_VIEW_ONLY, chat);
export const loadAndOpenErrandParams = (id,params) => openErrandCore(id,
	OPEN_SEARCHED_ERRAND, false, null, params);

const doGetAnonymizeData = (p) => async(getAnonymize(p), errand[keyAnonymize]);

const doAnonymizeData = (p) => async(postAnonymize(p), errand[keyAnonymize]);

export const getAnonymizeData = (id,tz) => (dispatch, getState) => {
	dispatch(doGetAnonymizeData({errandId: id}))
	.then(rs => {
		const errand = getState().app.errand;
		let timezone = false;
		if(errand.dataExportLog.data){
			if(errand.dataExportLog.data.TimeZones){
				timezone = true;
			}
		}
		dispatch(getExportLog(id, timezone));
	});
};

export const anonymizeData = (id, tz) => (dispatch, getState) => {
	dispatch(doAnonymizeData({errandId: id}))
	.then(rs => {
		if(rs){
			dispatch(getExportLog(id, true));
		}
	});
}

export const doGetExportLog = (id,p) => async(postExportLog(id,p), errand[keyDataExportLog]);

export const getExportLog = (id,tz) => (dispatch, getState) => {
	dispatch(doGetExportLog(id, {wantTimeZones: tz}))
	.then(rs => {
		if(rs){
			dispatch(setExportLog(rs));
		}
	});
};


export const exportContact = (id, lang, tz) => (dispatch, getState) => {
	dispatch(doExportContact(id, {lang: lang, tz: tz}))
	.then(rs => {
		const errand = getState().app.errand;
		let logs = [];
		let exportLog = [], path = "", secret = "";

		if(errand.dataExportLog.data){
			exportLog = (errand.dataExportLog.data.Logs ? errand.dataExportLog.data.Logs : []);
			path = errand.dataExportLog.data.Path;
			secret = errand.dataExportLog.data.Secret;
		}

		var exportState = {
			Error: "",
			Path: path,
			Secret: secret,
			TimestampEnabled: 0,
			ExpirySecs: 0,
			Logs: exportLog,
			TimeZones: errand.ui.gdpr.exportTimeZones,
			LangCode: lang,
			TimeZoneId: tz
		};

		if(rs.Error){
			exportState.Error = I('Export failed, please try again.');
		}else{
			if(rs.Log){
				logs.push(rs.Log);
				for(let i=0;i<exportLog.length;i++) {
					logs.push(exportLog[i]);
				}
				exportState.Logs = logs;
				exportState.Error = rs.Error;
				exportState.Path = rs.Path;
				exportState.Secret = rs.Secret;
				exportState.TimestampEnabled = rs.TimestampEnabled;
				exportState.ExpirySecs = rs.ExpirySecs;
			}
		}
		dispatch(setExportLog(exportState));

	});
};

export const doDelExportContact = (id, secret) => async(deleteContactExport(id,secret), errand[keyDeleteExportContact]);

// Revoke Access (Export data)
export const delExportContact = (id,secret) => (dispatch, getState) => {
	dispatch(doDelExportContact(id, secret))
	.then(rs => {
		const errand = getState().app.errand;
		let logs = [];
		let deleteLog = errand.dataRevokeAccess;
		let data = deleteLog.data, error = deleteLog.error;
		let exportLog = [], path = "", timezones = [];
		if(errand.dataExportLog.data){
			exportLog = errand.ui.gdpr.exportLogs;
			path = errand.dataExportLog.data.Path;
			timezones = errand.dataExportLog.data.TimeZones;
		}

		var exportState = {
			Error: "",
			Logs: exportLog,
			Path: path,
			TimeZones: timezones
		};

		if(error){
			exportState.Error = I('Revoke failed, please try again.');
		}else{
			if(rs.Log){
				logs.push(rs.Log);
				for(let i=0;i<exportLog.length;i++) {
					logs.push(exportLog[i]);
				}
				exportState.Logs = logs;
				exportState.Path = "";
			}
			dispatch(revokeGrantedAccess(exportState));
		}

	});
};

export const doExportContact = (id, p) => async(postContactExport(id, p), errand[keyExportContact]);

export const doCheckMembershipStatus = (p) => async(getMembershipStatus(p), errand[keyHasMembership]);

export const recordEditorSize = (p) => async(postRecordEditorSize(p), errand[keyRecordEditorSize]);

export const updateShowReplyToolbarPref = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({showReplyToolbar: p}))
		.then(() => {
			dispatch(toggleReplyToolbar(p));
		});
};

export const updateShowReplyAssistPref = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({showReplyAssist: p}))
		.then(() => {
			dispatch(toggleReplyAssist(p));
		});
};

export const updateShowRecipientsPref = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({showReplyRecipients: p}))
		.then(() => {
			dispatch(toggleRecipients(p));
		});
};

export const updateShowSubjectPref = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({showReplySubject: p}))
		.then(()=> {
			dispatch(toggleSubject(p));
		})
};

export const updateSelectedTabPref = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({selectedReplyTab: p}))
		.then(()=> {
			dispatch(toggleReplyOptionTab(p));
		})
};

export const setVerticalViewPref = (verticalView) => (dispatch, getState) => {
	return dispatch(setAgentPreference({verticalView: verticalView}))
}

export const setAgentAssistTogglePref = (show) => (dispatch) => {
	return dispatch(setAgentPreference({showAgentAssistPanel: show}))
}

export const toggleHeaderPanel = (show) => (dispatch, getState) => {
	return dispatch(setAgentPreference({showErrandHeader: show}));
}

export const updateShowReplyPanelPref = (show) => (dispatch, getState) => {
	return dispatch(setAgentPreference({showReplyPanel: show}));
}

const setupErrandsLockToMe = (param) => async(postErrandUpdateLockToMe(param),
	errand[keyErrandUpdateLockToMe]
);

export const updateLockToMe = (id, cipherKey, lock) => (dispatch) => {
	dispatch(setupErrandsLockToMe({id, cipher_keys: cipherKey, lock}))
		.then( rs =>{
			if(rs && rs.data){
				const data = rs.data;
				if(data.status == "success"){
					dispatch(toggleLockToMe(data.id, data.locked));
				}
		}
	});
}

function searchEmailAddressInCards(toAddress, service, basicFrom) {
	let address;
	if(service === Workflow.Errand.SERVICE_EMAIL) {
		$.each(toAddress, (k,v) => {
			if(v === basicFrom) {
				address = v;
				return false;
			}
		});
	}
	if(!address) {
		address = toAddress[0];
	}
	return address;
}

const checkBeforeChangeChannel = (param) => async(verifyChannelSelection(param),
	errand[keyVerifyChannelSelection]
);

export const checkBeforeSetChannel = (channel) => (dispatch, getState) => {
	const erd = getState().app.errand
		, card = erd.fetchClientsAddressList.data
		, errand = erd.currentErrand
		, basic = erd.basic.data
		, currentReplyChannel = getCurrentReply(getState())
		;
	if(currentReplyChannel != RPLY_COLLABORATE && card) {
		let service = REPLY_CHANNEL_TO_SERVICE_TYPE[channel]
		, toAddressArray = []
		, address = ""
		;
		$.each(card.clientAddress, (k,v) => {
			if(v.serviceType === service) {
				toAddressArray.push(v.address);
			}
		});
		if(toAddressArray.length > 0) {
			address = searchEmailAddressInCards(toAddressArray,
				service, basic.data.fromAddress);
		}
		if(service > 0 && address != "") {
			dispatch(checkBeforeChangeChannel({
				service: service,
				address: address,
				errand: errand.id
			})).then( rs => {
				if(rs) {
					if(rs.status) {
						dispatch(selectReplyChannel(channel));
						if(currentReplyChannel != RPLY_COLLABORATE){
							dispatch(updateReplyToAndChannel(service,
								{id:address, value:address}));
						}
					} else {
						dispatch(togglePopAlert(I("{MESSAGE}").replace('{MESSAGE}',
							rs.message)));
					}
				}
			});
		} else if(channel == "origin") {
			service = basic.data.sourceService;
			dispatch(selectReplyChannel(channel));
			dispatch(updateReplyToAndChannel(service, {}));
		} else {
			dispatch(selectReplyChannel(channel));
			if (service === basic.data.service){
				address = basic.data.fromAddress;
			}
			dispatch(updateReplyToAndChannel(service,
				{id:address, value:address}));
		}
	} else if(currentReplyChannel == RPLY_COLLABORATE) {
		dispatch(selectReplyChannel(channel));
	}
}

export const handleSavingRecordedVidChat = (sessionId, type, blob, length) => (dispatch, getState) => {
	const recordedBlobs = new Blob(blob, {type: 'video/mp4'});
	let fileName = type+"-"+sessionId+"-video.mp4";
	let file = new File([recordedBlobs], fileName);

	let fd = new FormData();
	let boundary = Math.floor(Math.random() * 6)+ '-'+ Math.floor(''+new Date() / 1000) ;
	fd.append( 'uploadfile', file );
	fd.append( 'session', sessionId);
	fd.append( 'eventType', 'chatVideo');
	fd.append( 'fileNameOnly', fileName);
	fd.append( 'random', parseFloat(boundary));

	return dispatch(errandUploadAnswerAttachment(fd))
	.then(results => {
		let isAgent = (type == "local" ? true : false);
		AgentSocket.SendEvent(
			"CHAT_VIDEO_RECORD_DONE"
			, {
				sessionId: sessionId,
				video: results.download,
				length: length,
				size: results.sizeHuman,
				isAgent: isAgent,
				fileId: results.id,
				fileIdc: results.idc,
				fileName: results.value
			}, ack => {
				//console.log("Done send recorded video to chatd for session ", sessionId);
			}
		);
		dispatch(handleAgentRecordVid(sessionId, false));
	});
}

export const handleSavingRecordedSip = (errandId, type, blob, length, isManual) => (dispatch, getState) => {
	const recordedBlobs = new Blob(blob, {type: 'audio/webm'});
	let fileName = type+"-"+errandId+"-"+getRandomString(5)+"-audio.webm";
	let file = new File([recordedBlobs], fileName);

	let fd = new FormData();
	let boundary = Math.floor(Math.random() * 6)+ '-'+ Math.floor(''+new Date() / 1000) ;
	fd.append( 'uploadfile', file );
	fd.append( 'session', errandId);
	fd.append( 'eventType', 'sipMedia');
	fd.append( 'fileNameOnly', fileName);
	fd.append( 'random', parseFloat(boundary));
	fd.append( 'length', parseInt(length));
	fd.append( 'isVoiceRecording', true);

	return dispatch(errandUploadAnswerAttachment(fd))
	.then(results => {
			if(isManual == true){
				return dispatch(uploadedManualCallErrandAttachments(results));
			} else {
					//here. maybe fore update in the "then" section?
				return dispatch(uploadedAnswerAttachments(results));
			}
	});
}

export const fbEmotionHistory = (emotErrandId, statusId) => (
	dispatch,
	getState
) => {
	dispatch(socialMediaReplyAction(
		emotErrandId,
		MEDIA_ACTION.FB_EMOTION_HISTORY,
		'',
		closeStatusString(statusId)
	))
	dispatch(toggleSocialMediaUI(showReactionPopupMemo(getState()), 'reactions'))
}

export const openPreviousErrand = () => (dispatch, getState) => {
	let prevId = getPreviousErrandId(getState());
	let currentErrandOpening = getCurrentErrandOpening(getState());
	if(currentErrandOpening) {
		console.info("current errand is opening, skip open previous errand");
		return;
	}
	if(prevId != 0) {
		console.info("storing previously opened errand id:", prevId);
		dispatch(loadAndOpenErrand(prevId));
		dispatch(setPreviousErrand(0));
	}
}
